import React, { useState, useEffect } from 'react';
import { t } from '../languages';
import { Progress } from 'antd';
import styled from 'styled-components';

interface ImageLoaderProps {
    src: string; // 图片地址
    alt?: string; // 图片的描述
    flag?: boolean; // 是否显示图片
}

const ImageLoader: React.FC<ImageLoaderProps> = ({ src, alt, flag }) => {
    const [loading, setLoading] = useState(true); // 加载状态
    const [progress, setProgress] = useState(0); // 加载进度
    const [imageLoaded, setImageLoaded] = useState(false); // 图片是否加载完成

    useEffect(() => {
        let xhr: XMLHttpRequest | null = new XMLHttpRequest();
        xhr.open('GET', src, true);
        xhr.responseType = 'blob';

        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = Math.round((event.loaded / event.total) * 100);
                setProgress(percentComplete);
            }
        };

        xhr.onload = () => {
            if (xhr && xhr.status === 200) {
                const blob = xhr.response;
                const url = URL.createObjectURL(blob);
                const img = new Image();

                img.src = url;
                img.onload = () => {
                    setLoading(false);
                    setImageLoaded(true);
                    URL.revokeObjectURL(url); // 释放内存
                };
            }
        };

        xhr.onerror = () => {
            console.error('图片加载失败');
            setLoading(false);
        };

        xhr.send();

        // 清理
        return () => {
            if (xhr) {
                xhr.abort();
                xhr = null;
            }
        };
    }, [src]);

    return (
        <>
            {/* <div style={{ position: 'relative', textAlign: 'center', display: flag ? 'none' : 'block' }}>
                {loading && (
                    <div style={{ position: 'absolute', top: '100px', left: '50%', transform: 'translateX(-50%)' }}>
                        <span>{t("加载中:")} {progress}%</span>
                    </div>
                )}
            </div> */}

            <div style={{ display: flag ? 'block' : 'none' }}>
                <ProgressContainer progress={progress}>
                    <p className='title'>{t("预览加载中，请稍后...")}</p>
                    <Progress strokeColor='#FDC448' percent={progress} size="small" status="active" />
                </ProgressContainer>
            </div>

            {imageLoaded && <PreviewImg>
                <img src={src} alt={alt} style={{ display: 'block', maxWidth: '100%' }} />
                <div className='bar' style={{display: !flag ? 'none' : 'block'}}></div>
            </PreviewImg>}
        </>
    );
};

export default ImageLoader;

const ProgressContainer = styled.div<{ progress: Number }>`
    width: 240px;
    height: 790px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: ${props => props.progress === 100 ? 'none' : ''};

    .title {
        font-weight: 400;
        font-size: 20px;
        color: #222;
        line-height: 29px;
        margin-bottom: 40px;
    }
`;

const PreviewImg = styled.div`
    position: relative;
    
    .bar {
        position: absolute;
        width: 100%;
        height: 240px;
        background: linear-gradient( 180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.96) 40%, #FFFFFF 100%);
        bottom: 0;
    }
`