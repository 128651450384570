import Layout from "antd/es/layout/layout";
import { PDFDocumentProxy } from "pdfjs-dist";
import { useCallback, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSearchParams } from "react-router-dom";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { Flex } from "antd";
import { useVoerkaI18n } from "@voerkai18n/react";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: "/standard_fonts/",
};

const maxWidth = 1280;

export default function Doc() {
  const [numPages, setNumPages] = useState<number>();
  const { activeLanguage } = useVoerkaI18n();
  const [path, setPath] = useState("");
  let [searchParams] = useSearchParams();
  const type = searchParams.get("type") ?? 0;

  useEffect(() => {
    if (type === 0) {
      if (activeLanguage === "zh") {
        setPath("https://pdftopdf.ai/static/doc/PA_ZH.pdf");
      } else {
        setPath("https://pdftopdf.ai/static/doc/PA.pdf");
      }
    } else {
      if (activeLanguage === "zh") {
        setPath("https://pdftopdf.ai/static/doc/SA_ZH.pdf");
      } else {
        setPath("https://pdftopdf.ai/static/doc/SA.pdf");
      }
    }
  }, []);
  return (
    <>
      <div style={{height:'100vh'}}>
        <embed
          src={path}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      </div>
    </>
  );
}
