import React from 'react';
import { FloatButton } from 'antd';
import translate_icon from '../assets/home/translate.png';
import { t } from '../languages';
import { useVoerkaI18n } from '@voerkai18n/react';

const FloatButtonComponent: React.FC = () => {
  const { activeLanguage } = useVoerkaI18n();

  return (
    <FloatButton
      onClick={() => VoerkaI18n.change(activeLanguage === "zh" ? "en" : "zh")}  // 切换语言
      icon={<img style={{ width: 20, objectFit: 'cover' }} src={translate_icon} alt={t('语言切换按钮')} />}
    />
  );
};

export default FloatButtonComponent;
