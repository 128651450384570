
import HomeFooter from "../Home/Footer/HomeFooter"
import HomeHeader from "../Home/Header/HomeHeader"
import { t } from "../../languages"
import styled from "styled-components"
import { useEffect, useState } from "react";
import { useRequest } from "ahooks"
import { ApiMyinvitation, deleteAccount, getUserInfo, sendVerificationCode, showActivityPopup, updateEmail, updatePassword, updateUsername, UserInfo } from "../../libs/http/api"
import { Button, message, Modal } from "antd"
import LeftArea from "../../components/LeftArea"
import { useAuth } from "../../components/AuthContext"
import { useNavigate } from "react-router-dom"
import InviteActivityBar from "../Home/Header/InviteActivityBar"
import { useVoerkaI18n } from "@voerkai18n/react";


const UserAccount = () => {
  const showPopup = useRequest(showActivityPopup, { manual: true })
  const [isShowLoginOutPopup, setIsShowLoginOutPopup] = useState(false)
  const [isShowWriteOffPopup, setIsShowWriteOffPopup] = useState(false)
  const [userInfo, setUserInfo] = useState<UserInfo | null>()

  const [userNameIsEdit, setUserNameIsEdit] = useState(false)
  const [verifyEmailIsEdit, setVerifyEmailIsEdit] = useState(false)
  const [emailIsEdit, setEmailIsEdit] = useState(false)
  const [verifyPasswordIsEdit, setVerifyPasswordIsEdit] = useState(false)

  const [passwordIsShow, setPasswordIsShow] = useState(false)
  const [passwordIsEdit, setPasswordIsEdit] = useState(false)

  const [userName, setUserName] = useState("")
  const [email, setEmail] = useState("")
  const [emailVerifyCode, setEmailVerifyCode] = useState("")
  const [passwordVerifyCode, setPasswordVerifyCode] = useState("")
  const [password, setPassword] = useState("")

  const { activeLanguage } = useVoerkaI18n();

  const deleteAccountData = useRequest(deleteAccount, { manual: true })
  const getUserInfoData = useRequest(getUserInfo, { manual: true })
  const auth = useAuth(); //全局登录态
  const navigate = useNavigate();

  const [openAlert, setOpenAlert] = useState(false);
  const apiInvInfo = useRequest(ApiMyinvitation, { manual: true });

  useEffect(() => {
    getUserInfoData.runAsync().then(res => {
      console.log(res.data.data, '用户信息');
      if (res.data.code === 200) {
        setUserInfo(res.data.data)
        if (res.data.data.basic_info.login_type.includes("Email")) {
          setPasswordIsShow(true)
        }
      }
    })
  }, [])

  // 用户名修改
  const handleUserNameChange = () => {
    updateUsername(userName).then(res => {
      if (res.data.code === 200) {
        setUserNameIsEdit(false)
        console.log(res.data.data, '修改用户名');
        message.success(t("修改成功"))
        setUserInfo({ ...userInfo!, basic_info: { ...userInfo!.basic_info, username: userName } });
        console.log(userInfo, '修改后的用户信息');
      }
    })
  }

  //点击了邮箱的提交按钮，开始发验证码流程
  const handleEmailSubmit = () => {
    if (email.trim().length === 0) {
      message.error(t("请输入邮箱"))
      return
    }
    //发送验证码
    sendVerificationCode(userInfo?.basic_info.email ?? '', activeLanguage ?? 'en').then(res => {
      if (res.data.code === 200) {
        console.log(res, '邮箱发验证码');
        message.success(t("验证码已发送"))
        setVerifyEmailIsEdit(true)
      }
    })
  }

  //开始修改邮箱
  const handleEmailVerifyCodeSubmit = () => {
    if (emailVerifyCode.trim().length === 0) {
      message.error(t("请输入验证码"))
      return;
    }
    console.log(email, '邮箱');
    console.log(emailVerifyCode, '验证码')

    updateEmail(email, emailVerifyCode).then(res => {
      console.log(res, '邮箱修改');

      if (res.data.code === 200) {
        console.log(res, '邮箱修改');
        message.success(t("邮箱修改成功"))
        setVerifyEmailIsEdit(false)
        setEmailIsEdit(false)
        setEmail(""); //清空输入框
        setEmailVerifyCode(""); //清空输入框
      } else {
        message.error(t(res.data.message))
      }
    }
    )
  }

  //点击了修改密码，开始发验证码流程
  const handlePasswordSubmit = () => {
    if (password.trim().length === 0) {
      message.error(t("请输入密码"))
      return
    }

    sendVerificationCode(userInfo?.basic_info.email ?? '', activeLanguage ?? 'en').then(res => {
      if (res.data.code === 200) {
        console.log(res, '密码修改');
        message.success(t("验证码已发送"))
        setVerifyPasswordIsEdit(true)
      }
    })
  }

  //开始修改密码
  const handlePasswordVerifyCodeSubmit = () => {
    if (password.trim().length === 0 || passwordVerifyCode.trim().length === 0) {
      message.error(t("请输入密码或验证码"))
      return
    }

    updatePassword(password, passwordVerifyCode).then(res => {
      if (res.data.code === 200) {
        console.log(res, '修改密码');
        message.success(t("密码修改成功"))
        setPasswordIsEdit(false)
        setVerifyPasswordIsEdit(false)
        setPassword(""); //清空输入框
        setPasswordVerifyCode(""); //清空输入框
      }
    }
    )
  }

  return <>
    <HomeHeader />
    {/* 左边操作区域 */}
    <Container>
      <AccountTitle>{t("我的账户")}</AccountTitle>
      <Area>
        <LeftArea />

        <RightArea>
          {/* 右边内容区域 */}
          <AccountContent>
            <BasicInfo>
              <SectionTitle>{t("基本信息")}</SectionTitle>
              <InfoRow>
                <InfoLabel>{t("用户名：")}</InfoLabel>
                <InfoValue style={{ display: userNameIsEdit ? 'none' : '' }}>
                  {userInfo?.basic_info.username}
                  <SubmitButton onClick={() => setUserNameIsEdit(true)}>{t("修改")}</SubmitButton>
                </InfoValue>

                <InfoValue style={{ display: userNameIsEdit ? '' : 'none' }}>
                  <InfoInput placeholder="请修改" onChange={(e) => setUserName(e.target.value)} value={userName} />
                  <SubmitButton onClick={() => {
                    handleUserNameChange();
                  }}>{t("提交")}</SubmitButton>
                  <CButton onClick={() => {
                    setUserNameIsEdit(false)
                  }}>{t("取消")}</CButton>
                </InfoValue>

              </InfoRow>

              <InfoRow>
                <InfoLabel>{t("支持的登录方式：")}</InfoLabel>
                <InfoValue>{userInfo?.basic_info.login_type.map(item => t(item)).join("、")}</InfoValue>
              </InfoRow>

              {/* 邮箱 */}
              <InfoRow>
                <InfoLabel>{t("邮箱：")}</InfoLabel>
                <InfoValue style={{ display: emailIsEdit ? 'none' : '' }}>
                  {userInfo?.basic_info.email}
                  <SubmitButton onClick={() => {
                    setEmailIsEdit(true)
                  }}>{t("修改")}</SubmitButton>
                </InfoValue>

                <InfoValue style={{ display: emailIsEdit ? '' : 'none' }}>
                  <InfoInput placeholder="请修改" onChange={(e) => setEmail(e.target.value)} value={email} />
                  <SubmitButton onClick={handleEmailSubmit}>{t("提交")}</SubmitButton>
                  <CButton onClick={() => {
                    setEmailIsEdit(false)
                  }}>{t("取消")}</CButton>
                </InfoValue>
              </InfoRow>

              {/* 邮箱验证码 */}
              <InfoRow style={{ display: verifyEmailIsEdit ? '' : 'none' }}>
                <InfoLabel>{t("验证码：")}</InfoLabel>
                <InfoValue>
                  <InfoInput placeholder="请填写旧邮箱收到的验证码" onChange={(e) => setEmailVerifyCode(e.target.value)} value={emailVerifyCode} />
                  <SubmitButton onClick={handleEmailVerifyCodeSubmit}>{t("提交")}</SubmitButton>
                  <CButton onClick={() => {
                    setVerifyEmailIsEdit(false)
                  }}>{t("取消")}</CButton>
                </InfoValue>
              </InfoRow>

              {/* 修改密码 */}
              <InfoRow style={{ display: passwordIsShow ? '' : 'none' }}>
                <InfoLabel>{t("密码：")}</InfoLabel>
                <InfoValue style={{ display: passwordIsEdit ? 'none' : '' }}>
                  ********
                  <SubmitButton onClick={() => setPasswordIsEdit(true)}>{t("修改")}</SubmitButton>
                </InfoValue>

                <InfoValue style={{ display: passwordIsEdit ? '' : 'none' }}>
                  <InfoInput placeholder="请输入密码进行邮箱变更" onChange={(e) => setPassword(e.target.value)} value={password} />
                  <SubmitButton onClick={() => {
                    handlePasswordSubmit();
                  }}>{t("提交")}</SubmitButton>
                  <CButton onClick={() => {
                    setPasswordIsEdit(false)
                  }}>{t("取消")}</CButton>
                </InfoValue>
              </InfoRow>

              {/* 密码验证码 */}
              <InfoRow style={{ display: verifyPasswordIsEdit ? '' : 'none' }}>
                <InfoLabel>{t("验证码：")}</InfoLabel>
                <InfoValue>
                  <InfoInput placeholder="请填写旧邮箱收到的验证码" onChange={(e) => setPasswordVerifyCode(e.target.value)} value={passwordVerifyCode} />
                  <SubmitButton onClick={handlePasswordVerifyCodeSubmit}>{t("提交")}</SubmitButton>
                  <CButton onClick={() => {
                    setVerifyPasswordIsEdit(false)
                  }}>{t("取消")}</CButton>
                </InfoValue>
              </InfoRow>

              <ButtonContainer>
                <LoginOutButton onClick={() => setIsShowLoginOutPopup(true)}>{t("退出登录")}</LoginOutButton>
                <CancelButton onClick={() => setIsShowWriteOffPopup(true)}>{t("注销账户")}</CancelButton>
              </ButtonContainer>

            </BasicInfo>
            <AccountBalance>
              <SectionTitle>{t("账户余额")}</SectionTitle>
              <InfoRow>
                <InfoLabel>{t("剩余转文本页数：")}</InfoLabel>
                <InfoValue>{userInfo?.account_balance.remaining_pages.toString()}{t("页")}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>{t("剩余翻译页数：")}</InfoLabel>
                <InfoValue>{userInfo?.account_balance.translated_pages.toString()}{t("页")}</InfoValue>
              </InfoRow>
              <InviteButton onClick={async () => {
                // const resp = await showPopup.runAsync();
                // console.log(resp.data.data, '邀请活动');
                // if (resp.data.data.show_popup) {
                //   setOpenAlert(true);
                // }
                setOpenAlert(true);

              }}>{t("邀请活动")}</InviteButton>
            </AccountBalance>
          </AccountContent>
        </RightArea>
      </Area>
    </Container>

    <HomeFooter />

    <Modal centered open={isShowLoginOutPopup} onCancel={() => setIsShowLoginOutPopup(false)} title={t("退出登录")} footer={[
      <Button key="back" onClick={() => setIsShowLoginOutPopup(false)}>{t("取消")}</Button>,
      <Button key="submit" style={{ backgroundColor: '#FDC448' }} onClick={() => {
        setIsShowLoginOutPopup(false);
        auth.logout();
        navigate("/", { replace: true });
        window.location.reload();
      }
      }>{t("确定")}</Button>,
    ]} width={540}>
      {t("确定退出当前账户")}
    </Modal>

    <Modal centered open={isShowWriteOffPopup} onCancel={() => setIsShowWriteOffPopup(false)} title={t("注销账户")} footer={[
      <Button key="back" onClick={() => setIsShowWriteOffPopup(false)}>{t("取消")}</Button>,
      <Button key="submit" style={{ backgroundColor: '#FDC448' }} onClick={() => {
        setIsShowLoginOutPopup(false);
        deleteAccountData.runAsync().then((res) => {
          console.log(res, 'deleteAccountData');
          if (res.data.code === 200) {
            auth.logout();
            navigate("/", { replace: true });
            window.location.reload();
          }
        });

      }}>{t("确定")}</Button>,
    ]} width={540}>
      <span style={{ color: '#222' }}>{t("注销账户后，")}</span>
      <span style={{ color: '#FF0020' }}>{t("账户内的文件将全部清空无法找回")}</span>
      <span style={{ color: '#222' }}>{t("，剩余页数将作废，请谨慎操作")}</span>
    </Modal>

    <InviteActivityBar isOpen={openAlert} onClose={() => setOpenAlert(false)} />
  </>
}

export default UserAccount

const Container = styled.div`
    margin-top: 30px;
    width: 100%;
    background-color: #fff;
`

const Area = styled.div`
    display: flex;
    width: 100%;

`

const RightArea = styled.div`
    display: inline-block;
    flex: 1;
    background-color: #fff;
    float: right;
    margin-left: 40px;

    .right-area-main-subject {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #222222;
        line-height: 25px;
        text-align: left;
        margin-top: 22px;
    }

    .right-area-main-subject-select {
        width: 100%;
        text-align: left;
        margin-right: 120px;
    }
`

const AccountTitle = styled.p`
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 40px;
    color: #222222;
`

const AccountContent = styled.div`
  display: flex;
//   justify-content: space-between;
`;

const BasicInfo = styled.div`
    margin-left: 240px;
    width: 800px;
`;

const AccountBalance = styled.div`
  flex: 0 0 35%;
  padding-left: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
  border-left: 4px solid #FFCB54;
  padding-left: 8px;
  display: flex;
  height: 25px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const InfoLabel = styled.span`
  flex: 0 0 150px;
  font-size: 18px;
  color: #999;
  text-align: right;
  margin-right: 10px;
`;

const InfoValue = styled.span`
  font-size: 18px;
  color: #222;
  font-weight: 600;
  // background-color: red;
`;

const InfoInput = styled.input`
  flex: 1;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  font-size: 18px;
  margin-right: 8px;
  width: 300px;
  height: 48px;
  background-color: #f5f5f5;
  padding-left: 8px;
`;

const SubmitButton = styled.button`
  padding: 8px;
  color: #167ED5;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  background-color: #fff;
`;

const CButton = styled.button`
  padding: 8px;
  color: #999;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  background-color: #fff;
`;

const ButtonContainer = styled.div`
  float: right;
`;

const LoginOutButton = styled.div`
  background: #222;
  color: #fff;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  width: 240px;
  height: 60px;
  line-height: 60px;
  display: block;
`;

const CancelButton = styled.div`
  margin-top: 12px;
  color: #666;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  width: 240px;
  height: 60px;
  line-height: 60px;
  display: block;
`;

const InviteButton = styled.button`
  background-color: #ffaf00;
  color: #222;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  width: 240px;
  height: 60px;
  line-height: 60px;
    float: left;
  &:hover {
    background-color: #e89d00;
  }
`;
