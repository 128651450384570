import styled from "styled-components"
import home_icon_scan from "../../../assets/home/12.11/home_icon_scan@2x.png"
import home_icon_img from "../../../assets/home/12.11/home_icon_img@2x.png"
import home_icon_trans from "../../../assets/home/12.11/home_icon_trans@2x.png"
import home_icon_upload from "../../../assets/home/12.11/home_icon_upload@2x.png"
import alert_tip from "../../../assets/home/12.11/alert_tip.png"
import icon_pdf from "../../../assets/home/12.11/icon_pdf@2x.png"
import icon_img from "../../../assets/home/12.11/icon_img@2x.png"
import home_icon_list_del from "../../../assets/home/12.11/home_icon_list_del@2x.png"
import home_list_more from "../../../assets/home/12.11/home_list_more@2x.png"
import { px2vw } from "../../../libs/utils";
import { t } from "../../../languages";
import React, { useState, useEffect } from "react";
import { logEvent } from "../../../libs/analytics"
import { message, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import { Modal, Button } from 'antd';
import deviceuuid from "../../../libs/deviceuuid"
import tokenManager from "../../../libs/TokenManager"
import { apiDeletePdf, apiPdfTasks, TaskDetailData, TaskItemData } from "../../../libs/http/api"
import { useRequest } from "ahooks"
import { useAuth } from "../../../components/AuthContext"
import { useNavigate } from "react-router-dom"
import { Timeout } from "ahooks/lib/useRequest/src/types"
import { useVoerkaI18n } from "@voerkai18n/react"

const ProcessSection = React.memo<{ typeIndex: string, isLight?: boolean }>((param) => {
    const deletePdf = useRequest(apiDeletePdf, { manual: true }); // 删除PDF
    const { runAsync: runDelete } = deletePdf;
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [selectedPdfItem, setSelectedPdfItem] = useState<TaskDetailData | null>(null);//选中删除的index
    const { activeLanguage } = useVoerkaI18n();

    const auth = useAuth();

    const [translateCategoryIndex, setTranslateCategoryIndex] = useState(Number(param.typeIndex) ?? 0);
    const [documentCategoryIndex, setDocumentCategoryIndex] = useState(0);
    let [taskList, setTaskList] = useState<Array<TaskDetailData>>([]);
    const navigate = useNavigate();
    const [flash, setFlash] = useState(false);

    const handleItemClick = (index: number) => {
        setTranslateCategoryIndex(index);
        translateCategoryIndex === index ? setTranslateCategoryIndex(index) : setDocumentCategoryIndex(0);
    };

    const handleRightItemClick = (index: number) => {
        setDocumentCategoryIndex(index);
    };

    const translateCategory = [
        { icon: home_icon_scan, title: t('扫描件转文本'), desc: t('保留格式'), param: 'SCAN_CONVERSION' },
        { icon: home_icon_img, title: t('图片转文本'), desc: t('还原排版'), param: 'IMAGE_TO_TEXT' },
        { icon: home_icon_trans, title: t('文件翻译'), desc: t('高效精准'), param: 'DOCUMENT_TRANSLATION' }]

    const documentCategory = [
        [{
            title: t('书籍'),
            type: 0,
            param: 'BOOK',
            desc: t('高效处理书籍扫描件文本 支持提取、复制、搜索'),
            tips: [t('保留原格式'), t('文本更高清'), t('阅读更便捷')]
        },
        {
            title: t('合同'),
            type: 0,
            param: 'CONTRACT',
            desc: t('OCR扫描合同内容  便于存储、检索、减少人工错误'),
            tips: [t('扫描内容“0”失误'), t('阅读合同更清晰'), t('转换结果高效精准')]
        },
        {
            title: t('论文'),
            type: 0,
            param: 'THESIS',
            desc: t('高效处理论文扫描件 支持提取、复制、搜索 '),
            tips: [t('保留原格式'), t('简化翻译和多语言支持'), t('提高研究效率')]
        },
        {
            title: t('其他'),
            type: 0,
            param: 'OTHER',
            desc: t('更多其他扫描件内容均支持一键提取   支持翻译、复制、检索'),
            tips: [t('图片转文字 '), t('保留原格式'), t('文本更高清')]
        },
        ],
        [
            {
                title: t('图文结合'),
                type: 1,
                param: 'PICTEXT',
                desc: t('高效处理图文图片文本    支持提取、复制、搜索'),
                tips: [t('保持原图文排版'), t('快速定位重点信息'), t('高效进行复制引用')]
            },
            {
                title: t('纯文本'),
                type: 1,
                param: 'TEXT',
                desc: t('一键提取纯文本扫描件内容      支持提取、复制、搜索'),
                tips: [t('文件压缩'), t('文本更高清'), t('复制粘贴更高效')]
            }
        ],
        [
            {
                title: t('书籍'),
                type: 0,
                param: 'BOOK',
                desc: t('快速翻译多页书籍PDF文件内容'),
                tips: [t('专业术语自动适配'), t('一键生成多国语言'), t('打破语言阅读障碍')]
            },
            {
                title: t('单页'),
                type: 0,
                param: 'PAPER',
                desc: t('准确、高效翻译单页书籍内容'),
                tips: [t('专业术语自动适配'), t('一键生成多国语言'), t('打破语言阅读障碍')]
            }
        ]
    ];


    const getPDFListData = async () => {
        const transParam = translateCategory[translateCategoryIndex].param;
        const docParam = documentCategory[translateCategoryIndex][documentCategoryIndex].param;

        const resp = await apiPdfTasks(transParam, docParam);
        console.log(resp.data, '轮询');
        if (resp.data.code === 200) {
            resp.data.data.reverse();
            setTaskList(resp.data.data)
            if (!resp.data.data.some(task => task.status === "PROCESSING")) {
                tasks.cancel()
            }
        }
        return resp.data.data
    };

    const tasks = useRequest(getPDFListData, { pollingInterval: 5000, pollingWhenHidden: false, manual: true }); // 获取制作任务合集


    useEffect(() => {
        getPDFListData().then((data) => {
            if (data.some(task => task.status === "PROCESSING")) {
                tasks.runAsync(); // 只在有未完成任务时请求数据
            }
        })
    }, [])

    useEffect(() => {
        setFlash(true)
        const timer = setTimeout(() => {
            setFlash(false)
        }, 3000)
        return () => clearTimeout(timer)
    }, [param.isLight])

    useEffect(() => {
        if (auth.isLoggedIn || auth.isLocalLogin) {
            getPDFListData();
        }
    }, [auth.isLoggedIn, auth.isLocalLogin, translateCategoryIndex, documentCategoryIndex]);


    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const handleChange: UploadProps['onChange'] = (info) => {
        let newFileList = [...info.fileList];
        console.log(info, '上传中...');
        setFileList(newFileList.reverse().filter((file) => file.status !== "removed"));
    };
    const uuid = deviceuuid()

    const props = {
        action: translateCategoryIndex === 1 ? `${process.env.REACT_APP_BASE_URL}api/image_tasks` : `${process.env.REACT_APP_BASE_URL}api/upload_pdf`,
        onChange: handleChange,
        multiple: true,
        accept: translateCategoryIndex === 1 ? '.jpg,.png' : '.pdf',
        showUploadList: false,
        headers: {
            'A-Language': 'en',
            'Device-Type': 'web',
            'Version-Code': '1.0.0',
            'Device-UUID': uuid,
            'Device-Model': 'web',
            Authorization: 'Bearer ' + tokenManager.getToken(),
        },
        name: translateCategoryIndex === 1 ? 'images' : 'file',
        data: translateCategoryIndex === 1 ? {
            image_type: documentCategory[translateCategoryIndex][documentCategoryIndex].param
        } : {
            translate_type: translateCategory[translateCategoryIndex].param,
            document_category: documentCategory[translateCategoryIndex][documentCategoryIndex].param,
        }
    };

    return (
        <>
            <Container>
                {/* 左边操作区域 */}
                <LeftArea>
                    {translateCategory.map((item, index) => {
                        return <LeftAreItem
                            key={index}
                            onClick={() => handleItemClick(index)}
                            className={translateCategoryIndex === index ? "selected" : ""}
                        >
                            <img alt="apple"
                                style={{ width: px2vw(24) }}
                                src={item.icon}
                            />
                            <LeftAreItemContainer>

                                <LeftAreItemTitle className={translateCategoryIndex === index ? "selected" : ""}>
                                    {item.title}
                                </LeftAreItemTitle>

                                <LeftAreItemDesc className={translateCategoryIndex === index ? "selected" : ""}>
                                    {item.desc}
                                </LeftAreItemDesc>

                            </LeftAreItemContainer>

                        </LeftAreItem>
                    })}

                </LeftArea>

                {/* 右边上传区域 */}
                <RightArea>
                    {
                        documentCategory[translateCategoryIndex].map((item, index) => {
                            return <>
                                <RightTitle
                                    className={documentCategoryIndex === index ? "right_title_selected" : ""}
                                    onClick={() => handleRightItemClick(index)}>
                                    {item.title}
                                </RightTitle>

                            </>
                        })
                    }
                    <RightDesc activieLanguage={activeLanguage ?? 'en'}>{
                        documentCategory[translateCategoryIndex][documentCategoryIndex].desc
                    }</RightDesc>

                    {
                        documentCategory[translateCategoryIndex][documentCategoryIndex].tips.map((tip, index) => {
                            return <RightTips>
                                {tip}
                            </RightTips>
                        })
                    }

                    <FileArea>
                        <Upload {...props}>
                            <DownloadImg flash={flash}>
                                <div>
                                    <span>{t("上传第一份文件试试吧！")}</span>
                                    <img alt="upload"
                                        style={{ width: px2vw(56) }}
                                        src={home_icon_upload}
                                    />
                                    <p className="title">{t("上传文件")}</p>
                                    <p className="desc">{t("支持格式：")}{translateCategoryIndex === 1 ? 'JPG,PNG' : 'PDF'}</p>
                                </div>
                            </DownloadImg>
                        </Upload>


                        <FileDisplayContainer>
                            {
                                fileList.map((file, index) => {
                                    return <>
                                        <FileDisplayArea key={index} onClick={() => {

                                            if (file.response && file.response.code === 200 && file.status === 'done') {
                                                navigate(`/record-detail/${file.response.data.pdf_id}/${file.response.data.translate_type}`)
                                            }
                                        }}>

                                            <div className="pdf_info">
                                                <img
                                                    style={{ width: px2vw(48) }}
                                                    alt="pdf" src={file.type?.split("/")[1] === 'pdf' ? icon_pdf : icon_img} />
                                                <p className="title">
                                                    {file.name}
                                                </p>
                                                <span className="upload_res" style={{ display: (file.response && file.response.code !== 200 && file.status === 'done') ? 'block' : 'none' }}>
                                                    {t("上传失败")}
                                                </span>
                                            </div>

                                            <span className="percent" style={{ display: (file.status === 'uploading') ? '' : 'none' }}>
                                                <p className="title">{t("上传中")}...{file.percent!.toFixed(2)}%</p>
                                                <p className="desc">{t("在上传完成前，请勿离开当前页")}</p>
                                            </span>

                                            <span className="pdf_name" style={{ display: (file.response && file.response?.code === 200) ? '' : 'none' }}>
                                                <span className="finish">
                                                    {t("上传完成")}
                                                </span>

                                                <span className="detail">
                                                    {t("前往处理")}
                                                </span>
                                            </span>

                                            <span className="error" style={{ display: (file.response && file.response.code !== 200 && file.status === 'done') ? 'block' : 'none' }}>
                                                <p className="title">
                                                    {t("上传失败")}
                                                </p>
                                            </span>

                                            <img onClick={() => {
                                                fileList[index].status = 'removed'
                                                setFileList(fileList.filter((_, i) => i !== index));
                                            }
                                            } className="home_icon_list_del" style={{ width: px2vw(36) }} src={home_icon_list_del} alt="" />

                                        </FileDisplayArea>
                                    </>
                                })
                            }

                            {/* ------------------------------------------------------------------------*/}
                            {
                                taskList.map((task, index) => {
                                    return <TaskDisplayArea key={task.pdf_id} onClick={() => {
                                        navigate(`/record-detail/${task.pdf_id}/${task.translate_type}`)
                                    }
                                    }>
                                        <img onClick={(event) => {
                                            // 删除文件
                                            event.stopPropagation();
                                            setSelectedPdfItem(task);
                                            setDeleteModalVisible(true);
                                        }
                                        } className="home_icon_list_del" style={{ width: px2vw(36) }} src={home_icon_list_del} alt="" />
                                        <img
                                            style={{ width: px2vw(48) }}
                                            alt="pdf" src={task.translate_type === "IMAGE_TO_TEXT" ? icon_img : icon_pdf}
                                            className="pdf-icon"
                                        />
                                        <p className="title">
                                            {task.pdf_name}
                                        </p>




                                        {
                                            task.status === 'PROCESSING' ? <div className="processing">
                                                <span className="status">
                                                    {t("处理中") + '...' + task.progress_value + '%'}
                                                </span>

                                                <span className="detail">
                                                    {t("查看详情")}
                                                </span>
                                            </div> : <div />
                                        }

                                        {

                                            task.status === 'COMPLETED' ? <div className="completed">
                                                <span className="status">
                                                    {t("处理完成")}
                                                </span>

                                                <span className="detail">
                                                    {t("查看详情")}
                                                </span>
                                            </div> : <div />
                                        }

                                        {
                                            task.status === 'PENDING' ? <div className="pending">
                                                <span className="status">
                                                    {t("上传完成")}
                                                </span>

                                                <span className="detail">
                                                    {t("前往处理")}
                                                </span>
                                            </div> : <div />
                                        }



                                    </TaskDisplayArea>
                                })
                            }

                            <MoreListIcon onClick={() => {
                                navigate("/historical");
                            }
                            }>
                                <img style={{ width: px2vw(16), height: px2vw(28) }} src={home_list_more} alt="" />
                                <p>
                                    {t("文件")}
                                    <br />
                                    {t("列表")}
                                </p>
                            </MoreListIcon>
                        </FileDisplayContainer>
                    </FileArea>

                </RightArea>
            </Container>

            <Modal title={t("删除文件")} onCancel={() => setDeleteModalVisible(false)} open={deleteModalVisible} footer={[
                <Button key="back" onClick={() => {
                    setDeleteModalVisible(false);
                }}>{t("取消")}</Button>,
                <Button key="submit" style={{ backgroundColor: '#FDC448' }} onClick={() => {
                    setDeleteModalVisible(false);
                    logEvent("Home", "delete_click", "delete");
                    const type = selectedPdfItem?.translate_type === 'IMAGE_TO_TEXT' ? 'image' : 'pdf';
                    console.log(selectedPdfItem?.pdf_id, type);

                    runDelete(selectedPdfItem?.pdf_id ?? 0, type)
                        .then((resp) => {
                            console.log(resp);
                            setTaskList(taskList.filter((item) => item.pdf_id !== selectedPdfItem?.pdf_id));
                            message.success(t("删除成功"));
                        })
                        .catch((err) => {
                            console.log(err);

                        });
                }}>{t("确定")}</Button>,
            ]} >
                {t("您正在操作删除文件，删除后文件无法找回，请确认您的操作")}
            </Modal>
        </>

    )
})

export default ProcessSection

const Container = styled.div`
    width: 100%;
    height: 560px;
    background-color: #fff;
    display: flex;
    margin-top: 20px;
`

const LeftArea = styled.div`
    width: 330px;
    position: absolute;
    left: 0;
`

const LeftAreItem = styled.div`
    height: 100px;
    margin-left: 20px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 15px;
    margin: 0px 0px 8px 20px;
    cursor: pointer;
    opacity: 0.4;

    &.selected {
        background-color: #F5F6F7;
        border-radius: 16px 0 0 16px;
        opacity: 1.0;
    }

    &:hover {
        opacity: 1.0;
    }
`

const LeftAreItemContainer = styled.span`
    text-align: left;
`

const LeftAreItemTitle = styled.span`
    display: inline-block;
    font-size: 20px;
    margin-left: 12px;
    font-weight: 400;
    white-space: nowrap;
    &.selected {
        font-weight: 600;
    }
`

const LeftAreItemDesc = styled.span`
    display: inline-block;
    margin-left: 8px;
    white-space: nowrap;
    font-size: 16px;
    padding: 3px 8px;
    margin-top: 4px;
    background-color: rgba(251, 63, 105, 0.12);
    color: #FB3F69;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    &.selected {
        font-weight: 600;
        background-color: #FB3F69;
        color: #fff;
    }
`

const RightArea = styled.div`
    width: 1540px;
    background-color: #F5F6F7;
    padding: 0px 10px 0px 10px;
    text-align: left;
    border-radius: 16px;
    position: absolute;
    right: 40px;
    height: 530px;
`

const RightTitle = styled.span`
    display: inline-block;
    font-size: 20px;
    padding: 20px 50px 0 50px;
    font-weight: 400;
    color: #999;
    cursor: pointer;

    &.right_title_selected {
        color: #222;
        font-weight: 600;
        text-decoration: underline #FFCB54 8px;
    }

    &:hover {
        color: #222;
    }
`

const RightDesc = styled.div<{ activieLanguage: string }>`
    font-size:  ${({ activieLanguage }) => (activieLanguage === 'en' ? 28 : 32)}px;;
    font-weight: 600;
    color: #222;
    margin: 24px 0 0 50px;
`

const RightTips = styled.span`
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    height: 28px;
    color: #666;
    margin: 12px 0 0 62px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 4px;
        width: 4px;
        height: 20px;
        background-color: #FFCB54;
        border-radius: 2px;
        margin-left: -10px;
    }
`

const FileArea = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: #222;
    margin: 24px 0 0 50px;
    display: flex;
`

const DownloadImg = styled.span<{ flash: boolean }>`
    width: 223px;
    height: 315px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 2px 2px 21px -7px rgba(255,178,0,0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @keyframes flash {
        0% { border-color: #A6A6A6; }
        50% { border-color: #FFCB54; }
        100% { border-color: #A6A6A6; }
    }

    div{
        width: 202px;
        height: 294px;
        border-radius: 4px;
        border: 4px dashed #A6A6A6;
        text-align: center;
        position: relative;
        animation: ${({ flash }) => (flash ? 'flash 1.2s linear infinite' : 'none')};

        &:hover span {
            visibility: visible;
        }

        span{
            position: absolute;
            height: 64px;
            left: 0;
            right: 0;
            top: -64px;
            background-image: url(${alert_tip});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            visibility: hidden;
            font-size: 14px;
            color: #FF8152;
            line-height: 54px;
            text-align: center;
        }

        img{
            margin-top: 50px;
        }

        .title {
            font-size: 20px;
            font-weight: 600;
        }

        .desc {
            font-size: 20px;
            font-weight: 400;           
        }
    }
`

const FileDisplayContainer = styled.div`
    width: 1233px;
    border-radius: 8px;
    margin-left: 50px;
    overflow-x: auto; /* 添加这一行 */
    // white-space: nowrap;
    &::-webkit-scrollbar{
       display: none;
    }
    display: flex;
`

const FileDisplayArea = styled.div`
    display: inline-block;
    flex-shrink: 0;
    width: 233px;
    height: 315px;
    background: #fff;    
    cursor: pointer;
    margin-right: 50px;
    position: relative;
    border-radius: 8px;
    hard-clip: true;
    border: 2px solid transparent; /* 初始边框设置为透明 */
    
    &:hover {
        border: 2px solid #FFCB54; /* 鼠标悬停时改变边框颜色 */
    }

    &:hover .home_icon_list_del{
        display: none;
    }

    > * {
        border-radius: 0 0 8px 8px;
    }

    .home_icon_list_del {
        position: absolute;
        top: 10px;
        right: 17px;
        display: none;
    }

    .pdf_info {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 68px;
        text-align: center;

        img{
            margin-top: 92px;
        }
            
        .title {
            font-size: 14px;
            color: #666;
            margin: 8px;
            white-space: normal; /* 允许文本自动换行 */
            overflow-wrap: break-word; /* 同上 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
            display: -webkit-box; /* 设置为弹性盒子 */
            -webkit-line-clamp: 2; /* 设置显示的行数 */ 
            -webkit-box-orient: vertical; /* 设置弹性盒子的方向为垂直 */
            overflow: hidden; /* 超出部分隐藏 */
        }

        .upload_res {
            position: absolute;
            bottom: 19px;
            left: 0;
            right: 0;
            font-size: 14px;
            color: #FB3F69;
            margin: 4px 0 0 0;
        }
    }

    .percent {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        height: 68px;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        display: flex;

        .title {
            font-size: 16px;
            color: #fff;
            margin: 0;
        }

        .desc {
            font-size: 14px;
            color: rgba(255,255,255,0.8);
            margin: 0;
        }
    }

    .pdf_name {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        height: 68px;
        text-align: center;
        display: flex;
        align-items: center; /* 使文字垂直居中 */
        overflow: hidden; /* 隐藏超出容器的内容 */
        flex-direction: column;
        justify-content: center;

        .finish {
            font-size: 16px;
            color: #fff;
            margin: 0;
            width: 100%;
        }

        .detail {
            font-size: 14px;
            color: rgba(255,255,255,0.8);
            margin: 4px 0 0 0;
            width: 100%;
        }
    }

    .error {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        height: 68px;
        text-align: center;
        
        .title {
            font-size: 14px;
            color: #fff;
            margin: 0;
            line-height: 68px;
        }
    }
`

const TaskDisplayArea = styled.div`
    display: inline-block;
    width: 233px;
    height: 315px;
    background: #fff;    
    cursor: pointer;
    margin-right: 50px;
    position: relative;
    border-radius: 8px;
    // hard-clip: true;
    border: 2px solid transparent; /* 初始边框设置为透明 */
    flex-shrink: 0;
    text-align: center;

    &:hover {
        border: 2px solid #FFCB54; /* 鼠标悬停时改变边框颜色 */
    }

    &:hover .home_icon_list_del{
        display: block;
    }

    .home_icon_list_del {
        position: absolute;
        top: 10px;
        right: 17px;
        display: none;
    }

    .pdf-icon{
        margin-top: 92px;
    }
            
    .title {
        font-size: 14px;
        color: #666;
        margin: 8px;
        white-space: normal; /* 允许文本自动换行 */
        overflow-wrap: break-word; /* 同上 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        display: -webkit-box; /* 设置为弹性盒子 */
        -webkit-line-clamp: 2; /* 设置显示的行数 */ 
        -webkit-box-orient: vertical; /* 设置弹性盒子的方向为垂直 */
        overflow: hidden; /* 超出部分隐藏 */
    }

    

    .processing, .completed, .pending{
        position: absolute;
        font-size: 14px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        height: 68px;
        text-align: center;
        display: flex;
        align-items: center; /* 使文字垂直居中 */
        overflow: hidden; /* 隐藏超出容器的内容 */
        flex-direction: column;
        justify-content: center;
        color: #fff;
        border-radius: 0 0 8px 8px;

        .status {
            display:inline-block;
            margin-bottom: 2px;
        }

        .detail {
            display:inline-block;
            margin-top: 2px;
        }
    }

    .completed {
        opacity: 0;
    }

    &:hover .completed {
        opacity: 1;
    }
`

const MoreListIcon = styled.div`
    width: 76px;
    height: 76px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    right: 60px;
    top: 50%;
    box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.1);
    border: 1px solid #F5F6F7;
    cursor: pointer;

    &:hover {
        background: #FFCB54;
        box-shadow: 0px 2px 24px 0px rgba(246,185,45,0.6);
    }

    &:hover img {
        display: none;
    }

    &:hover p {
        display: block;
    }

    img {
        margin: 50%;
        transform: translate(-50%, -50%);
    }

    p {
        display: none;
        text-align: center;
        font-size: 16px;
    }

`