import React, { useEffect } from "react";
import { Button, Popover } from "antd";
import styled from "styled-components";
import { t } from "../../../languages";
import { useNavigate } from "react-router-dom";
import { apiArticleList } from "../../../libs/http/api";
import { useRequest } from "ahooks";
import arrow from "../../../assets/home/arrow.png";
import { useVoerkaI18n } from "@voerkai18n/react";
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
`;

const GridItem = styled.div`
  width: 580px;
  height: 162px;
  text-align: center;
  display: flex;
  border-radius: 8px;
  align-items: start;
  justify-content: start;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    background: #fdc448;
  }
`;

const Image = styled.img`
  margin-top: 16px;
  margin-left: 16px;
  width: 204px;
  height: 130px;
`;

const Label = styled.label`
  margin-top: 24px;
  margin-left: 20px;

  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  text-align: left;
  font-style: normal;
`;

const MoreButton = styled.button`
  margin-top: 24px;
  margin-bottom: 24px;
  background: #fdc448;
  width: 140px;
  height: 50px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 16px;
  border: none;
  cursor: pointer;
`;

const MoreIcon = styled.img`
  width: 10px;
  height: 18px;
  margin-left: 8px;
`;

const PopoverBlog: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const articleList = useRequest(apiArticleList, { manual: true }); // 获取文章列表
  const { activeLanguage } = useVoerkaI18n();
  var navigate = useNavigate();

  useEffect(() => {
    articleList.runAsync(activeLanguage ?? "en");
  }, []);

  const content = (
    <center>
      <GridContainer>
        {articleList?.data?.data.data?.slice(0, 3).map((item) => (
          <GridItem
            onClick={() => {
              navigate(`/article/${item.index}/${item.keyword_tag}`);
            }}
          >
            <Image src={item.image_path} />
            <Label>{item.title}</Label>
          </GridItem>
        ))}
      </GridContainer>
      <MoreButton
        onClick={() => {
          navigate("/articles");
        }}
      >
        {t("更多")}
        <MoreIcon alt="" src={arrow} />
      </MoreButton>
    </center>
  );

  return <Popover content={content}>{children}</Popover>;
};

export default PopoverBlog;
