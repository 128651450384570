import map_detail_img_1 from "../../assets/scenes/map_detail_img_1@2x.png";
import styled from "styled-components";
import { t } from "../../languages";
import { Flex, Layout } from "antd";
import HomeHeader from "../Home/Header/HomeHeader";
import HomeFooter from "../Home/Footer/HomeFooter";
import ProcessSection from "../Home/components/ProcessSection";


const ResearchInstitution = () => {
    return <>
        <Layout style={{ background: "#FFF", alignItems: "center" }}>
            <HomeHeader />

            <ContentTextTitle>{t("学术研究机构")}</ContentTextTitle>

            <Flex vertical>
                <ContentFlex>
                    <ContentTextBox>
                        <ContentTextBoxTitle>{t("增强你的数据集")}</ContentTextBoxTitle>
                        <ContentTextBoxTxt>
                            <b>{t("比竞争对手更完备")}</b> <br />
                            {t("扫描件也可以成为模型可阅读的数据集")}
                            <br />
                            {t("古早文档统统收入囊中")}
                        </ContentTextBoxTxt>
                    </ContentTextBox>
                    <ContentImage src={map_detail_img_1} alt={t("扫描后增强数据集示例图")}></ContentImage>
                </ContentFlex>


            </Flex>
            <ProcessSection typeIndex="0"/>
            <div style={{ height: "80px" }}></div>
            <HomeFooter />

        </Layout>
    </>
}

export default ResearchInstitution

const ContentTextTitle = styled.div`
  font-family: Montserrat Blod;
  font-weight: 600;
  font-size: 36px;
  color: #222222;
  text-align: center;
  font-style: normal;
  white-space: nowrap;
  margin-top: 60px;
`;

const ContentTextBoxTitle = styled.div`
  font-family: Montserrat Blod;
  font-weight: 600;
  font-size: 32px;
  color: #222222;
  line-height: 62px;
  text-align: left;
  font-style: normal;
  white-space: nowrap;
`;

const ContentFlex = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    background-color: #F5F6F7;
    width: 1280px;
    height: 420px;
    padding-left: 80px;
    margin-bottom: 80px;
    border-radius: 24px;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ContentTextBoxTxt = styled.div`
  margin-top: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 48px;
  text-align: left;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 77px;
    line-height: 123px;
    margin-top: 42px;
  }
`;

const ContentImage = styled.img<{ order?: number }>`
  width: 360px;
  height: 360px;
  align-self: center;

  @media only screen and (max-width: 768px) {
    width: 1229px;
    height: 1229px;
    order: ${(props) => (props.order ? props.order : 0)};
  }
`;

const ContentTextBox = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 102px;
    padding-right: 102px;
    align-items: start;
    margin-top: 154px;
  }
`;