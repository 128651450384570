import { useEffect, useState } from "react";
import { TaskItemData, apiGetTrPreviewDetail } from "../../../libs/http/api";
import { useRequest } from "ahooks";

const useTrDetailPolling = (
  pdf_id: number,
  trCallBack: (data: TaskItemData) => void
) => {
  const [startPolling, setStartPolling] = useState(false);
  const delay = 5000;

  const trdDetail = useRequest(apiGetTrPreviewDetail, { manual: true }); //获取单个PDF信息
  const { runAsync: getTrDetail } = trdDetail;

  let timeoutId: NodeJS.Timeout;

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      try {
        try {
          const response = await getTrDetail(pdf_id);
          // console.log(response, 'getTrDetail');
          
          if (response.data.code === 200) {
            trCallBack(response.data.data);
          }
        } catch {}

        if (mounted) {
          timeoutId = setTimeout(fetchData, delay);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (mounted) {
          timeoutId = setTimeout(fetchData, delay);
        }
      }
    };

    if (startPolling) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [startPolling]);

  const start = () => setStartPolling(true);
  const stop = () => {
    clearTimeout(timeoutId);
    setStartPolling(false);
  };
  const runAsyncDetail = async () => {
    try {
      const response = await getTrDetail(pdf_id);
      if (response.data.code === 200) {
        trCallBack(response.data.data);
      }
    } catch {}
  };
  return { start, stop, runAsyncDetail };
};

export default useTrDetailPolling;
