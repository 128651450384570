export default {
    "支持格式：PDF": 1,
    "我们想说": 2,
    "更多": 3,
    "上传文件": 4,
    "处理记录": 5,
    "上传失败！": 6,
    "下载文件共需页数": 7,
    "输入邀请码": 8,
    "邀请码抵扣": 9,
    "每个邀请码可以抵扣500页内的文件": 10,
    "请输入邀请码": 11,
    "抵扣": 12,
    "正在上传PDF文件...": 13,
    "请上传正确的PDF文件！": 14,
    "正在上传": 15,
    "已上传": 16,
    "处理中": 17,
    "已完成": 18,
    "处理失败": 19,
    "确认": 20,
    "下载": 21,
    "支付下载": 22,
    "文件上传完成后可以确认生成格式，您可以同时上传更多文件": 23,
    "支付": 24,
    "登录": 25,
    "谷歌一键登录": 26,
    "正在登录": 27,
    "分享链接给新朋友注册，双方将获得100页Pro转换": 28,
    "复制专属链接": 29,
    "图片分享": 30,
    "扫描二维码注册": 31,
    "获得100页PDF Pro处理次数": 32,
    "退出账号": 33,
    "复制成功！": 34,
    "登录已过期，请重新登录！": 35,
    "保存至本地": 36,
    "优先抵扣页数：": 37,
    "推荐": 38,
    "识别正确率": 39,
    "填写邀请码": 40,
    "你将获得100页PDF PRO处理次数": 41,
    "提交": 42,
    "定价": 43,
    "99.5%识别正确率": 44,
    "S$0.07/页": 45,
    "适合专业人士/企业/机构": 46,
    "尽可能高性价比 尽可能高效": 47,
    "让大语言模为您智能纠错": 48,
    "99%识别正确率": 49,
    "S$0.05/页": 50,
    "适合对精确度要求不高的个人": 51,
    "尽可能低廉": 52,
    "满足日常需求": 53,
    "图书转文字": 54,
    "减少95%存储成本": 55,
    "通过图片转文字进行存储": 56,
    "90MB轻松转为6MB": 57,
    "瘦身文件简简单单": 58,
    "你可以存更多": 59,
    "存储更多，看见更多": 60,
    "LLMs企业/学术研究机构": 61,
    "增强你的数据集": 62,
    "比竞争对手更完备": 63,
    "扫描件也可以成为模型可阅读的数据集": 64,
    "古早文档统统收入囊中": 65,
    "电子书企业": 66,
    "降低95%成本": 67,
    "快速促进书籍、档案数字化": 68,
    "节省存储、传输": 69,
    "更好服务用户": 70,
    "给用户最快速提供最高清的图书": 71,
    "节省存储成本": 72,
    "小小的移动设备容纳更多书籍": 73,
    "再也不必担心存储空间": 74,
    "对自己更好": 75,
    "清晰的阅读，美好的体验": 76,
    "学生/老师": 77,
    "课件/文献语言障碍不复存在": 78,
    "扫描件变文本件": 79,
    "直接翻译就好啦": 80,
    "DDL杀手": 81,
    "快速复制引用，论文无忧": 82,
    "残障人士": 83,
    "让所有文件信息都能被读取": 84,
    "扫描件转换文本后即可成为能够被语音阅读的文件": 85,
    "让所有文件变成无障碍": 86,
    "关心在身边的他/她们": 87,
    "如果你希望为无障碍文件作出贡献": 88,
    "非常欢迎联系我们": 89,
    "我们将收录您的书籍，用于公益事业": 90,
    "语言学习者": 91,
    "快速提升语言能力": 92,
    "多种语言自由切换阅读，不必再浪费过多时间查阅": 93,
    "你可以学习更多": 94,
    "看见更多语种，看见更多可能": 95,
    "王先生": 96,
    "从事LLM相关领域研发": 97,
    "非常好，推荐！": 98,
    "很不错，有了这个工具再也不用带着纸质资料外出了。": 99,
    "文本提取准确度很高，大多数类型的扫描件转换完都没什么误差。": 100,
    "转换完体验太棒了！终于可以翻译成多种语言！阅读毫无障碍！": 101,
    "看了别人推荐过来的，很好用。": 102,
    "使用场景": 103,
    "常见问题": 104,
    "Q&A": 105,
    "Blog": 106,
    "阅读爱好者": 107,
    "分享更多Pdf to Pdf相关内容": 108,
    "分享更多Pdf to Pdf相关内容、ocr文本提取技术、文件瘦身": 109,
    "常见问题，图片OCR文本识别FAQ": 110,
    "在这里，我们列出了一些关于pdf到pdf转换的常见问题。如果您没有找到所需的信息，请随时通过pdftopdf@leqi.ai与我们联系。": 111,
    "免费在线OCR—将PDF扫描件转换为文本": 112,
    "通过ocr技术，将pdf扫描件转换为pdf文本文件，一键实现文件瘦身的同时保留文件原排版。阅读体验更佳，内容复制、搜索无阻碍!": 113,
    "上传文件，通过OCR技术快速识别pdf扫描件内容，提取结果精准度高达99%＋。PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件。": 114,
    "Pdf to Pdf| 高效工具全方位定价方案 | 量身定制满足您的需求": 115,
    "探索Pdf to Pdf软件的灵活定价计划，体验无与伦比的功能，享受个性化服务，现在注册更有超值优惠！": 116,
    "验证码错误！": 117,
    "邮箱登录": 118,
    "请输入邮箱": 119,
    "请输入密码": 120,
    "验证码已发送至邮箱": 121,
    "请输入验证码": 122,
    "设置密码": 123,
    "从事商务工作": 124,
    "刘先生": 125,
    "从事教师工作": 126,
    "赵先生": 127,
    "陈先生": 128,
    "个人财务管理者": 129,
    "张女士": 130,
    "可处理页书： ": 131,
    "语言": 132,
    "邮箱：": 133,
    "立即转换 pdf": 134,
    "PDF扫描件文字提取利器，一键提升企业与学术文献处理效率": 135,
    "PDFtoPDF文字提取利器，可用于快速摘录文献、报纸、杂志上的重要信息，进行引用或创作，提高研究和写作效率。支持将手稿或旧文档数字化，方便存储和检索，是LLMs企业和学术研究机构的得力助手！": 136,
    "高效提取PDF扫描件文本——电子书企业必备工具": 137,
    "利用先进的OCR技术，PDFtoPDF网站能精准提取PDF扫描件中的文字内容，助力电子书企业快速将纸质书籍、文档转变为可编辑的文本，实现数字化飞跃。简化编辑流程，提升出版效率，为您的电子书项目注入无限创意与灵活性。立即体验，开启智慧出版新时代。": 138,
    "PDF扫描件图片文本一键提取：从图片到清晰文字，升级阅读爱好者体验": 139,
    "专为阅读爱好者设计，这款OCR工具能巧妙地从PDF扫描件中抓取图片内的文字，将其转化为清晰、易读的文本格式。从此，不再受限于扫描文档的阅读不便，享受如纸质书般的流畅与清晰，让每一次阅读都成为一种享受。提升文本质量，尽享无界阅读新乐趣，现在就加入我们，开启您的舒适阅读之旅。": 140,
    "教育助力：PDF扫描件文本轻松提取，优化学习资料，提升教学体验": 141,
    "专为学生和教师打造的OCR解决方案，高效从PDF扫描件及图片中精准提取文本，转化成清晰可编辑的内容，让繁琐的学习资料瞬间变得井井有条。无论是论文研读、讲义整理还是教材制作，都能显著提升效率，助力学术研究与课堂教学迈上新台阶。立即体验，开启智慧学习新篇章。": 142,
    "OCR文字识别技术：将PDF扫描件转化为语音可读文本，构筑无障碍信息桥梁": 143,
    "利用先进的Text Recognition OCR技术，将PDF扫描文档轻松转换成可编辑、可语音阅读的文本，打破了残障人士阅读障碍。无论是学习资料、工作文件还是日常资讯，都能瞬间转化，让信息获取不再受限，促进数字内容的全面无障碍。": 144,
    "OCR技术赋能语言学习：PDF扫描件秒变文本，多语言翻译随心阅": 145,
    "OCR文本提取 & 翻译神器：一键将PDF扫描文档转换成可编辑文本，无论是外语教材还是原版文献，都能即扫即译。支持多种语言切换，大大节省查阅时间，多语言阅读无障碍，学习加速新体验！": 146,
    "上传PDF扫描件，轻松转换文本——高效PDF转文字工具": 147,
    "端点": 148,
    "认证": 149,
    "需要'web'类型的JWT Token": 150,
    "请求头": 151,
    "响应": 152,
    "成功": 153,
    "失败": 154,
    "Python代码示例": 155,
    "使用示例": 156,
    "参数": 157,
    "需要'test_company'类型的JWT Token": 158,
    "PDF文件，作为multipart/form-data上传": 159,
    "错误信息，例如：No file part in the request": 160,
    "PDF处理任务的ID": 161,
    "API": 162,
    "正式密钥": 163,
    "测试环境": 164,
    "接入文档": 165,
    "获取密钥": 166,
    "密钥:": 167,
    "复制": 168,
    "充值": 169,
    "剩余：": 170,
    "页": 171,
    "登录获取密钥": 172,
    "可免费测试3次PDF文件": 173,
    "1. 获取客户端密钥": 174,
    "复制代码": 175,
    "2. 获取访问令牌": 176,
    "3. 上传PDF文件": 177,
    "4. 获取PDF处理状态": 178,
    "5. 下载PDF文件": 179,
    "企业抬头:": 180,
    "请输入": 181,
    "税号:": 182,
    "开户行:": 183,
    "电话:": 184,
    "地址:": 185,
    "10000页": 186,
    "50000页": 187,
    "复制成功": 188,
    "请确保将 `<YOUR_WEB_JWT_TOKEN>`, `<YOUR_TEST_COMPANY_JWT_TOKEN>`,": 189,
    "`<YOUR_CLIENT_SECRET>`, `<YOUR_TASK_ID>`, 和 `path/to/your/file.pdf` 替换为合适的值，以进行测试。": 190,
    "提交成功": 191,
    "感谢您的提交，我们将在10个工作日内处理您的开票请求，如有疑问或其他要求请联系我们的邮箱：pdftopdf@leqi.ai": 192,
    "免费处理文件数": 193,
    "Apple ID": 194,
    "提示": 195,
    "您已享受一次免费文件处理，您还可以通过分享您的链接或邀请码邀请更多新用户注册，获取免费处理100页": 196,
    "复制邀请链接": 197,
    "下载邀请海报": 198,
    "图片转文字节省空间示例图": 199,
    "图片转文字存储更多示例图": 200,
    "语言切换按钮": 201,
    "扫描后增强数据集示例图": 202,
    "图片提取文字后节省存储示例图": 203,
    "PDF扫描前后对比图": 204,
    "移动端节省存储成本示例图": 205,
    "扫描件提取文本直接翻译": 206,
    "快速复制示例图": 207,
    "扫描件提取文本可被读取示例图": 208,
    "书本ocr收录": 209,
    "扫描件提取文本供翻译": 210,
    "文本提取后可翻译成所需语言": 211,
    "正在制作中...": 212,
    "正在下载中...": 213,
    "请选择输出格式": 214,
    "请选择翻译目标语言": 215,
    "预览": 216,
    "收起处理文件": 217,
    "展开处理文件": 218,
    "OCR": 219,
    "请选择源文件中包含的语言，将提升识别精度，如未选择则智能识别": 220,
    "源文件包含语言：": 221,
    "请选择": 222,
    "中文": 223,
    "英文": 224,
    "输出文件格式：": 225,
    "制作中": 226,
    "开始制作": 227,
    "正在转换文件格式:": 228,
    "翻译": 229,
    "翻译目标语言：": 230,
    "翻译中": 231,
    "开始翻译": 232,
    "正在翻译文件:": 233,
    "预览页面加载中，请稍后…": 234,
    "加载失败": 235,
    "重新制作": 236,
    "邮箱或密码错误": 237,
    "Facebook": 238,
    "请输入验证码！": 239,
    "请输入密码！": 240,
    "下载成功": 241,
    "下载失败": 242,
    "文件高清化与多语言翻译一站解决": 243,
    "扫描件转文本": 244,
    "保留格式": 245,
    "图片转文本": 246,
    "还原排版": 247,
    "文件翻译": 248,
    "高效精准": 249,
    "文字识别精准": 250,
    "高精度OCR文字识别技术，高达99.5%的识别精确度让扫描件提取文字后即刻能用，节省90%无效时间。目前可识别中文简体、英文，持续增加中。": 251,
    "保留文件原排版": 252,
    "完全还原原文件排版，PDF在线格式转换工具让你从繁琐的逐字输入工作中解放，同时因其高精度的转换结果，让你彻底摆脱人工比对原稿逐字校对、排版的苦恼。": 253,
    "支持多端": 254,
    "收藏网址pdftopdf.ai，无论是手机还是电脑，都可直接使用，无需下载、不占内存，方便快捷！外出再也不用随身带电脑，掏出手机即可在移动端完成文件处理，随时随地解决PDF文件格式转换、文本提取、编辑、压缩、翻译的问题！": 255,
    "文件压缩": 256,
    "使用PDF to PDF在线工具，可免费试用OCR文本提取功能，经过提取的PDF扫描件将转变为PDF文本文件格式，且转换格式后的文件将被进行高度压缩。不花一分钱同时获得OCR文字识别和PDF文件压缩的结果，存储更节省空间！": 257,
    "书籍": 258,
    "高效处理书籍扫描件文本 支持提取、复制、搜索": 259,
    "保留原格式": 260,
    "文本更高清": 261,
    "阅读更便捷": 262,
    "合同": 263,
    "OCR扫描合同内容  便于存储、检索、减少人工错误": 264,
    "扫描内容“0”失误": 265,
    "阅读合同更清晰": 266,
    "转换结果高效精准": 267,
    "论文": 268,
    "高效处理论文扫描件 支持提取、复制、搜索 ": 269,
    "简化翻译和多语言支持": 270,
    "提高研究效率": 271,
    "其他": 272,
    "更多其他扫描件内容均支持一键提取   支持翻译、复制、检索": 273,
    "图片转文字 ": 274,
    "图文结合": 275,
    "高效处理图文图片文本    支持提取、复制、搜索": 276,
    "保持原图文排版": 277,
    "快速定位重点信息": 278,
    "高效进行复制引用": 279,
    "纯文本": 280,
    "一键提取纯文本扫描件内容      支持提取、复制、搜索": 281,
    "复制粘贴更高效": 282,
    "快速翻译多页书籍PDF文件内容": 283,
    "专业术语自动适配": 284,
    "一键生成多国语言": 285,
    "打破语言阅读障碍": 286,
    "单页": 287,
    "准确、高效翻译单页书籍内容": 288,
    "支持格式：": 289,
    "上传第一份文件试试吧！": 290,
    "上传中": 291,
    "在上传完成前，请勿离开当前页": 292,
    "上传失败": 293,
    "全部": 294,
    "处理完成": 295,
    "文件列表": 296,
    "查看详情": 297,
    "超爱": 298,
    "请您评价一下我们的网站": 299,
    "不喜欢": 300,
    "无感": 301,
    "还不错": 302,
    "喜欢": 303,
    "请选择一个主题": 304,
    "您的建议": 305,
    "邮件/联系方式": 306,
    "您向您的朋友和同事推荐我们的可能性有多大": 307,
    "开具发票": 308,
    "电子发票发送至邮箱:": 309,
    "请填写签收邮箱": 310,
    "提交信息": 311,
    "学术研究机构": 312,
    "站内搜索": 313,
    "我的账户": 314,
    "搜索": 315,
    "查看账户": 316,
    "退出登录": 317,
    "关于PDFtoPDF": 318,
    "BLOG": 319,
    "隐私政策": 320,
    "联系我们": 321,
    "学术研究机构/研究员": 322,
    "LLMs企业": 323,
    "学生": 324,
    "教师": 325,
    "更多合作": 326,
    "免费使用": 327,
    "欢迎关注我们": 328,
    "加载中:": 329,
    "预览加载中，请稍后...": 330,
    "待处理": 331,
    "是否确认删除该记录?": 332,
    "删除成功": 333,
    "一经删除，无法恢复，请谨慎操作！": 334,
    "结果文件预览": 335,
    "开始处理": 336,
    "上传新文件": 337,
    "建议": 338,
    "表扬": 339,
    "bug": 340,
    "疑问": 341,
    "不太可能": 342,
    "非常可能": 343,
    "修改成功": 344,
    "验证码已发送": 345,
    "邮箱修改成功": 346,
    "请输入密码或验证码": 347,
    "密码修改成功": 348,
    "基本信息": 349,
    "用户名：": 350,
    "修改": 351,
    "支持的登录方式：": 352,
    "验证码：": 353,
    "密码：": 354,
    "注销账户": 355,
    "账户余额": 356,
    "剩余转文本页数：": 357,
    "剩余翻译页数：": 358,
    "邀请活动": 359,
    "取消": 360,
    "确定": 361,
    "确定退出当前账户": 362,
    "注销账户后，": 363,
    "账户内的文件将全部清空无法找回": 364,
    "，剩余页数将作废，请谨慎操作": 365,
    "邀请码": 366,
    "文件": 367,
    "列表": 368,
    "省钱省时示例图": 369,
    "有效=高效": 370,
    "只做有效的事": 371,
    "节省时间、财力": 372,
    "把耗时耗力的重复性工作安心交给我们处理": 373,
    "请填写完整信息": 374,
    "高效处理图文图片文本 支持提取、复制、搜索": 375,
    "上传完成": 376,
    "支付成功": 377,
    "请填写正确的邮箱": 378,
    "发送成功": 379,
    "发送失败": 380,
    "提交失败": 381,
    "正在加急处理中，请稍后哦": 382,
    "删除文件": 383,
    "预览图请求失败": 384,
    "重新加载": 385,
    "您正在操作删除文件，删除后文件无法找回，请确认您的操作": 386,
    "前往处理": 387
}