import { Flex, Modal, Spin, Popover, message, Button } from "antd";
import { t } from "../../../languages";
import logo from "../../../assets/logo@2x.png";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { isMobile, px2vw } from "../../../libs/utils";
import { StatusChangeInfo, useAuth } from "../../../components/AuthContext";
import imgGoogle from "../../../assets/home/login_icon_google@2x.png";
import imgFacebook from "../../../assets/home/login_icon_facebook@2x.png";
import imgEmail from "../../../assets/home/login_icon_email@2x.png";
import imgEdit from "../../../assets/home/ocr_icon_edit@2x.png";
import ocrUserIcon from "../../../assets/home/ocr_user@2x.png";
import appleIcon from "../../../assets/home/login_icon_apple@2x.png"
import { useRequest, useSafeState } from "ahooks";
import InviateBar from "./InviateBar";
import { apiCheckEmail, apiSendEmail, apiSetPassword, showActivityPopup } from "../../../libs/http/api";
import InviateCodeModel from "../../../components/InviateCodeModel";
import { logEvent } from "../../../libs/analytics";
import PopoverBlog from "./PopoverBlog";
import HeaderDrawer from "./HeaderDrawer";
import h5_open from "../../../assets/home/mobile/h5_nav_menu@2x.png"
import search_icon from "../../../assets/home/12.11/search_icon.png"
import emitter from "../../../libs/eventBus";
import { IResolveParams, LoginSocialApple } from "../components/LoginSocialApple";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { PublicClientApplication } from "@azure/msal-browser";
import InviteActivityBar from "./InviteActivityBar";
import { useGoogleLogin } from "@react-oauth/google";
import { useVoerkaI18n } from "@voerkai18n/react";

const msalConfig = {
  auth: {
    clientId: "db2378dd-0b0c-452e-a9d0-841b0bbe2bce",
    authority: "https://login.microsoftonline.com/5cf3a189-e7f1-4c60-8cb1-152f2d1d6e94",
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

enum LoginStatus {
  Page,
  EmailLogin,
  EmailPwd,
  EmailSendCode,
  EmailSetPwd
}

export default function HomeHeader() {
  const { activeLanguage } = useVoerkaI18n();
  const inviateBarRef = useRef<{ onInviateClick: (isUrl: boolean) => void }>(null);
  const showPopup = useRequest(showActivityPopup, { manual: true })
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("invite_code"); // 邀请码
  const officialInviteCode = searchParams.get("official_invite_code"); // 邀请码
  const navigate = useNavigate();
  const [isMobileOpen, setIsMobileOpen] = useSafeState(false); //移动端页面更多按钮
  const [isPopInvDialog, setPopInvDialog] = useSafeState(false); //退出账号按钮
  const [isPopLogout, setisPopLogout] = useSafeState(false); //退出账号按钮
  const [spinning, setSpinning] = useSafeState(false); // 加载态
  const [isShowLoginOutPopup, setIsShowLoginOutPopup] = useState(false)
  const [logingLoading, setLogingLoading] = useSafeState(false); //登录中状态
  const auth = useAuth(); //全局登录态
  const [isScrolled, setIsScrolled] = useState(false);
  const [layout, setLayout] = useState<LoginStatus>(LoginStatus.Page);
  const [canEmailInput, setEmailInput] = useState<boolean>(true); // 邮箱是否可以输入
  const [email, setEmail] = useState(""); // 邮箱
  const [code, setCode] = useState(""); // 邮箱验证码
  const [password, setPassword] = useState(""); // 邮箱密码
  const checkApi = useRequest(apiCheckEmail, { manual: true });
  const sendApi = useRequest(apiSendEmail, { manual: true });
  const setPwdApi = useRequest(apiSetPassword, { manual: true });
  const inputRef = useRef<HTMLInputElement>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [showInviteCode, setShowInviteCode] = useState(false)
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    // checkFree()
    msalInstance.initialize();
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!auth.isLoggedIn) {
      return setShowInviteCode(true)
    }
    showPopup.runAsync().then(resp => {
      console.log(resp.data.data, '邀请活动');
      if (resp.data.data.show_popup) {
        setShowInviteCode(true)
      }
    });

  }, []);

  useEffect(() => {
    emitter.on('login', onLoginClick);
    if (officialInviteCode && auth.isLoggedIn === false) {
      onLoginClick();
    }
    return () => {
      emitter.off('login', onLoginClick);
    };

  }, []);

  useEffect(() => {
    if (auth) {
      //监听登录中状态
      const handleStatusChange = (info: StatusChangeInfo) => {
        setLogingLoading(info.isLoggedLoading);
        if (info.isLoggedLoading) {
          console.log('=====登录中=====');
        }
      };

      // 添加监听器
      auth.addStatusChangeListener(handleStatusChange);

      // 组件卸载时移除监听器
      return () => {
        auth.removeStatusChangeListener(handleStatusChange);
      };
    }
  }, [auth]);

  const onLoginClick = () => {
    if (auth.isLoggedIn) {
      onLogoutClick();
      return;
    }

    auth.setLoginModel(true);
    logEvent("Home", "login_click", "login");
  };

  const onMicrosoftLogin = async (id_token: string) => {
    if (id_token !== undefined) {
      setSpinning(true);
      auth.setLoginModel(false);
      await auth.loginMicrosoft(id_token, inviteCode, officialInviteCode);
      setSpinning(false);
    }
  };

  const onAppleLogin = async (id_token: string) => {
    if (id_token !== undefined) {
      setSpinning(true);
      auth.setLoginModel(false);
      await auth.loginApple(id_token, inviteCode, officialInviteCode);
      setSpinning(false);
    }
  };

  const onLogoutClick = () => {
    setisPopLogout(false);
    auth.logout();
    logEvent("Home", "logut_click", "logout");
    navigate("/0", { replace: true });
    window.location.reload();
  };

  const onGoogleLogin = async (access_token: string) => {
    if (access_token) {
      setSpinning(true);
      auth.setLoginModel(false);
      const resp = await auth.loginGoogle(access_token, inviteCode, officialInviteCode);
      console.log(resp, 'onGoogleLogin');
      setSpinning(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      console.log(tokenResponse, '谷歌tokenResponse')
      if (tokenResponse.access_token) {
        // 用 access_token 获取用户信息
        onGoogleLogin(`${tokenResponse.access_token}`)
      }
    },
    scope: "openid email profile",
  });

  const onFacebookLogin = async (resp: any) => {
    if (resp !== undefined) {
      setSpinning(true);
      auth.setLoginModel(false);
      var accessToken = resp.accessToken as string;
      await auth.loginFacebook(accessToken, inviteCode, officialInviteCode);
      setSpinning(false);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setisPopLogout(newOpen);
  };

  const showInviteDialog = () => {
    setPopInvDialog(true);
    logEvent("Home", "Click", "invite dialog");
  };

  const checkEmail = async () => {
    if (!email.length) {
      return
    }
    const resp = await checkApi.runAsync(email);
    if (resp.data.data.has_registered) {
      setEmailInput(false);
      setLayout(LoginStatus.EmailPwd);
    } else {
      setLayout(LoginStatus.EmailSendCode);
      sendApi.runAsync(email, activeLanguage ?? "en");
    }
  };

  const register = async () => {
    if (code.trim().length === 0) {
      message.error(t("请输入验证码！"));
      return
    }
    if (password.trim().length === 0) {
      message.error(t("请输入密码！"));
      return
    }
    const resp = await auth.registerEmail(
      email,
      code,
      password,
      inviteCode,
      officialInviteCode
    );
    if (resp) {
      modelClose();
    } else {
      message.error(t("验证码错误！"));
    }
  };

  const loginWithEmail = async () => {
    const resp = await auth.loginWithEmail(email, password);
    console.log(resp, "resp-email");

    if (resp) {
      modelClose();
    } else {
      setLogingLoading(false);
    }
  };

  const modelClose = () => {
    setLayout(LoginStatus.Page);
    auth.setLoginModel(false);
    setEmailInput(true);
    setPassword("");
    setCode("");
    setEmail("");
  };

  const renderLayout = () => {
    switch (layout) {
      case LoginStatus.Page:
        return (
          <RenderLayoutStyle>
            <LoginTitle>{t("登录")}</LoginTitle>
            <GoogleLoginBtn
              onClick={() => login()}
            >
              <span>
                {t("谷歌一键登录")}
                <img alt="google" src={imgGoogle} />
              </span>
            </GoogleLoginBtn>

            <GoogleLoginBtn>
              <FacebookLogin
                appId="885064993749005"
                onSuccess={(response) => {
                  console.log('Login Success!', response);
                  onFacebookLogin(response);
                }}
                onFail={(error) => {
                  console.log('Login Failed!', error);
                }}
                onProfileSuccess={(response) => {
                  // console.log('Get Profile Success!', response);
                }}
                render={({ onClick, logout }) => (
                  <div onClick={onClick}>
                    <span>
                      {t("Facebook")}
                      <img alt="facebook" src={imgFacebook} />
                    </span>
                  </div>
                )}
              />
            </GoogleLoginBtn>

            <GoogleLoginBtn>
              <LoginSocialApple
                client_id={process.env.REACT_APP_APPLE_ID || ""}
                scope={"name email"}
                redirect_uri="https://pdftopdf.ai/signinwithapple"
                // redirect_uri="http://localhost:3000/signinwithapple"
                onReject={(err: any) => {
                  console.log(err);
                }}
                onResolve={function ({ provider, data }: IResolveParams): void {
                  if (data) {
                    onAppleLogin(data.authorization.id_token)
                  }
                }}
              >
                <span>
                  {t("Apple ID")}
                  <img alt="apple" src={appleIcon} />
                </span>
              </LoginSocialApple>
            </GoogleLoginBtn>
            <LoginBottomIcons>
              <LoginBottomCirle onClick={() => {
                setLayout(LoginStatus.EmailLogin);
              }}>
                <BottomIcons src={imgEmail} />
              </LoginBottomCirle>
            </LoginBottomIcons>

          </RenderLayoutStyle>
        );
      case LoginStatus.EmailLogin:
        return (
          <RenderLayoutStyle>
            <LoginTitle>{t("邮箱登录")}</LoginTitle>
            <InputWrapper>
              <EmailInput
                key={"old_email"}
                ref={inputRef}
                disabled={!canEmailInput}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t("请输入邮箱")}
              ></EmailInput>
              {!canEmailInput && (
                <InputIcon
                  onClick={() => {
                    setEmailInput(true);
                  }}
                  src={imgEdit}
                />
              )}
            </InputWrapper>
            <SumbitButtom onClick={checkEmail}>{t("确认")}</SumbitButtom>
          </RenderLayoutStyle>
        );
      case LoginStatus.EmailPwd:
        return (
          <RenderLayoutStyle>
            <LoginTitle>{t("邮箱登录")}</LoginTitle>
            <InputWrapper>
              <EmailInput
                key={"old_email"}
                ref={inputRef}
                disabled={!canEmailInput}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t("请输入邮箱")}
              ></EmailInput>
              {!canEmailInput && (
                <InputIcon
                  onClick={() => {
                    setEmailInput(true);
                  }}
                  src={imgEdit}
                />
              )}
            </InputWrapper>
            <SizeBox></SizeBox>
            <InputWrapper>
              <EmailInput
                key={"old_password"}
                type="password"
                datatype="passworedtype"
                placeholder={t("请输入密码")}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              ></EmailInput>
            </InputWrapper>
            <SumbitButtom onClick={loginWithEmail}>{t("确认")}</SumbitButtom>
          </RenderLayoutStyle>
        );
      case LoginStatus.EmailSendCode:

        return (
          <RenderLayoutStyle >
            <LoginTitle>{t("验证码已发送至邮箱")}</LoginTitle>
            <SizeBox></SizeBox>
            <EmailInput
              key={"verify_code"}
              placeholder={t("请输入验证码")}
              onChange={(data) => {
                setCode(data.target.value);
              }}
            ></EmailInput>
            <SizeBox></SizeBox>
            <EmailInput
              key={"set_pwds"}
              type="password"
              autoComplete="new-password"
              inputMode="text"
              datatype="passworedtype"
              placeholder={t("设置密码")}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            ></EmailInput>
            <SumbitButtom onClick={register}>{t("确认")}</SumbitButtom>
          </RenderLayoutStyle>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Flex style={{ width: "100%" }} vertical>
        <HeaderStyle isScrolled={isScrolled}>
          {!isMobile() ? <InviateBar ref={inviateBarRef} /> : null}
          <HeaderBar>
            <HeaderImage onClick={() => navigate("/0")} src={logo} alt="logo" />
            <HeaderLeftDesc>{t("文件高清化与多语言翻译一站解决")}</HeaderLeftDesc>
            <div style={{ width: "130px" }} />

            {!isMobile() ? (
              <div>
                {!logingLoading ? (
                  <Flex align="center" justify="center">

                    <LinkButton
                      onClick={() => {
                        navigate("/get-api");
                      }}
                    >
                      {t("API")}
                    </LinkButton>

                    <LinkButton style={{ display: showInviteCode ? '' : 'none' }}
                      onClick={() => {
                        setOpenAlert(true);
                      }}
                    >{t("邀请码")}</LinkButton>

                    <PopoverBlog>
                      <LinkButton
                        onClick={() => {
                          navigate("/articles");
                        }}
                      >{t("Blog")}</LinkButton>
                    </PopoverBlog>

                    <LinkButton
                      onClick={() => {
                        navigate("/historical");
                      }}
                    >
                      {t("处理记录")}
                    </LinkButton>
                    {/* 
                    {!auth.isLoggedIn ? (
                      <LinkButton
                        onClick={() => {
                          showInviteDialog();
                        }}
                      >
                        {t("填写邀请码")}
                      </LinkButton>
                    ) : null} */}


                    <Search onClick={() => {
                      navigate("/search");
                    }}>
                      <img src={search_icon} alt="" style={{ width: px2vw(20), height: px2vw(20) }} />
                      <span className="search-text">{t("搜索")}</span>
                    </Search>

                    {auth.isLoggedIn ? (
                      <Flex justify="center" align="center">
                        <div style={{ cursor: "pointer" }}>
                          <Popover
                            placement="bottom"
                            content={
                              <AccoundContainer>
                                <AccoundItem onClick={() => {
                                  navigate("/user-account");
                                }}>
                                  {t("查看账户")}
                                </AccoundItem>

                                <AccoundItem onClick={() => {
                                  navigate("/apply-invoice");
                                }}>
                                  {t("开具发票")}
                                </AccoundItem>

                                <AccoundItem onClick={() => setIsShowLoginOutPopup(true)}>
                                  {t("退出登录")}
                                </AccoundItem>

                              </AccoundContainer>
                            }
                            trigger="click"
                            open={isPopLogout}
                            onOpenChange={handleOpenChange}
                          >
                            <img
                              alt=""
                              src={ocrUserIcon}
                              style={{ width: px2vw(50), height: px2vw(50) }}
                            ></img>
                          </Popover>
                        </div>
                      </Flex>
                    ) : (
                      <LoginButton onClick={onLoginClick}>
                        {t("登录")}
                      </LoginButton>
                    )}
                  </Flex>
                ) : (
                  <Spin size="large" />
                )}
              </div>
            ) : (
              <div>
                <DreawerOpen
                  src={h5_open}
                  onClick={() => {
                    setIsMobileOpen(!isMobileOpen);
                  }}
                />
              </div>
            )}
          </HeaderBar>
        </HeaderStyle>
      </Flex>
      <Modal
        centered
        width={540}
        open={auth.isUploadFail}
        onCancel={() => {
          auth.setUploadFail(false)
        }}
        footer={null}
      >
        <UploadFeildModel>
          <UpTips>{t("提示")}</UpTips>
          <UpContent>
            {t("您已享受一次免费文件处理，您还可以通过分享您的链接或邀请码邀请更多新用户注册，获取免费处理100页")}
          </UpContent>
          <UpBox>
            <MoreButton
              onClick={() => {
                auth.setUploadFail(false)
                inviateBarRef.current?.onInviateClick(true)
              }}
            >
              {t("复制邀请链接")}
            </MoreButton>
            <MoreButton
              onClick={() => {
                auth.setUploadFail(false)
                inviateBarRef.current?.onInviateClick(false)
              }}
            >
              {t("下载邀请海报")}
            </MoreButton>
          </UpBox>
        </UploadFeildModel>
      </Modal>
      <Modal
        width={450}
        open={auth.loginModel}
        onCancel={() => {
          modelClose();
        }}
        footer={null}
      >
        {renderLayout()}
      </Modal>
      <InviateCodeModel
        isOpen={isPopInvDialog}
        onClose={(success: boolean, code?: string) => {
          setPopInvDialog(false);
          if (success && auth.isLoggedIn === false) {
            navigate("/register?official_invite_code=" + code);
          }
        }}
      ></InviateCodeModel>
      <Spacer isLogin={auth.isLoggedIn}></Spacer>
      <HeaderDrawer
        onLogin={onLoginClick}
        open={isMobileOpen}
        onClose={() => {
          setIsMobileOpen(false);
        }}
        onInvClick={() => {
          showInviteDialog();
        }}
      />
      <Spin spinning={spinning} fullscreen tip={t("正在登录")} />

      <InviteActivityBar isOpen={openAlert} onClose={() => setOpenAlert(false)} />

      <Modal open={isShowLoginOutPopup} onCancel={() => setIsShowLoginOutPopup(false)} title={t("退出登录")} footer={[
        <Button key="back" onClick={() => setIsShowLoginOutPopup(false)}>{t("取消")}</Button>,
        <Button key="submit" style={{ backgroundColor: '#FDC448' }} onClick={() => {
          setIsShowLoginOutPopup(false);
          onLogoutClick()
        }
        }>{t("确定")}</Button>,
      ]} width={540}>
        {t("确定退出当前账户")}
      </Modal>
    </>
  );
}

const UploadFeildModel = styled.div`
  width: 100%;
  height: auto;
  background: #FFFFFF;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const UpTips = styled.label`
  margin-top: 10px;
  height: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 24px;
  color: #222222;
  line-height: 20px;
  text-align: center;
  font-style: normal;
`

const UpBox = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 27px;
  justify-content: center;
  margin-bottom: 12px;
`

const MoreButton = styled.button`
  width: 210px;
  background: #fdc448;
  height: 60px;
  font-size: 18px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 16px;
  border: none;
  cursor: pointer;
`;

const UpContent = styled.label`
  width: 100%;
  margin-top: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  text-align: left;
  font-style: normal;
`

const RenderLayoutStyle = styled.div`
  height: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: 1200px;
    gap: 42px;
  }
`;

const Spacer = styled.div<{ isLogin: boolean }>`
  @media only screen and (max-width: 768px) {
    height: 471px;
  }

  @media only screen and (min-width: 769px) {
    height: ${(props) =>
    props.isLogin ? "9" : "9"}vw; /* 设置与header相同的高度 邀请bar */
  }
`;

const HeaderBar = styled.div`
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: center;
`;


const HeaderImage = styled.img`
  width: 280px;
  height: 90px;

  @media only screen and (max-width: 768px) {
    width: 988px;
    height: 317px;
  }

  @media only screen and (min-width: 769px) {
    width: 280px;
    height: 90px;
    
  }

  cursor: pointer;
`;

const HeaderLeftDesc = styled.span`
    color: #222;
    align-items: center;
    width: 330px;
    font-size: 18px;
    display: inline-block;
    // background-color: red;
`;


const HeaderStyle = styled.header<{ isScrolled: boolean }>`
  width: 100%;
  position: fixed;
  top: 0;

  box-shadow: ${(props) => props.isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none"};
  z-index: 1000;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    background-color: white;
    min-height: 317px;
  }

  @media only screen and (min-width: 769px) {
    background-color: ${(props) =>
    props.isScrolled ? "white" : "transparent"};
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    min-height: 88px;
  }
`;

const LinkButton = styled.div`
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  padding: 4px 10px;
  text-align: center;
  font-style: normal;
  white-space: nowrap;
  cursor: pointer;
  padding: 5px 50px;

  &:hover {
    font-weight: bold;
  }
`;

const HeaderUl = styled.ul`
  display: flex;
  padding: 4px 10px;
  font-size: 16px;

  li {
    display: flex;
    padding: 4px 10px;
    font-size: 16px;
    border: 1px solid red;
  }
  li::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    visibility: hidden;
    height: 1px;
    color: transparent;
    margin-bottom: -1px;
    overflow: hidden;
  }
  li:hover {
    font-weight: bold;
  }
`;

const LoginButton = styled.button`
  cursor: pointer;
  margin-left: 12px;
  height: 46px;
  min-width: 100px;
  background: #222222;
  border-radius: 8px;

  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  line-height: 29px;
  text-align: center;
  font-style: normal;

  border: 0;
`;

const LoginTitle = styled.label`
  margin-top: 50px;
  margin-bottom: 30px;
  // height: 54px;
  font-weight: bold;
  font-size: 36px;
  color: #222222;
  // line-height: 94px;
  text-align: center;
  // word-break: wrap;

  @media only screen and (max-width: 768px) {
    font-size: 92px;
    margin-bottom: 100px;
  }
`;

const GoogleLoginBtn = styled.button`
  cursor: pointer;
  width: 90%;
  height: 68px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #ebebeb;
  margin-top: 30px;

  span {
    position: relative;
    font-weight: bold;
    font-size: 22px;
    color: #303133;
    line-height: 36px;
    text-align: center;
    font-style: normal;
  }

  img {
    position: absolute;
    top: -4px;
    left: -40px;
    width: 32px;
    height: 32px;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    height: 206px;
    background: #ffffff;
    border-radius: 20px;
    border: 5px solid #ebebeb;

    img {
      top: -24px;
      left: -140px;
      width: 123px;
      height: 123px;
    }

    span {
      font-size: 64px;
      line-height: 92px;
    }
  }
`;

const CountSapn = styled.label`
  margin-right: 30px;
  width: auto;
  min-width: 150px;
  font-weight: 500;
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  text-align: center;
  font-style: normal;
`;

const EmailInput = styled.input`
  width: 90%;
  height: 44px;
  border-radius: 8px;
  border: 2px solid #ebebeb;

  padding: 16px;

  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  font-style: normal;

  ::placeholder {
    color: #b6b6b6;
  }

  :disabled {
    background-color: #f0f0f0; /* 灰色背景 */
    color: #999999; /* 灰色文字 */
    border: 1px solid #cccccc; /* 边框颜色 */
    cursor: not-allowed; /* 禁用时的光标 */
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 200px;
    background: #ffffff;
    border-radius: 20px;
    border: 5px solid #ebebeb;

    font-size: 72px;
    line-height: 92px;
  }
`;

const SumbitButtom = styled.button`
  cursor: pointer;
  margin-top: 50px;
  background: #fdc448;
  width: 50%;
  height: 60px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    width: 90%;
    height: 246px;
    font-size: 22px;
    color: #222222;
    line-height: 97px;
    border-radius: 41px;
    box-shadow: 20px 20px 0px 0px #373737;
  }
`;

const InputWrapper = styled.div`
  width: 90%;
  position: relative;
  display: inline-block;
`;

const InputIcon = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 16px;
  cursor: pointer;
`;

const SizeBox = styled.div`
  height: 30px;
`;

const DreawerOpen = styled.img`
  width: 246px;
  height: 246px;
  cursor: pointer;
`;

const Search = styled.div`
  width: 200px;
  height: 46px;
  cursor: pointer;
  background-color: #F5F6F7;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  margin-right: 30px;
  text-align: left;
  display: flex;
  align-items: center;
  img {
    margin-left: 14px;
  }

  .search-text {
    font-size: 18px;
    color: #999999;
    text-align: left;
    margin-left: 10px;
  }
`;


const LoginBottomIcons = styled.div`
  margin-top: 32px;
  flex-direction: row;
  display: flex;
  gap: 60px;
`

const LoginBottomCirle = styled.div`
  width: 68px;
  height: 68px;
  background: #F6F7FA;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`

const BottomIcons = styled.img`
  width: 32px;
  height: 32px;
`

const AccoundContainer = styled.div`
  width: 160px;
  height: 180px;
`

const AccoundItem = styled.div`
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #222;
  cursor: pointer;
  font-size: 20px;
`