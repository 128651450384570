import { useEffect, useState } from "react";
import { TaskItemData, apiGetPreviewDetail } from "../../../libs/http/api";
import { useRequest } from "ahooks";

const useDetailPolling = (
  pdf_id: number,
  callBack: (data: TaskItemData) => void,
) => {
  const [startPolling, setStartPolling] = useState(false);
  const delay = 5000;
  const detail = useRequest(apiGetPreviewDetail, { manual: true }); //获取单个PDF信息
  const { runAsync: getDetail } = detail;

  let timeoutId: NodeJS.Timeout;

  useEffect(() => {
    
    let mounted = true;
    const fetchData = async () => {
      try {
        try {
          const response = await getDetail(`${pdf_id}`, '');
          if (response.data.code === 200) {
            callBack(response.data.data);
          }
        } catch {}

        if (mounted) {
          timeoutId = setTimeout(fetchData, delay);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (mounted) {
          timeoutId = setTimeout(fetchData, delay);
        }
      }
    };

    if (startPolling) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [startPolling]);

  const start = () => setStartPolling(true);
  const stop = () => {
    clearTimeout(timeoutId);
    setStartPolling(false);
  };
  const runAsyncDetail = async () => {
    try {
      const response = await getDetail(`${pdf_id}`, '');
      if (response.data.code === 200) {
        callBack(response.data.data);
      }
    } catch {}
  };
  return { start, stop, runAsyncDetail };
};

export default useDetailPolling;
