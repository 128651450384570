import HomeFooter from "../Home/Footer/HomeFooter"
import HomeHeader from "../Home/Header/HomeHeader"
import { t } from "../../languages"
import styled from "styled-components"
import { useState, useEffect, useRef, useCallback } from "react";
import { px2vw } from "../../libs/utils";
import { List, Modal, Progress, Button, message, Flex } from "antd"
import icon_pdf from "../../assets/home/12.11/icon_pdf@2x.png"
import history_icon_del from "../../assets/home/12.11/history_icon_del.png"
import history_icon_download from "../../assets/home/12.11/history_icon_download.png"
import { useRequest } from "ahooks"
import { apiDdownloadMarkdown, apiDdownloadPdf, apiDdownloadTrPdf, apiDeletePdf, apiDownloadImage, apiGetPreviewDetail, apiImagePreviewDetail, apiPdfTasks, TaskDetailData, TaskItemData } from "../../libs/http/api"
import { useAuth } from "../../components/AuthContext"
import { useNavigate } from "react-router-dom"
import LeftArea from "../../components/LeftArea";
import { logEvent } from "../../libs/analytics";
import { Timeout } from "ahooks/lib/useRequest/src/types";
import { downloadPDF } from "../../components/downloadPDF";

const Evaluate = () => {
  const deletePdf = useRequest(apiDeletePdf, { manual: true }); // 删除PDF
  const { runAsync: runDelete } = deletePdf;

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const downloadpdf = useRequest(apiDdownloadPdf, { manual: true }); //下载PDF
  const { runAsync: download } = downloadpdf;

  const downloadMarkdown = useRequest(apiDdownloadMarkdown, { manual: true }); //下载Markdown
  const { runAsync: download_markdown } = downloadMarkdown;

  const downloadTrpdf = useRequest(apiDdownloadTrPdf, { manual: true }); //下载PDF
  const { runAsync: downloadTr } = downloadTrpdf;

  const downloadImage = useRequest(apiDownloadImage, { manual: true }); //下载Markdown
  const { runAsync: download_image } = downloadImage;

  //翻译的种类
  const translateCategory = [
    { name: t("全部"), param: '' },
    { name: t("扫描件转文本"), param: 'SCAN_CONVERSION' },
    { name: t("图片转文本"), param: 'IMAGE_TO_TEXT' },
    { name: t("文件翻译"), param: 'DOCUMENT_TRANSLATION' }
  ];

  //文档的种类
  const documentCategory = [
    [],

    [
      { name: t("全部"), param: '' },
      { name: t("书籍"), param: 'BOOK' },
      { name: t("合同"), param: 'CONTRACT' },
      { name: t("论文"), param: 'THESIS' },
      { name: t("其他"), param: 'OTHER' }
    ],

    [
      { name: t("全部"), param: '' },
      { name: t("图文结合"), param: 'PICTEXT' },
      { name: t("纯文本"), param: 'TEXT' }
    ],

    [
      { name: t("全部"), param: '' },
      { name: t("书籍"), param: 'BOOK' },
      { name: t("单页"), param: 'PAPER' }
    ]
  ];

  const navigate = useNavigate();
  const [translateCategoryIndex, setTranslateCategoryIndex] = useState(0);
  const [documentCategoryIndex, setDocumentCategoryIndex] = useState(0);
  const auth = useAuth();
  let [taskList, setTaskList] = useState(Array<TaskDetailData>);
  const [loading, setLoading] = useState(false);
  const [selectedPdfItem, setSelectedPdfItem] = useState<TaskItemData | null>(null);//选中删除的index


  const getPDFListData = async (isShowLoading: boolean) => {
    const transParam = translateCategoryIndex === 0 ? "" : translateCategory[translateCategoryIndex].param;
    const docParam = translateCategoryIndex === 0 ? "" : documentCategory[translateCategoryIndex][documentCategoryIndex].param;
    if (isShowLoading) setLoading(true);
    var resp = await apiPdfTasks(transParam, docParam);
    console.log(resp, '文件列表');

    if (resp.data.code === 200) {
      resp.data.data.reverse();
      setTaskList([...resp.data.data]);
      if (!resp.data.data.some(task => task.status === "PROCESSING")) {
        tasks.cancel()
      }
    }
    if (isShowLoading) setLoading(false);
    return resp.data.data
  };

  const tasks = useRequest(getPDFListData, { manual: true, pollingInterval: 5000, pollingWhenHidden: false, }); // 获取制作任务合集

  useEffect(() => {
    if (auth.isLoggedIn || auth.isLocalLogin) {
      getPDFListData(true).then((data) => {
        if (data.some(task => task.status === "PROCESSING")) {
          tasks.runAsync(false); // 只在有未完成任务时请求数据
        }
      })
    }
  }, [auth.isLoggedIn, auth.isLocalLogin, translateCategoryIndex, documentCategoryIndex])


  //下载ocrPDF
  const dowloadOcrPdf = (data: TaskDetailData) => {
    logEvent("file_list", "download_click", "download");
    message.info(t("正在下载中..."));
    download(data.pdf_id).then((resp) => {
      downloadPDF(resp.data.data.compiled_pdf, data.pdf_name)
    }).catch((err) => { });
  }

  //下载翻译PDF
  const dowloadTrPdf = (data: TaskDetailData) => {
    logEvent("file_list", "download_click", "download");
    message.info(t("正在下载中..."));
    downloadTr(data.pdf_id).then((resp) => {
      downloadPDF(resp.data.data.compiled_pdf, data.pdf_name)
    }).catch((err) => {

    });
  }

  //下载markdownPDF
  const downloadMarkdownTask = (data: TaskDetailData) => {
    download_markdown(data.pdf_id).then((resp) => {
      if (resp.data.code === 200) {
        message.success(t("下载成功"));
        downloadPDF(resp.data.data.compiled_pdf, data.pdf_name)
      } else {
        message.error(t("下载失败"));
      }
    }).catch((err) => { });
  }

  //下载图片
  const downloadImageTask = (data: TaskDetailData) => {
    download_image(data.pdf_id).then((resp) => {
      if (resp.data.code === 200) {
        message.success(t("下载成功"));
        downloadPDF(resp.data.data.compiled_pdf, data.pdf_name)
      } else {
        message.error(t("下载失败"));
      }
    }).catch((err) => { });
  }

  function getStatus(status: string) {
    switch (status) {
      case 'PENDING':
        return t('待处理');
      case 'PROCESSING':
        return t('处理中');
      case 'COMPLETED':
        return t('处理完成');
      case 'FAILED':
        return t('处理失败');
      default:
        break;
    }
  }

  return <>
    <HomeHeader />
    {/* 左边操作区域 */}
    <Container>
      <EvaluateTitle>{t("文件列表")}</EvaluateTitle>

      <Area>

        <LeftArea />

        <RightArea>
          {/* 右边内容区域 */}
          <TypeListOne>
            {
              translateCategory.map((item, index) => {
                return <TypeListOneItem onClick={() => {
                  if (index === translateCategoryIndex) return;
                  setTranslateCategoryIndex(index);
                  setDocumentCategoryIndex(0);
                }}
                  isSelected={translateCategoryIndex === index} key={index}>{item.name}</TypeListOneItem>
              })
            }
          </TypeListOne>

          <TypeListTwo>
            {
              documentCategory[translateCategoryIndex].map((item, index) => {
                return <TypeListTwoItem onClick={() => {
                  if (index === documentCategoryIndex) return;
                  setDocumentCategoryIndex(index);
                }}
                  isSelected={documentCategoryIndex === index} key={index}>{item.name}</TypeListTwoItem>
              })
            }
          </TypeListTwo>

          <TableList>
            <TableListCategaryContainer>
              <p className="tableListCategaryName">文件名</p>
              <p className="tableListCategaryStatus">文件状态</p>
              <p className="tableListCategaryDate">上传时间</p>
              <p className="tableListCategaryOperation">操作</p>
            </TableListCategaryContainer>

            <List
              loading={loading}
              className="table-list"
              split={false}
              pagination={{
                onChange(page, pageSize) {

                }, position: "bottom", pageSize: 10, align: "center", showSizeChanger: false,
                itemRender(page, type, element) {
                  return element;
                },
              }}
              dataSource={taskList}
              renderItem={(item, index) => (
                <List.Item style={{ padding: '0' }}>
                  <ListItemContent>
                    <img
                      className="pdf-icon"
                      style={{ width: px2vw(32) }}
                      alt="pdf" src={icon_pdf} />

                    <p className="pdf-name">
                      {item.pdf_name}
                    </p>

                    <p className='status'>
                      <span className={`${item.status} `}>{getStatus(item.status)}</span>
                    </p>

                    <p className="date">
                      {item.upload_time}
                    </p>

                    <div className="progress" style={{ opacity: item.status === 'PROCESSING' ? 1 : 0 }}>
                      <Progress style={{ display: item.translate_type === 'IMAGE_TO_TEXT' ? 'none' : '' }} percent={item.progress_value == null ? 0 : item.progress_value} type="line" showInfo={false} strokeWidth={4} strokeColor={"#FDC448"} trailColor="#E0E0E0" />
                    </div>

                    {
                      item.status === 'PROCESSING' ? <span className="PROCESSING-progress">{item.translate_type === 'IMAGE_TO_TEXT' ? '' : `${item.progress_value}%`}</span> : <img className="download" style={{ width: px2vw(36), opacity: item.status === 'COMPLETED' ? 1 : 0.2, cursor: item.status === 'COMPLETED' ? 'pointer' : 'not-allowed' }} src={history_icon_download} alt="" onClick={() => {
                        if (item.status === 'COMPLETED') {
                          if (!item.is_downloadable) {
                            return message.info(t("正在制作中..."));
                          }

                          if (item.translate_type === 'DOCUMENT_TRANSLATION') {
                            item.output_type === 'pdf' ? dowloadTrPdf(item) : downloadMarkdownTask(item);
                          } else if (item.translate_type === 'IMAGE_TO_TEXT') {
                            downloadImageTask(item);
                          } else if (item.translate_type === 'SCAN_CONVERSION') {
                            item.output_type === 'pdf' ? dowloadOcrPdf(item) : downloadMarkdownTask(item);
                          }
                        }
                      }
                      } />
                    }

                    <span className="see-detail" onClick={() => {
                      navigate(`/record-detail/${item.pdf_id}/${item.translate_type}`)
                    }}>{t("查看详情")}</span>
                    <img className="delete" style={{ width: px2vw(36) }} src={history_icon_del} alt="" onClick={() => {
                      setSelectedPdfItem(item);
                      setDeleteModalVisible(true);
                    }} />
                  </ListItemContent >
                </List.Item >
              )}
            />
          </TableList >
        </RightArea >
      </Area >

    </Container >

    <HomeFooter />

    <Modal title={t("是否确认删除该记录?")} onCancel={() => setDeleteModalVisible(false)} open={deleteModalVisible} footer={[
      <Button key="back" onClick={() => {
        setDeleteModalVisible(false);
      }}>取消</Button>,
      <Button key="submit" style={{ backgroundColor: '#FDC448' }} onClick={() => {
        setDeleteModalVisible(false);
        logEvent("file_list", "delete_click", "delete");
        const type = selectedPdfItem?.translate_type === 'IMAGE_TO_TEXT' ? 'image' : 'pdf';
        runDelete(selectedPdfItem?.pdf_id ?? 0, type)
          .then((resp) => {
            setTaskList(taskList.filter((item) => item.pdf_id !== selectedPdfItem?.pdf_id));
            message.success(t("删除成功"));
          })
          .catch((err) => { });
      }}>确定</Button>,
    ]} >
      {t("一经删除，无法恢复，请谨慎操作！")}
    </Modal>

  </>
}

export default Evaluate

const Container = styled.div`
    margin: 30px 0 60px 0;
    width: 100%;
    background-color: #fff;

`

const Area = styled.div`
    display: flex;
    width: 100%;
`

const EvaluateTitle = styled.p`
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #222;
    line-height: 50px;
`

const RightArea = styled.div`
    display: inline-block;
    flex: 1;
    height: 100%;
    background-color: #fff;
    margin: 0 40px;
    text-align: left;
`

const TypeListOne = styled.div`
    flex: 1;
    height: 100%;
    background-color: #fff;
    font-size: 18px;
`

const TypeListOneItem = styled.div<{ isSelected: boolean }>`
    display: inline-block;
    font-size: 18px;
    font-weight: ${(props) => props.isSelected ? '600' : '400'};
    color: ${(props) => props.isSelected ? '#222' : '#999'};
    cursor: pointer;
    margin-right: 30px;
    height: 35px;
    line-height: 35px;
    &:hover {
      color: #222;
    }
`

const TypeListTwo = styled.div`
    flex: 1;
    height: 100%;
    background-color: #fff;
    font-size: 18px;
    margin-top: 30px;
`

const TypeListTwoItem = styled.div<{ isSelected: boolean }>`
    display: inline-block;
    font-size: 18px;
    font-weight: ${(props) => props.isSelected ? '600' : '400'};
    color: ${(props) => props.isSelected ? '#222' : '#999'};
    cursor: pointer;
    margin-right: 30px;
    height: 35px;
    line-height: 35px;

    &:hover {
      color: #222;
    }
`

const TableList = styled.div`
   
`

const TableListCategaryContainer = styled.div`
   height: 40px;
   display: flex;
   font-weight: 400;
    font-size: 16px;
    color: #999999;
    line-height: 40px;
    text-align: left;
    font-style: normal;
  
  .tableListCategaryName {
    margin-left: 40px;
    width: 525px;
    height: 40px;
  }

  .tableListCategaryStatus {
    margin-left: 35px;
    width: 80px;
    height: 40px;
    text-align: center;
  }

  .tableListCategaryDate {
    margin-left: 40px;
    width: 250px;
    height: 40px;
    text-align: center;
  }

  .tableListCategaryOperation {
    margin-left: 340px;
    height: 40px;
    width: 100px;
    text-align: center;
  }
`


const ListItemContent = styled.div`
  border: 1px solid #EBEBEB;
  height: 100px;
  background: #F5F6F7;
  border-radius: 24px;
  width: 1500px;
  display: flex;
  align-items: center;
  margin-top: 20px;

  .pdf-icon {
    margin-left: 40px;
  }
  
  .pdf-name {
    font-size: 20px;
    color: #222222;
    width: 350px;
    margin-left: 10px;
    white-space: normal; /* 允许文本自动换行 */
    overflow-wrap: break-word; /* 同上 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    display: -webkit-box; /* 设置为弹性盒子 */
    -webkit-line-clamp: 2; /* 设置显示的行数 */ 
    -webkit-box-orient: vertical; /* 设置弹性盒子的方向为垂直 */
    overflow: hidden; /* 超出部分隐藏 */
  }
  
  .status {
    font-size: 18px;
    margin-left: 160px;
    text-align: center;
    width: 100px;
    justify-content: center;
    align-items: center;

    span {
      border-radius: 6px;
      padding: 5px 8px;
    }
  }

  .PENDING {
      color: #4E89F9;
      border: 2px solid #4E89F9;
  }

  .PROCESSING {
      color: #F9A24E;
      border: 2px solid #F9A24E;
  }

  .COMPLETED {
      color: #02BB00;
      border: 2px solid #02BB00;
  }

  .FAILED {
      color: red;
      border: 2px solid red;
  }

  .PROCESSING-progress {
    font-size: 18px;
    color: #222222;
    width: 40px;
  }

  .date {
    font-size: 20px;
    color: #222222;
    margin-left: 60px;
    white-space: nowrap;

  }

  .progress {
    width: 200px;
    margin-left: 50px;
    margin-right: 12px;
  }

  .download {
    cursor: pointer;
  }

  .see-detail {
    font-size: 20px;
    color: #222222;
    margin-left: 40px;
    cursor: pointer;
    white-space: nowrap;
  }

  .delete {
      margin-left: 40px;
      cursor: pointer;
  }
}
`

const Title = styled.label`
  font-size: 22px;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
`;


const DownloadButton = styled.button`
  cursor: pointer;
  background: #fdc448;
  width: 240px;
  height: 60px;
  font-size: 20px;
  border-radius: 8px;
  border: none;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    border-radius: 20px;
    background: #fdc448;
    box-shadow: 15px 15 0px 0px #373737;
    border-radius: 41px;

    font-weight: bold;
    font-size: 22px;
    color: #222222;
    line-height: 97px;
  }
`;

const Fotmat = styled.div`
    display: flex;
    margin: 40px;
    padding: 0;
`


const PdfFotmat = styled.div`
    font-weight: 400;
    font-size: 18px;
    color: #222;
    display: flex;
    margin: 0;
    padding: 0;
    cursor: pointer;

    p{
        margin: 0;
        padding: 0;
        margin-left: 8px;
    }
`

const MarkdownFotmat = styled.div`
    font-weight: 400;
    font-size: 18px;
    color: #222;
    display: flex;
    margin-left: 40px;
    cursor: pointer;

    p{
        margin: 0;
        padding: 0;
        margin-left: 8px;
    }
`