import Layout from "antd/es/layout/layout";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import HomeFooter from "../Home/Footer/HomeFooter";
import HomeHeader from "../Home/Header/HomeHeader";
import { apiArticleList } from "../../libs/http/api";
import { useRequest } from "ahooks";
import styled from "styled-components";
import { Flex, Pagination } from "antd";
import { px2vw, isMobile } from "../../libs/utils";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useVoerkaI18n } from "@voerkai18n/react";
import { t } from "../../languages";
import withMeta from "../../components/withMeta";

export default function AricleList() {
  const { activeLanguage } = useVoerkaI18n();
  const navigate = useNavigate();
  const articleList = useRequest(apiArticleList, { manual: false }); // 获取文章列表
  const { runAsync: articleGet } = articleList;
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = isMobile() ? 10 : 9; // 每页文章数
  // 计算当前页的文章
  const currentArticles = articleList?.data?.data.data.slice(
    (currentPage - 1) * articlesPerPage,
    currentPage * articlesPerPage
  );

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    articleGet(activeLanguage ?? "en");
  }, [activeLanguage]);

  return (
    <>
      {withMeta({
        title: t("分享更多Pdf to Pdf相关内容"),
        description: t("分享更多Pdf to Pdf相关内容、ocr文本提取技术、文件瘦身"),
        keywords:
          "pdf to pdf method, pdf to pdf steps, how to extract pdf scan content, extract pdf scan text，compress pdf document，compress pdf to pdf",
      })}
      <Layout style={{ background: "#fff", alignItems: "center" }}>
        <HomeHeader />
        <WhyMiddleText>{t("我们想说")}</WhyMiddleText>
        <Flex
          gap={16}
          align="center"
          justify="center"
          wrap="wrap"
          style={{ marginTop: px2vw(58) }}
        >
          {/* {articleList?.data?.data.data?.map((item) => (
            <SayItemStyle
              onClick={() => {
                navigate(`/article/${item.index}/${item.keyword_tag}`);
              }}
            >
              <SayImage src={item.image_path} alt="" />
              <span style={{}}>{item.title}</span>
            </SayItemStyle>
          ))} */}
          {currentArticles?.map((item) => (
            <SayItemStyle
              onClick={() => {
                navigate(`/article/${item.index}/${item.keyword_tag}`);
              }}
            >
              <SayImage src={item.image_path} alt="" />
              <span style={{}}>{item.title}</span>
            </SayItemStyle>
          ))}
        </Flex>
        <StyledPagination
          current={currentPage}
          pageSize={articlesPerPage}
          total={articleList?.data?.data.data.length}
          onChange={onPageChange}
          showSizeChanger={false}
        />
        <HomeFooter />
      </Layout>
    </>
  );
}

const SayItemStyle = styled.div`
  width: 474px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 51px;
  margin-bottom: 58px;
  cursor: pointer;

  span {
    height: 3em;
    margin-top: 24px;
    font-weight: 400;
    font-size: 28px;
    color: #222222;
    line-height: 41px;
    text-align: left;
    font-style: normal;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* 定义文本的行数 */
    -webkit-box-orient: vertical; /* 设置布局方向为垂直 */
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 超出文本显示省略号 */
    white-space: normal; /* 正常换行 */
    max-height: 3em; /* 限制最大高度，根据行高调整 */
    line-height: 1.5em;

    @media only screen and (max-width: 768px) {
      font-size: 77px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 819px;
  }
`;

const SayImage = styled.img`
  width: 474px;
  height: 304px;
  object-fit: cover;

  border-radius: 16px;

  @media only screen and (max-width: 768px) {
    width: 819px;
    height: 522px;
    border-radius: 48px;
  }
`;

const WhyMiddleText = styled.text`
  font-family: Montserrat Bold;
  font-weight: bold;
  font-size: 48px;
  color: #222222;
  line-height: 63px;
  text-align: right;
  font-style: normal;
  margin-top: 38px;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    font-size: 123px;
  }
`;

const StyledPagination = styled(Pagination)`
  margin-bottom: 80px;
`;
