import HomeFooter from "../Home/Footer/HomeFooter"
import HomeHeader from "../Home/Header/HomeHeader"
import { t } from "../../languages"
import styled from "styled-components"
import { useState } from "react";
import { px2vw } from "../../libs/utils";
import { ConfigProvider, Flex, Input, message, Select, Slider } from "antd"
import evaluate_happy from "../../assets/home/12.11/evaluate_happy.png";
import evaluate_sad from "../../assets/home/12.11/evaluate_sad.png";
import TextArea from "antd/es/input/TextArea"
import { feedback } from "../../libs/http/api"
import { useRequest } from "ahooks"
import LeftArea from "../../components/LeftArea"
import { useVoerkaI18n } from "@voerkai18n/react";

const Evaluate = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const userFeedback = useRequest(feedback, { manual: true })
    const [evaluateLevel, setEvaluateLevel] = useState(t('超爱'));
    const [theme, setTheme] = useState('建议');
    const [feedbackText, setFeedbackText] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [recommendIndex, setRecommendIndex] = useState(10);
    const { activeLanguage } = useVoerkaI18n();

    return <>
        <Flex vertical>
            <HomeHeader />
            {/* 左边操作区域 */}
            <Container>
                <EvaluateTitle>{t("请您评价一下我们的网站")}</EvaluateTitle>

                <Area>
                    <LeftArea />

                    <RightArea>
                        {/* 右边内容区域 */}
                        <RightAreaEvaluate className="right-area-evaluate">
                            <div>
                                <img alt="apple" src={evaluate_sad} style={{ width: px2vw(40), height: px2vw(40) }} />
                                <ConfigProvider theme={{
                                    components: {
                                        Slider: {
                                            controlSize: 20,
                                            handleColor: '#FFCB54',
                                            trackBg: '#FFCB54',
                                            handleActiveColor: '#FFCB54',
                                            trackHoverBg: '#FFCB54',
                                            railBg: '#EEF2F6',
                                            railHoverBg: '#EEF2F6',
                                            railSize: 4
                                        },
                                    },
                                }}>
                                    <Slider className="slider" min={0} max={170} defaultValue={170} disabled={false} onChange={(value) => {
                                        if (value < 25) {
                                            setEvaluateLevel(t('不喜欢'));
                                        } else if (value < 65) {
                                            setEvaluateLevel(t('无感'));
                                        } else if (value < 105) {
                                            setEvaluateLevel(t('还不错'));
                                        } else if (value < 145) {
                                            setEvaluateLevel(t('喜欢'));
                                        } else {
                                            setEvaluateLevel(t('超爱'));
                                        }

                                    }} tooltip={{ open: false }} />
                                </ConfigProvider>
                                <img alt="apple" src={evaluate_happy} style={{ width: px2vw(40), height: px2vw(40) }} />

                            </div>

                            <p className="right-area-evaluate-result">{evaluateLevel}</p>
                        </RightAreaEvaluate>

                        {/* 请选择一个主题 */}
                        <RightAreaMainSubject>

                            {t("请选择一个主题")}
                            <div>

                                <ConfigProvider theme={{
                                    components: {
                                        Select: {
                                            selectorBg: "#F5F6F7",
                                            fontSize: 12,
                                            optionActiveBg: "#F5F6F7",
                                        }
                                    }
                                }}>
                                    <Select
                                        defaultValue={t('建议')}
                                        className="right-area-main-subject-select"
                                        onChange={(value) => {
                                            setTheme(value)
                                        }}
                                        options={[
                                            { value: '建议', label: t('建议') },
                                            { value: '表扬', label: t('表扬') },
                                            { value: 'bug', label: t('bug') },
                                            { value: '疑问', label: t('疑问') },
                                        ]}
                                    />
                                </ConfigProvider>
                            </div>
                        </RightAreaMainSubject>

                        <RightAreaAdvice>
                            <span className="right-area-advice-title">{t("您的建议")}</span>
                            <span style={{ color: '#FB3F69' }}>*</span>

                            <TextArea
                                className="right-area-advice-input"
                                onChange={(e) => {
                                    if (e.target.value && contactInfo) {
                                        setIsDisabled(false)
                                    } else {
                                        setIsDisabled(true)
                                    }
                                    setFeedbackText(e.target.value)

                                }}
                                placeholder={t("请输入")}
                                autoSize={{ minRows: 5, maxRows: 10 }}
                                value={feedbackText}
                            />
                        </RightAreaAdvice>

                        <RightAreaEmail className="right-area-email">
                            <span className="right-area-email-title">{t("邮件/联系方式")}</span>
                            <span style={{ color: '#FB3F69' }}>*</span>

                            <Input
                                className="right-area-email-input"
                                onChange={(e) => {
                                    if (feedbackText && e.target.value) {
                                        setIsDisabled(false)
                                    } else {
                                        setIsDisabled(true)
                                    }
                                    setContactInfo(e.target.value)

                                }}
                                placeholder={t("请输入")}
                                value={contactInfo}
                            />
                        </RightAreaEmail>

                        <RightAreaRecommend>
                            <span className="right-area-recommend-title">{t("您向您的朋友和同事推荐我们的可能性有多大")}</span>

                            <div className="right-area-recommend-content">
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                                        return <span className={recommendIndex === item ? "active" : ""} key={item} onClick={() => {
                                            setRecommendIndex(item)
                                        }}>{item}</span>
                                    })
                                }
                            </div>

                            <div className="right-area-recommend-possiblity">
                                <span>{t("不太可能")}</span>
                                <span>{t("非常可能")}</span>
                            </div>
                        </RightAreaRecommend>

                        <UploadBtn className="disabled" isDisabled={false} onClick={async () => {
                            if (!feedbackText || !contactInfo) return;
                            const resp = await userFeedback.runAsync(theme, feedbackText, contactInfo, `${recommendIndex}`, `${recommendIndex}`)
                            console.log(resp.data);

                            if (resp.data.code === 200) {
                                setFeedbackText('')
                                setContactInfo('')
                                setIsDisabled(true)
                                message.success(t("提交成功"))
                            } else {
                                message.error(t("提交失败"))
                            }
                        }}>{t("提交")}</UploadBtn>
                    </RightArea>
                </Area>

            </Container>

            <HomeFooter />
        </Flex>
    </>
}

export default Evaluate

const Container = styled.div`
    margin-top: 30px;
    width: 100%;
    background-color: #fff;
`

const Area = styled.div`
    display: flex;
    width: 100%;

`

const RightArea = styled.div`
    display: inline-block;
    flex: 1;
    height: 1100px;
    background-color: #fff;
    float: right;
    margin-left: 40px;

    .right-area-main-subject {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #222222;
        line-height: 25px;
        text-align: left;
        margin-top: 22px;
    }

    .right-area-main-subject-select {
        width: 100%;
        text-align: left;
        margin-right: 120px;
    }
`

const EvaluateTitle = styled.p`
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #222222;
    line-height: 50px;
`


const RightAreaEvaluate = styled.div`
    width: 420px;
        
    div {
        display: flex;
        align-items: center;

        .slider {
            width: 300px;
        }
    }

    .right-area-evaluate-result {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #222222;
        line-height: 25px;
        margin: 0;
    }
`

const RightAreaMainSubject = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #222222;
    line-height: 25px;
    text-align: left;
    margin-top: 22px;
    margin-right: 120px;

    .right-area-main-subject-select {
        width: 100%;
        text-align: left;
        margin-top: 16px;
    }
`

const RightAreaAdvice = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #222222;
    line-height: 25px;
    text-align: left;
    margin-top: 40px;
    margin-right: 120px;
        
    .right-area-advice-title {
        font-size: 18px;
    }

    .right-area-advice-input {
        margin-top: 16px;
        text-align: left;
        margin-right: 120px;
        font-size: 18px;
        background-color: #F5F6F7;
    }
`

const RightAreaEmail = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #222222;
    line-height: 25px;
    text-align: left;
    margin-top: 40px;
    margin-right: 120px;

    .right-area-email-input {
        margin-top: 16px;
        width: 100%;
        text-align: left;
        margin-right: 120px;
        background-color: #F5F6F7;
        font-size: 18px;
    }
`

const RightAreaRecommend = styled.div`
    width: 800px;
    text-align: left;
    margin-top: 40px;

    .right-area-recommend-title{
        font-size: 18px;
    }
    
    .right-area-recommend-content {
        margin-top: 16px;
        
        span {
            display: inline-block;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background-color: #F5F6F7;
            margin-left: 10px;
            margin-right: 10px;
            border-radius: 8px;
            cursor: pointer;
            font-size: 18px;
        }

        .active {
            background-color: #FFCB54;
        }
    }
    
    .right-area-recommend-possiblity {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
        margin-right: 10px;
        color: #999;
        font-size: 18px;
    }
`

const UploadBtn = styled.div<{ isDisabled: boolean }>`
    width: 240px;
    height: 60px;
    border-radius: 8px;
    background-color: #FDC448;
    line-height: 60px;
    color: #222;
    font-size: 20px;
    margin-top: 40px;
    opacity: ${(props) => props.isDisabled ? 0.6 : 1};
    cursor: ${(props) => props.isDisabled ? 'not-allowed' : 'pointer'};
`