import home_icon_scan from "../assets/home/12.11/home_icon_scan@2x.png"
import home_icon_img from "../assets/home/12.11/home_icon_img@2x.png"
import home_icon_trans from "../assets/home/12.11/home_icon_trans@2x.png"
import { t } from "../languages"
import styled from "styled-components"
import { px2vw } from "../libs/utils"
import { useNavigate } from "react-router-dom"

export default function LeftArea() {
    const left_elements = [
        { icon: home_icon_scan, title: t('扫描件转文本'), desc: t('保留格式') },
        { icon: home_icon_img, title: t('图片转文本'), desc: t('还原排版') },
        { icon: home_icon_trans, title: t('文件翻译'), desc: t('高效精准') }]
    const navigate = useNavigate();

    return <>
        <Area>
            {left_elements.map((item, index) => {
                return <LeftAreItem
                    key={index} onClick={() => {
                        navigate(`/${index}`);
                    }
                    }>
                    <img alt="apple"
                        style={{ width: px2vw(24) }}
                        src={item.icon}
                    />
                    <LeftAreItemContainer>

                        <LeftAreItemTitle>
                            {item.title}
                        </LeftAreItemTitle>

                        <LeftAreItemDesc>
                            {item.desc}
                        </LeftAreItemDesc>

                    </LeftAreItemContainer>

                </LeftAreItem>
            })}

        </Area>
    </>
}


const Area = styled.div`
    display: inline-block;
    flex: 0 0 340px; /* 设置固定宽度 */
`

const LeftAreItem = styled.div`
    height: 100px;
    margin-left: 20px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin: 0px 0px 8px 20px;
    cursor: pointer;
    opacity: 0.4;

    &.selected {
        background-color: #fff;
        border-radius: 16px 0 0 16px;
        opacity: 1.0;
    }

    &:hover {
        opacity: 1.0;
    }
`

const LeftAreItemContainer = styled.span`
    text-align: left;
`

const LeftAreItemTitle = styled.span`
    display: inline-block;
    font-size: 20px;
    margin-left: 12px;
    font-weight: 400;
    white-space: nowrap;

    &.selected {
        font-weight: 600;
    }
`

const LeftAreItemDesc = styled.span`
    display: inline-block;
    margin-left: 8px;
    white-space: nowrap;
    font-size: 16px;
    padding: 3px 8px;
    margin-top: 4px;
    background-color: rgba(251, 63, 105, 0.12);
    color: #FB3F69;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;

    &.selected {
        font-weight: 600;
        background-color: #FB3F69;
        color: #fff;
    }
`
