import { isMobile } from "../../libs/utils"
import { t } from "../../languages";
import { useState } from "react";
import { px2vw, mToPcPx } from "../../libs/utils";
import { Flex, message } from "antd";
import {
    apiApplyBill
} from "../../libs/http/api";
import styled from "styled-components";
import HomeHeader from "../Home/Header/HomeHeader";
import HomeFooter from "../Home/Footer/HomeFooter";
import { useVoerkaI18n } from "@voerkai18n/react";
const ApplyInvoice = () => {
    const [companyName, setCompanyName] = useState('')
    const [taxNumber, setTaxNumber] = useState('')
    const [bankName, setBankName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [billSuccess, setBillSuccess] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const { activeLanguage } = useVoerkaI18n();


    // 正则表达式用于校验邮箱格式
    const validateEmail = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };


    // 适用于通用格式的税号校验，可根据具体需求修改
    const validateTaxNumber = (taxNumber: string) => {
        const taxRegex = /^[A-Za-z0-9]{8,15}$/; // 示例：8-15位字母或数字
        return taxRegex.test(taxNumber);
    };

    const handleApplyBill = () => {

        // 检查输入框是否为空
        if (!companyName || !taxNumber || !bankName || !phoneNumber || !address || !email) {
            return message.warning(t("请填写完整信息"));
        }

        if (!validateEmail(email)) {
            return message.warning(t("请填写正确的邮箱"));
        }

        apiApplyBill({
            company_name: companyName,
            tax_number: taxNumber,
            bank_name: bankName,
            phone_number: phoneNumber,
            address: address,
            email: email
        }).then(res => {
            console.log(res.data, '开发票');
            setCompanyName('')
            setTaxNumber('')
            setBankName('')
            setPhoneNumber('')
            setAddress('')
            setBillSuccess(true)
            setEmail('')
            if (res.data.code === 200) {
                message.success(t('发送成功'))
            } else {
                message.error(t("发送失败"))
            }
        })
    };

    return <>
        <Container>
            <HomeHeader />
            <Main>
                <ContentMain>
                    <ApplyInvoiceTitle>{t("开具发票")}</ApplyInvoiceTitle>
                    <Flex align="center" gap={isMobile() ? 0 : 20} wrap>
                        <div className="bill-box">
                            <div><span style={{ color: 'red' }}>*</span>{t("企业抬头:")}</div>
                            <input
                                type="text"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                placeholder={t("请输入")} />
                        </div>
                        <div className="bill-box">
                            <div><span style={{ color: 'red' }}>*</span>{t("税号:")}</div>
                            <input
                                type="text"
                                value={taxNumber}
                                onChange={(e) => setTaxNumber(e.target.value)}
                                placeholder={t("请输入")} />
                        </div>
                    </Flex>
                    <Flex align="center" gap={isMobile() ? 0 : 20} wrap>
                        <div className="bill-box">
                            <div><span style={{ color: 'red' }}>*</span>{t("开户行:")}</div>
                            <input
                                type="text"
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}
                                placeholder={t("请输入")} />
                        </div>
                        <div className="bill-box">
                            <div><span style={{ color: 'red' }}>*</span>{t("电话:")}</div>
                            <input
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder={t("请输入")} />
                        </div>
                    </Flex>
                    <div className="bill-box">
                        <div><span style={{ color: 'red' }}>*</span>{t("地址:")}</div>
                        <input
                            style={{ width: isMobile() ? '100%' : px2vw(960) }}
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder={t("请输入")} />
                    </div>

                    <div className="bill-box">
                        <div><span style={{ color: 'red' }}>*</span>{t("电子发票发送至邮箱:")}</div>
                        <input
                            style={{ width: isMobile() ? '100%' : px2vw(960) }}
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={t("请填写签收邮箱")} />
                    </div>
                    <button className="upload-btn" onClick={handleApplyBill}>{t("提交信息")}</button>
                </ContentMain>
            </Main>

            <HomeFooter />
        </Container>

    </>
}

export default ApplyInvoice

const Container = styled.div`
  width: 100%;
  height: auto;
  background: #ffffff;
  
`;

const Main = styled.div`
  margin-top: 40px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 95%;
    margin-top: 0px;
    padding: 102px;
  }
`;


const ContentMain = styled.div`
    padding-left: 40px;

    @media only screen and (max-width: 768px) {
        padding-left: 0px;
    }

    .bill-box {
        font-size: 18px !important;
        color: #222;
        text-align: left;

        @media only screen and (max-width: 768px) {
            margin-top: ${mToPcPx(20)};
            width: 100%;
            font-size: ${mToPcPx(14)} !important;
        }
        input {
            margin-top: 10px;
            margin-bottom: 30px;
            width: 450px;
            padding: 16px 0 16px 20px;
            background: #F5F6F7;
            font-size: 18px;
            border-radius: 8px;
            border: 1px solid #EBEBEB;
            outline: none;
            @media only screen and (max-width: 768px) {
                width: 100%;
                padding: ${mToPcPx(14)} ${mToPcPx(8)};
                font-size: ${mToPcPx(14)} !important;
                border-radius: ${mToPcPx(8)};
                border: ${mToPcPx(1)} solid #EBEBEB;
            }
        }     
    }

    .upload-btn {
        margin: 20px 0 40px;
        width: 360px;
        height: 60px;
        color: #222;
        font-size: 20px;
        background: #FDC448;
        border-radius: 8px;
        outline: none;
        border: none;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
            width: 100%;
            height: ${mToPcPx(48)};
            margin-top: ${mToPcPx(10)};
            font-size: ${mToPcPx(16)};
            box-shadow: ${mToPcPx(6)} ${mToPcPx(6)} 0 0 #373737;
            border-radius: ${mToPcPx(8)};
        }
    }
`;

const ApplyInvoiceTitle = styled.div`   
        margin-bottom: 40px;
        font-size: 36px;
        font-weight: 600;
    
    @media only screen and (max-width: 768px) {
        margin-top: ${mToPcPx(40)};
        font-size: ${mToPcPx(16)};
    } 
`
