import styled from "styled-components"
import HomeFooter from "../Home/Footer/HomeFooter"
import HomeHeader from "../Home/Header/HomeHeader"
import search_icon from "../../assets/home/12.11/search_icon.png"
import { px2vw } from "../../libs/utils";
import { t } from "../../languages";
import { Input, ConfigProvider, List } from "antd";
import { useEffect, useState } from "react";
import { searchText } from "../../libs/http/api";
import { useRequest } from "ahooks";
import {
    SearchListParams
} from "../../libs/http/api";
import { useNavigate } from "react-router-dom";
import HighLightText from "./HighLightText";
import { remark } from 'remark';
import strip from 'strip-markdown'


const SearchPage = () => {
    const navigate = useNavigate();

    const search = useRequest(searchText, { manual: true });
    const { runAsync: getSearch } = search;
    const [keywords, setKeywords] = useState('')
    const [searchList, setSearchList] = useState<SearchListParams[]>([]);

    const getSearchResultList = async (value: string) => {
        const res = await getSearch(value);
        console.log(res.data, 'sousuo ');
        setSearchList(res.data.data);
    }

    const [loading, setLoading] = useState(false)
    const [processedContents, setProcessedContents] = useState<{ [key: number]: string }>({});

    useEffect(() => {
        async function processContents() {
            const newContents: { [key: number]: string } = {};
            await Promise.all(searchList.map(async (item, index) => {
                newContents[index] = await stripHtmlExceptText(item.content);
            }));
            setProcessedContents(newContents);
        }

        if (searchList.length > 0) {
            processContents();
        }
    }, [searchList]);  // 当 searchList 变化时，重新处理文本




    async function stripHtmlExceptText(html: string): Promise<string> {
        try {
            const processedText = await remark().use(strip).process(html);
            return String(processedText).trim();
        } catch (error) {
            console.error('解析 Markdown 出错:', error);
            return html;  // 如果解析失败，返回原始文本
        }
    }


    return <>
        <HomeHeader />
        <Container>
            <ContentTextTitle>{t("站内搜索")}</ContentTextTitle>
            <Search>
                <img src={search_icon} alt="" style={{ width: px2vw(20), height: px2vw(20) }} />
                <ConfigProvider
                    theme={{
                        token: {
                            // Seed Token，影响范围大
                            colorPrimary: '#00b96b',
                            borderRadius: 2,
                            // 派生变量，影响范围小
                            colorBgContainer: 'transparent',
                            colorBorder: 'transparent'
                        },
                        components: {
                            Input: {
                                colorBgContainer: 'transparent',
                                colorBorder: 'transparent',
                                activeBg: 'transparent',
                                activeBorderColor: 'transparent',
                                hoverBorderColor: 'transparent',
                                activeShadow: 'transparent',
                            }
                        }
                    }}
                >
                    <Input type="text" className="search-input" placeholder="搜索" allowClear onKeyUp={(event) => {
                        if (event.key === 'Enter' && event.currentTarget.value) {
                            setKeywords(event.currentTarget.value)
                            getSearchResultList(event.currentTarget.value)
                        }
                    }} />
                </ConfigProvider>
            </Search>

            <TableList>
                <List
                    loading={loading}
                    pagination={{
                        onChange(page, pageSize) {

                        }, position: "bottom", pageSize: 10, align: "center", showSizeChanger: false,
                        itemRender(page, type, element) {
                            return element;
                        },
                    }}
                    dataSource={searchList}
                    renderItem={(item, index) => (
                        <List.Item>
                            <ListItemContent onClick={() => navigate(`/article/${item.index}/${item.keyword_tag}`)}>
                                <TableListIcon>
                                    <img src={item.article_image_names?.[0]} alt="" />
                                </TableListIcon>
                                <ListItemTextContent>
                                    <HighLightText isTitle title={item.title} highlight={keywords} />
                                    <HighLightText
                                        isTitle={false}
                                        title={processedContents[index] ?? item.content}
                                        highlight={keywords}
                                    />
                                </ListItemTextContent>
                            </ListItemContent>
                        </List.Item>
                    )}
                />
            </TableList>
        </Container>
        <HomeFooter />
    </>
}


export default SearchPage

const Container = styled.div`
    font-size: 36px;
    color: #222222;
    text-align: center;
    font-style: normal;
    white-space: nowrap;
    margin: 30px 0;
`;

const ContentTextTitle = styled.div`
  font-weight: 600;
  font-size: 36px;
  color: #222222;
  text-align: center;
  white-space: nowrap;
  margin-top: 60px;
`;

const Search = styled.div`
    width: 960px;
    height: 60px;
    background-color: #F5F6F7;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    text-align: left;
    display: flex;
    align-items: center;
    margin: 40px auto;

    img {
        margin-left: 14px;
    }

    .search-input {
        font-size: 18px;
        color: #222;
    }
`;

const TableList = styled.div`
    width: 1360px;
    margin: 0 auto;
`

const ListItemContent = styled.div`
    cursor: pointer;
    padding-top: 30px;
`;

const TableListIcon = styled.div`
    display: inline-block;
    width: 320px;
    height: 207px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    img {
        width: 100%;
        height: 100%;
    }
`

const ListItemTextContent = styled.div`
    width: 1042px;
    display: inline-block;
    padding: 0 0 38px 20px;
    vertical-align: top;
`;

const ListItemDesc = styled.div`
    font-weight: 400;
    font-size: 18px;
    color: #999999;
    margin-top: 12px;
    text-align: left;

    white-space: normal; /* 允许文本自动换行 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    display: -webkit-box; /* 设置为弹性盒子 */
    -webkit-line-clamp: 5; /* 设置显示的行数 */ 
    -webkit-box-orient: vertical; /* 设置弹性盒子的方向为垂直 */
    overflow: hidden; /* 超出部分隐藏 */  
`;