import React from "react";
import styled from "styled-components";
import { t } from "../../../languages";
import { Space } from "antd";
import human1 from "../../../assets/home/human1.png";
import human2 from "../../../assets/home/human2.png";
import human3 from "../../../assets/home/human3.png";
import human4 from "../../../assets/home/human4.png";
import human5 from "../../../assets/home/human5.png";
import com from "../../../assets/home/home_icon_com@2x.png";
import { calculateLayoutWidth, isMobile } from "../../../libs/utils";

const CallBackBox: React.FC = () => {
  return (
    <Main>
      <Container>
        {isMobile() ? <div></div> : null}
        <Card>
          <CardTop>
            <CardAdv src={human1} alt=""></CardAdv>{" "}
            <div style={{ width: "100%" }}></div> <CardCom src={com} alt=""></CardCom>
          </CardTop>
          <CardTitle>{t("王先生")}</CardTitle>
          <CardJob>{t("从事商务工作")}</CardJob>
          <CardContent>{t("非常好，推荐！")}</CardContent>
        </Card>
        <Card>
          <CardTop>
            <CardAdv src={human2} alt=""></CardAdv>{" "}
            <div style={{ width: "100%" }}></div> <CardCom src={com} alt=""></CardCom>
          </CardTop>
          <CardTitle>{t("刘先生")}</CardTitle>
          <CardJob>{t("从事教师工作")}</CardJob>
          <CardContent>
            {t("很不错，有了这个工具再也不用带着纸质资料外出了。")}
          </CardContent>
        </Card>
        <Card>
          <CardTop>
            <CardAdv src={human3} alt=""></CardAdv>{" "}
            <div style={{ width: "100%" }}></div> <CardCom src={com} alt=""></CardCom>
          </CardTop>
          <CardTitle>{t("赵先生")}</CardTitle>
          <CardJob>{t("语言学习者")}</CardJob>
          <CardContent>
            {t("转换完体验太棒了！终于可以翻译成多种语言！阅读毫无障碍！")}
          </CardContent>
        </Card>
        <Card>
          <CardTop>
            <CardAdv src={human5} alt=""></CardAdv>{" "}
            <div style={{ width: "100%" }}></div> <CardCom src={com} alt=""></CardCom>
          </CardTop>
          <CardTitle>{t("陈先生")}</CardTitle>
          <CardJob>{t("个人财务管理者")}</CardJob>
          <CardContent>
            {t("文本提取准确度很高，大多数类型的扫描件转换完都没什么误差。")}
          </CardContent>
        </Card>
        <Card>
          <CardTop>
            <CardAdv src={human4} alt=""></CardAdv>{" "}
            <div style={{ width: "100%" }}></div> <CardCom src={com}></CardCom>
          </CardTop>
          <CardTitle>{t("张女士")}</CardTitle>
          <CardJob>{t("从事LLM相关领域研发")}</CardJob>
          <CardContent>{t("看了别人推荐过来的，很好用。")}</CardContent>
        </Card>
      </Container>
    </Main>
  );
};

export default CallBackBox;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, #FFFAF0, #FFEEC8);
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
`;

const Title = styled.label`
  width: auto;
  height: 77px;
  font-family: Montserrat Bold;
  font-weight: bold;
  font-size: 52px;
  color: #222222;
  line-height: 77px;
  text-align: center;
  font-style: normal;
  margin-top: 95px;

  @media only screen and (max-width: 768px) {
    font-size: 154px;
    line-height: 189px;
    margin-top: 205px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: auto; /* 允许容器内容滚动 */
  gap: 50px;
  margin-top: 80px;

  @media only screen and (max-width: 768px) {
    margin-top: 205px;
    justify-content: flex-start;
    width: 100%;
    gap: 102px;
  }
`;

const Card = styled.div`
  width: 246px;
  height: 380px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 37px;
  padding-right: 37px;

  @media only screen and (max-width: 768px) {
    min-width: 1024px;
    height: 1229px;
    border-radius: 41px;
    padding-left: 92px;
    padding-right: 92px;
  }
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 16px;
  align-items: center;
  margin-top: 37px;

  @media only screen and (max-width: 768px) {
    margin-top: 92px;
  }
`;

const CardAdv = styled.img`
  width: 66px;
  height: 66px;

  @media only screen and (max-width: 768px) {
    width: 174px;
    height: 174px;
  }
`;
const CardCom = styled.img`
  width: 28px;
  height: 28px;

  @media only screen and (max-width: 768px) {
    width: 72px;
    height: 72px;
  }
`;

const CardTitle = styled.label`
  font-family: SourceHanSansCN;
  font-weight: bold;
  font-size: 24px;
  color: #222222;
  line-height: 36px;
  text-align: left;
  font-style: normal;
  margin-top: 13px;

  @media only screen and (max-width: 768px) {
    font-size: 61px;
    margin-top: 41px;
    line-height: 77px;
  }
`;

const CardJob = styled.label`
  margin-top: 8px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  font-size: 18px;
  color: #666666;
  line-height: 27px;
  text-align: left;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 51px;
    margin-top: 20px;
    line-height: 67px;
  }
`;

const CardContent = styled.label`
  font-family: SourceHanSansCN;
  font-weight: 400;
  font-size: 15px;
  max-height: 150px;
  color: #222222;
  line-height: 36px;
  text-align: left;
  margin-top: 16px;
  font-style: normal;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* 显示的行数 */
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    font-size: 72px;
    margin-top: 51px;
    line-height: 92px;
    max-height: 553px;
  }
`;
