import React from 'react';
import styled from 'styled-components';

interface ListItemProps {
    title: string;
    highlight: string; // 要高亮显示的关键字
    isTitle: boolean;
}

const HighLightText: React.FC<ListItemProps> = ({ title, highlight, isTitle }) => {
    // 创建一个正则表达式，用于匹配关键字
    const regex = new RegExp(`(${highlight})`, 'gi');

    // 使用正则表达式拆分关键字，并用自定义元素包裹以实现高亮
    const parts = title.split(regex);

    const renderHighlightedText = () => {
        return parts.map((part, index) =>
            regex.test(part) ? <HighLight key={index}>{part}</HighLight> : part
        );
    };

    return (
        isTitle ? <ListItemTitle>{renderHighlightedText()}</ListItemTitle> : <ListItemDesc>{renderHighlightedText()}</ListItemDesc>
    );
};

export default HighLightText;

const ListItemTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  color: #222222;
  text-align: left;
  
  white-space: normal; /* 允许文本自动换行 */
  overflow-wrap: break-word; /* 同上 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  display: -webkit-box; /* 设置为弹性盒子 */
  -webkit-line-clamp: 2; /* 设置显示的行数 */ 
  -webkit-box-orient: vertical; /* 设置弹性盒子的方向为垂直 */
  overflow: hidden; /* 超出部分隐藏 */
`;

const ListItemDesc = styled.p`
  font-weight: 400;
  font-size: 18px;
  color: #999999;
  margin-top: 12px;
  text-align: left;

  white-space: normal; /* 允许文本自动换行 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  display: -webkit-box; /* 设置为弹性盒子 */
  -webkit-line-clamp: 5; /* 设置显示的行数 */ 
  -webkit-box-orient: vertical; /* 设置弹性盒子的方向为垂直 */
  overflow: hidden; /* 超出部分隐藏 */  
`;

const HighLight = styled.span`
  color: #F9A24E;
`;
