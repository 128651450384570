import HomeFooter from "../Home/Footer/HomeFooter"
import HomeHeader from "../Home/Header/HomeHeader"
import home_icon_scan from "../../assets/home/12.11/home_icon_scan@2x.png"
import home_icon_img from "../../assets/home/12.11/home_icon_img@2x.png"
import home_icon_trans from "../../assets/home/12.11/home_icon_trans@2x.png"
import icon_img from "../../assets/home/12.11/icon_img@2x.png"

import pdf_detail_icon_sel from "../../assets/home/12.11/pdf_detail_icon_sel@2x.png"
import pdf_detail_icon_unsel from "../../assets/home/12.11/pdf_detail_icon_unsel@2x.png"
import { px2vw } from "../../libs/utils";
import { Cascader, CascaderProps, ConfigProvider, message, Flex, Modal, Spin, Space } from "antd"
import { apiConfirmPayment, apiDownloadImage, apiGetPreviewDetail, apiImagePreviewDetail, imageTasks, TaskDetailData } from "../../libs/http/api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import React, { useCallback, useEffect, useRef, useState } from "react";
import pay_sel from "../../assets/historical/pay_icon_sel@2x.png";
import arrow from "../../assets/home/arrow.png"
import add_icon from "../../assets/home/detail_icon_add@2x.png"
import error_img from "../../assets/home/12.11/error-img.png"
import icon_sel from "../../assets/home/icon_sel@2x.png"
import icon_unsel from "../../assets/home/icon_unsel@2x.png"
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {
    TaskItemData,
    apiCreatePaymentIntent,
    apiDdownloadMarkdown,
    apiDdownloadPdf,
    apiDdownloadTrPdf,
    apiDeletePdf,
    apiGetPreview,
    apiGetTranslatePreview,
    apiPaymentForPdf,
    apiUpdatePdfTaskSettings,
} from "../../libs/http/api";
import icon_pdf from "../../assets/historical/pdf@2x.png";
import { useRequest } from "ahooks";
import { pdfjs } from "react-pdf";
import { t } from "../../languages";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { isMobile, mToPcPx } from "../../libs/utils";
import styled from "styled-components";
import CheckoutForm from "../Payment/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { logEvent } from "../../libs/analytics";
import { useAuth } from "../../components/AuthContext";
import useTrDetailPolling from "../Historical/components/TrDetailPolling"
import ImageLoader from "../../components/ImageLoader"
import { Timeout } from "ahooks/lib/useRequest/src/types"
import { downloadPDF } from "../../components/downloadPDF"
import { useVoerkaI18n } from "@voerkai18n/react"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

//正式
const stripePromise = loadStripe(
    "pk_live_51O6o1BDkoOhDlqLb4znpIYocgnDFVqiQuACFD6hiCaxz4gWMJUEe7B2YqNuIOdgFwyMSdHOdMzyMBnHtfurjFZMO000tMUZTtc"
);

// 测试
// const stripePromise = loadStripe(
//     "pk_test_51O6o1BDkoOhDlqLbrAg1wrG6nFiRGzJhJ3mtVMCj4ysCFVSYWqRILaqYbQf6gHc4XBzYVVIOUWnJ059E2VenehtU00u7eSumiE"
// );

// 状态枚举
export enum Status {
    UPLOADING = "UPLOADING", //上传中
    PENDING = "PENDING", // 上传完成准备转化
    PROCESSING = "PROCESSING", // 转化中
    COMPLETED = "COMPLETED", // 已完成
    FAILED = "FAILED", // 处理失败
    READY = "READY", // 准备上传
    LOADING = "LOADING", // 加载
}

// 预览状态枚举
export enum PreviewStatus {
    PROCESSING = "PROCESSING", // 转化中
    COMPLETED = "COMPLETED", // 已完成
    FAILED = "FAILED", // 处理失败
}

function useApi(props: TaskItemData) {
    const [clientSecret, setClientSecret] = useState("");

    const [downloadType, setDownloadType] = useState(1); // 当前选择
    const [ocrPreviewData, setOcrPreivewData] = useState<string[]>([]);
    const [trPreviewData, setTrPreivewData] = useState<string[]>([]);
    const [preivewSelect, setPreviewSelect] = useState<string>("ocr") //预览时选中
    const [preivewData, setPreviewData] = useState<string[]>([]); // 预览图

    const payment_confirm = useRequest(apiConfirmPayment, { manual: true }); // 订单支付确认
    const { runAsync: confirmPayment } = payment_confirm;

    const preview = useRequest(apiGetPreview, { manual: true }); // 获取pdf预览图
    const { runAsync: getPreview } = preview;

    const trPreview = useRequest(apiGetTranslatePreview, { manual: true }); // 获取pdf预览图
    const { runAsync: getTrPreview } = trPreview;

    const paymentInfo = useRequest(apiPaymentForPdf, { manual: true }); // 获取付款信息
    const { runAsync: getPaymentInfo } = paymentInfo;

    const createIntent = useRequest(apiCreatePaymentIntent, { manual: true }); // 创建支付意图
    const { runAsync: create } = createIntent;

    const downloadpdf = useRequest(apiDdownloadPdf, { manual: true }); //下载PDF
    const { runAsync: download } = downloadpdf;

    const downloadMarkdown = useRequest(apiDdownloadMarkdown, { manual: true }); //下载Markdown
    const { runAsync: download_markdown } = downloadMarkdown;


    const downloadImage = useRequest(apiDownloadImage, { manual: true }); //下载Markdown
    const { runAsync: download_image } = downloadImage;


    const downloadTrpdf = useRequest(apiDdownloadTrPdf, { manual: true }); //下载PDF
    const { runAsync: downloadTr } = downloadTrpdf;

    const updateSettings = useRequest(apiUpdatePdfTaskSettings, { manual: true }); //下载PDF
    const { runAsync: apiUpdateSettings } = updateSettings;


    return {
        downloadType,
        setDownloadType,
        preview,
        paymentInfo,
        getPaymentInfo,
        create,
        clientSecret,
        setClientSecret,
        download,
        download_markdown,
        download_image,
        getPreview,
        getTrPreview,
        setOcrPreivewData,
        setTrPreivewData,
        downloadTr,
        ocrPreviewData,
        trPreviewData,
        preivewData,
        setPreviewData,
        preivewSelect,
        setPreviewSelect,
        apiUpdateSettings,
        confirmPayment
    };
}

const RecordDetail = () => {
    const { id, type } = useParams();
    const [detail, setDetail] = useState<TaskDetailData>(); //详情数据
    const [translateCategoryIndex, setTranslateCategoryIndex] = useState(0);
    const getPDFDetailData = useRequest(apiGetPreviewDetail, { manual: true }); //获取单个PDF信息
    const { runAsync: getPDFDetail } = getPDFDetailData;

    const getImageDetailData = useRequest(apiImagePreviewDetail, { manual: true }); //获取单个PDF信息
    const { runAsync: getImageDetail } = getImageDetailData;

    const getImageTask = useRequest(imageTasks, { manual: true }); // 获取制作任务合集
    const auth = useAuth()
    const { activeLanguage } = useVoerkaI18n();
    const [open, setOpen] = useState(false); //model框
    const [isPreview, setPreivew] = useState(false); //预览界面
    const [previewImages, setPreviewImages] = useState<string[]>([]); //预览图片
    const [reloadImg, setReloadImg] = useState(false); //预览图片
    const [spinning, setSpinning] = React.useState<boolean>(false); // 全屏加载框
    const [clientSecret, setClientSecret] = useState("");
    const [trResult, setTrresult] = useState("zh"); // 翻译目标
    const [trDropdowns, setTrDropdowns] = useState<string[]>(['en']); //源文件语言，多选

    const [item_status, setItemStatus] = useState<Status>(Status.LOADING);
    const [trItem_status, setTrItemStatus] = useState<Status>(Status.LOADING);
    const api = useApi(detail!);
    const [isPro, setPro] = useState(false);
    const [selectedFormat, setSelectedFormat] = useState<string>('pdf'); //输出格式
    const [trsSelectedFormat, setTrSelectedFormat] = useState<string>(''); //输出格式

    const [previewOpen, setPreviewOpen] = useState(false); // 预览弹窗
    const [paymentId, setPaymentId] = useState(0);//
    const navigate = useNavigate();

    let [searchParams] = useSearchParams();
    const paymentIntentId = searchParams.get("payment_intent");

    const left_elements = [
        { icon: home_icon_scan, title: t('扫描件转文本'), desc: t('保留格式'), mainTitle: t("高效处理书籍扫描件文本 支持提取、复制、搜索") },
        { icon: home_icon_img, title: t('图片转文本'), desc: t('还原排版'), mainTitle: t("高效处理图文图片文本 支持提取、复制、搜索") },
        { icon: home_icon_trans, title: t('文件翻译'), desc: t('高效精准'), mainTitle: t("快速翻译多页书籍PDF文件内容") }]

    const useTrPolling = useTrDetailPolling(
        detail?.pdf_id ?? 0,
        (data: TaskItemData) => { // 翻译
            setTrItemStatus(data.status as Status);
            setTrSelectedFormat(data.output_type)
            if (data.status !== Status.PROCESSING) {
                useTrPolling.stop()
            }
        }
    )

    useEffect(() => {
        if (paymentIntentId) {
            setSpinning(true);
            const confirmPaymentData = async () => {
                try {
                    const isImage = type === "IMAGE_TO_TEXT";
                    const response = await api.confirmPayment(paymentIntentId, isImage);
                    if (response.data.code === 200) {
                        logEvent(
                            "Home",
                            "pay_success",
                            paymentIntentId,
                            response.data.data.amount
                        );
                        searchParams.set("payment_intent", "");
                        window.history.replaceState(
                            {},
                            document.title,
                            window.location.pathname
                        ); // 清空传递参数

                        const timer = setTimeout(() => {
                            clearTimeout(timer)
                            setSpinning(false);
                            task.runAsync()
                        }, 2000);
                    } else {

                    }
                } catch (error) {
                    console.error("请求出错：", error);
                    setSpinning(false);
                    searchParams.set("payment_intent", "");
                    window.history.replaceState(
                        {},
                        document.title,
                        window.location.pathname
                    ); // 清空传递参数
                }
            };

            confirmPaymentData();
        }
    }, []);

    useEffect(() => {
        setItemStatus(detail?.status as Status);
        setTrItemStatus(detail?.status as Status);
    }, [detail]);

    //Pro点击
    const onProClick = async function (_isPro: boolean) {
        setPro(_isPro);
        clickPayemnt(_isPro);
        logEvent("Home", "pdf_pro_click", "pro");
    };

    //前往支付
    const clickPayemnt = (_isPro: boolean) => {
        const translate = detail?.translate_type === 'DOCUMENT_TRANSLATION'
        logEvent("Home", "payment_info_click", "payment info");
        const type = detail?.translate_type === "IMAGE_TO_TEXT";
        setSpinning(true);

        api.getPaymentInfo(
            type ? detail.summary_id : detail!.pdf_id,
            type ? 'image' : 'pdf',
            activeLanguage ?? "en",
            translate,
            _isPro ? _isPro : undefined,
        ).then((resp) => {
            console.log(resp.data.data, '获取付款信息resp');
            toPayment(
                resp.data.data.payment_gateways,
                resp.data.data.pricing_code,
                type ? detail.summary_id : detail!.pdf_id
            );
        }).catch((error) => {
            setSpinning(false);
            if (error.response) {
                message.error(error.response.data.message);
            }
        });
    };

    //开始支付
    const toPayment = (
        payment_methods: Array<string>,
        pricing_code: string,
        pdf_id: number,
    ) => {
        const pdf_type = detail!.translate_type;
        console.log(pricing_code, payment_methods, pdf_id, pdf_type, '=====');

        api.create({ pricing_code, payment_methods, pdf_id, pdf_type }).then((resp) => {
            console.log(resp.data.data, '创建支付意图');
            setPaymentId(resp.data.data.payment_id);
            setClientSecret(resp.data.data.client_secret);
            setSpinning(false);
            setOpen(true);
        }).catch((error) => {
            console.log(error, 'error');

            setSpinning(false);
            if (error.response) {
                message.error(error.response.data.message);
            }
        });
    };

    //下载ocrPDF
    const dowloadOcrPdf = useCallback(() => {
        logEvent("Home", "download_click", "download");

        message.info(t("正在下载中..."));
        api.download(detail!.pdf_id).then((resp) => {
            downloadPDF(resp.data.data.compiled_pdf, detail!.pdf_name)

        }).catch((err) => { });
    }, [detail, selectedFormat]);

    //下载翻译PDF
    const dowloadTrPdf = useCallback(() => {
        logEvent("Home", "download_click", "download");
        message.info(t("正在下载中..."));
        api.downloadTr(detail!.pdf_id).then((resp) => {
            downloadPDF(resp.data.data.compiled_pdf, detail!.pdf_name)
        }).catch((err) => {

        });
    }, [detail]);

    //下载markdownPDF
    const downloadMarkdownTask = () => {
        api.download_markdown(detail!.pdf_id).then((resp) => {
            if (resp.data.code === 200) {
                message.success(t("下载成功"));
                downloadPDF(resp.data.data.compiled_pdf, detail!.pdf_name)
            } else {
                message.error(t("下载失败"));
            }
        }).catch((err) => { });
    }

    //下载图片
    const downloadImageTask = () => {
        api.download_image(detail!.pdf_id).then((resp) => {
            if (resp.data.code === 200) {
                message.success(t("下载成功"));
                downloadPDF(resp.data.data.compiled_pdf, detail!.pdf_name)
            } else {
                message.error(t("下载失败"));
            }
        }).catch((err) => { });
    }

    // ocr的逻辑
    function ocrConventClick() {
        if (detail?.status === Status.COMPLETED) {
            if (detail!.is_downloadable === false) {
                message.info(t("正在制作中..."));
                return;
            }
            //已完成，下载
            detail.output_type === 'pdf' ? dowloadOcrPdf() : downloadMarkdownTask();
        } else if (detail?.status === Status.PENDING) {
            api.apiUpdateSettings({
                task_id: detail?.pdf_id ?? 0,
                source_languages: trDropdowns,
                target_language: 'en',
                output_formats: [selectedFormat]
            }).then(res => {
                console.log(res, '更新设置');
                // 已上传，支付
                clickPayemnt(false)
            })
        } else if (detail?.status === Status.PROCESSING) {
            //处理中
            message.info(t("正在加急处理中，请稍后哦"))
        }
    }

    //图片的逻辑
    function imageConventClick() {
        if (detail?.status === Status.COMPLETED) {
            if (detail!.is_downloadable === false) {
                message.info(t("正在制作中..."));
                return;
            }
            //已完成，下载
            downloadImageTask();
        } else if (detail!.status === Status.PENDING) {
            api.apiUpdateSettings({
                task_id: detail?.pdf_id ?? 0,
                source_languages: trDropdowns,
                target_language: 'en',
                output_formats: [selectedFormat]
            }).then(res => {
                // 已上传，支付
                clickPayemnt(false)
            })
        } else if (detail?.status === Status.PROCESSING) {
            //处理中
            message.info(t("正在加急处理中，请稍后哦"))
        }
    }

    // 翻译的逻辑
    async function trConventClick() {
        if (detail?.status === Status.COMPLETED) {
            if (detail!.is_downloadable === false) {
                message.info(t("正在制作中..."));
                return;
            }
            detail.output_type === 'pdf' ? dowloadTrPdf() : downloadMarkdownTask();
        } else if (detail!.status === Status.PENDING) {
            const params = {
                task_id: detail?.pdf_id ?? 0,
                source_languages: trDropdowns,
                target_language: trResult,
                output_formats: [selectedFormat]
            };
            api.apiUpdateSettings(params).then(res => {
                // 已上传，支付
                clickPayemnt(false)
            });
        } else if (detail?.status === Status.PROCESSING) {
            //处理中
            message.info(t("正在加急处理中，请稍后哦"))
        }
    }


    const getDetailData = async () => {
        if (type === 'IMAGE_TO_TEXT') {
            const response = await getImageDetail(`${id}`);
            if (response.data.code === 200) {
                if (response.data.data.output_type === 'pdf') { setSelectedFormat('pdf'); } else { setSelectedFormat('markdown'); }
                setDetail(response.data.data)
                if (response.data.data.status !== 'PROCESSING') {
                    task.cancel()
                }
            }
            console.log(response.data.data, '图片');
            return response.data.data
        } else {
            const translateType = type === 'DOCUMENT_TRANSLATION' ? 'translate' : 'ocr'
            const response = await getPDFDetail(`${id}`, `${translateType}`);
            if (response.data.code === 200) {
                if (response.data.data.output_type === 'pdf') { setSelectedFormat('pdf'); } else { setSelectedFormat('markdown'); }
                setDetail(response.data.data)
                if (response.data.data.status !== 'PROCESSING') {
                    task.cancel()
                }
            }
            console.log(response.data.data, 'pdf');
            return response.data.data
        }

    }

    const task = useRequest(getDetailData, { pollingInterval: 5000, pollingWhenHidden: false, manual: true }); // 轮询获取详情

    useEffect(() => {
        if (type === 'IMAGE_TO_TEXT') {
            setTranslateCategoryIndex(1)
        } else if (type === 'DOCUMENT_TRANSLATION') {
            setTranslateCategoryIndex(2)
        } else {
            setTranslateCategoryIndex(0)
        }

        getDetailData().then((data) => {
            if ((data.status === "PROCESSING")) {
                task.runAsync(); // 只在有未完成任务时请求数据
            }
        })
    }, [])

    //获取预览图
    useEffect(() => {
        if (detail?.preview_page_completed) {
            api.getPreview(detail.pdf_id).then((res) => {
                if (res.data.code === 200) {
                    setPreviewImages(res.data.data.preview_png)
                }
            }).catch((error) => {
                message.error(t('预览图请求失败'))
                setPreviewImages([])
            });
        } else {
            //
        }
    }, [detail, reloadImg])

    interface Option {
        value: string;
        label: string;
        children?: Option[];
    }

    const options: Option[] = [
        {
            label: t('英文'),
            value: 'en',
        },
        {
            label: t('中文'),
            value: 'zh',

        },
    ];
    const onSorceLanguageChange: CascaderProps<Option, 'value', true>['onChange'] = (value) => {
        const v = value.join(',').split(',')
        setTrDropdowns(v);
    };

    return <>
        <HomeHeader />

        <Container>

            <Area>
                <LeftArea>
                    {left_elements.map((item, index) => {
                        return <LeftAreItem
                            key={index} onClick={() => {
                                navigate(`/${index}`);
                            }}
                            className={translateCategoryIndex === index ? "selected" : ""}
                        >
                            <img alt="apple"
                                style={{ width: px2vw(24) }}
                                src={item.icon}
                            />
                            <LeftAreItemContainer>

                                <LeftAreItemTitle className={translateCategoryIndex === index ? "selected" : ""}>
                                    {item.title}
                                </LeftAreItemTitle>

                                <LeftAreItemDesc className={translateCategoryIndex === index ? "selected" : ""}>
                                    {item.desc}
                                </LeftAreItemDesc>

                            </LeftAreItemContainer>

                        </LeftAreItem>
                    })}
                </LeftArea>

                <RightAreaContainer>

                    <MainTitle>
                        {t(left_elements[translateCategoryIndex].mainTitle)}
                    </MainTitle>
                    <RightArea>

                        {/* 右边内容区域 */}
                        <PreviewImg onClick={() => {
                            if (detail?.preview_page_completed) {
                                setPreviewOpen(true)
                            }
                        }}>
                            <div style={{ display: detail?.preview_page_completed ? '' : 'none' }}>
                                <ImageLoader src={previewImages[0]} alt="" flag={false} />
                                <p>
                                    {t("结果文件预览")}
                                </p>
                            </div>
                            <img style={{ display: detail?.preview_page_completed ? 'none' : '', width: px2vw(64) }} className="place-holder-icon" src={detail?.translate_type === 'IMAGE_TO_TEXT' ? icon_img : icon_pdf} alt="" />
                        </PreviewImg>

                        <PdfInfo>
                            <PdfTitle>
                                {detail?.pdf_name}
                            </PdfTitle>

                            <PdfCount>
                                {t(`处理消耗页数：${detail?.page_count ?? 1}页`)}
                            </PdfCount>
                            <PdfKey>
                                {t("源文件包含语言：")}
                            </PdfKey>
                            <CascaderContainer>
                                <ConfigProvider theme={
                                    {
                                        components: {
                                            Cascader: {
                                                controlItemWidth: 300,
                                                optionSelectedBg: '#F6F7FA',
                                                colorPrimary: '#FDC448',
                                                colorWhite: '#000',
                                            }
                                        },
                                        token: {
                                            // colorPrimary: '#999',
                                            // colorBgContainer: '#EAEAEA'

                                        }
                                    }
                                }>
                                    <Cascader
                                        style={{ width: '300px' }}
                                        options={options}
                                        onChange={onSorceLanguageChange}
                                        multiple
                                        maxTagCount="responsive"
                                        defaultValue={[['en']]}
                                    />
                                </ConfigProvider>
                            </CascaderContainer>


                            <div style={{ display: detail?.translate_type === 'DOCUMENT_TRANSLATION' ? '' : 'none' }}>
                                <PdfKey>
                                    {t("翻译目标语言：")}
                                </PdfKey>

                                <CascaderContainer>
                                    <ConfigProvider theme={
                                        {
                                            components: {
                                                Cascader: {
                                                    controlItemWidth: 300,
                                                    optionSelectedBg: '#F6F7FA',
                                                    colorPrimary: '#FDC448',
                                                    colorWhite: '#000',
                                                }
                                            },
                                            token: {
                                                // colorPrimary: '#999',
                                                // colorBgContainer: '#EAEAEA'
                                            }
                                        }
                                    }>
                                        <Cascader
                                            style={{ width: '300px' }}
                                            options={options}
                                            onChange={(value) => {
                                                setTrresult(value.join(''))
                                            }}
                                            maxTagCount="responsive"
                                            defaultValue={['zh']}
                                        />
                                    </ConfigProvider>
                                </CascaderContainer>
                            </div>


                            <PdfKey>
                                {t("输出文件格式：")}
                            </PdfKey>

                            <Fotmat>
                                <PdfFotmat onClick={() => {
                                    if (detail?.status === 'PROCESSING' || detail?.status === 'COMPLETED') return;
                                    setSelectedFormat('pdf')
                                }}>
                                    <img src={selectedFormat === 'pdf' ? pdf_detail_icon_sel : pdf_detail_icon_unsel} style={{ width: px2vw(24), height: px2vw(24) }} alt="" />
                                    <p>PDF</p>
                                </PdfFotmat>

                                <MarkdownFotmat onClick={() => {
                                    if (detail?.status === 'PROCESSING' || detail?.status === 'COMPLETED') return;
                                    setSelectedFormat('markdown')
                                }}>
                                    <img src={selectedFormat === 'markdown' ? pdf_detail_icon_sel : pdf_detail_icon_unsel} style={{ width: px2vw(24), height: px2vw(24) }} alt="" />
                                    <p>Markdown</p>
                                </MarkdownFotmat>
                            </Fotmat>

                            <DownloadBtn status={detail?.status === Status.PENDING || detail?.status === Status.COMPLETED ? 1 : 0} onClick={() => {
                                if (detail?.translate_type === 'DOCUMENT_TRANSLATION') {
                                    trConventClick();
                                } else if (detail?.translate_type === 'IMAGE_TO_TEXT') {
                                    imageConventClick();
                                } else if (detail?.translate_type === 'SCAN_CONVERSION') {
                                    ocrConventClick();
                                }
                            }}>
                                {(() => {
                                    switch (detail?.status) {
                                        case 'PENDING':
                                            return t('开始处理');
                                        case 'PROCESSING':
                                            return `${t('处理中')}...${detail?.progress_value}%`;
                                        case 'COMPLETED':
                                            return t('下载');
                                        case 'FAILED':
                                            return t('处理失败');
                                    }
                                })()}
                            </DownloadBtn>

                            <UploadBtn onClick={() => {
                                navigate("/", { replace: true });
                                window.location.reload();
                            }}>
                                {t("上传新文件")}
                            </UploadBtn>
                        </PdfInfo>

                    </RightArea>
                </RightAreaContainer>
            </Area>
        </Container>

        <Modal
            width={600}
            title={t("支付下载")}
            style={{
                fontSize: 28,
            }}
            centered
            open={open}
            footer={null}
            onOk={() => setOpen(false)}
            onCancel={async () => {
                setPro(true);
                setOpen(false);
                logEvent("Home", "payment_cancel", "Cancel");
                if (await auth.hasFreeCount()) {
                    auth.setUploadFail(true)
                }
            }}
        >
            <center>
                <Space size={30}>
                    <ModalTopButton onClick={() => onProClick(false)} isPro={!isPro}>
                        <TBContent>
                            <TopButtonLabel>OCR</TopButtonLabel>
                            <Flex justify="center" align="center" vertical>
                                <TopButtonLabelRight>99%</TopButtonLabelRight>
                                <TopButtonLabelRB>{t("识别正确率")}</TopButtonLabelRB>
                            </Flex>
                        </TBContent>
                        {!isPro && <img className="sel" src={pay_sel} alt="" />}
                    </ModalTopButton>
                    <ModalTopButton onClick={() => onProClick(true)} isPro={isPro}>
                        <TopIcon>{t("推荐")}</TopIcon>
                        <TBContent>
                            <TopButtonLabel>OCR Pro</TopButtonLabel>
                            <Flex justify="center" align="center" vertical>
                                <TopButtonLabelRight>99.5%</TopButtonLabelRight>
                                <TopButtonLabelRB>{t("识别正确率")}</TopButtonLabelRB>
                            </Flex>
                        </TBContent>
                        {isPro && <img className="sel" src={pay_sel} alt="" />}
                    </ModalTopButton>
                </Space>
            </center>
            {clientSecret ? (
                <center>
                    <Elements
                        options={{
                            clientSecret: clientSecret,
                            appearance: { theme: "stripe" },
                        }}
                        stripe={stripePromise}
                    >
                        <CheckoutForm
                            is_translate={detail?.translate_type === 'DOCUMENT_TRANSLATION'}
                            update={() => {
                                setOpen(false);
                                setSpinning(true);
                                const timer = setTimeout(async () => {
                                    setSpinning(false);
                                    clearTimeout(timer)
                                    //查看订单详情
                                    task.runAsync()
                                }, 2000);
                            }}
                            pdf_id={detail?.translate_type === 'IMAGE_TO_TEXT' ? detail.summary_id : detail?.pdf_id ?? 0}
                            isImage={detail?.translate_type === 'IMAGE_TO_TEXT'}
                            data={api.paymentInfo.data?.data.data}
                        />
                    </Elements>
                </center>
            ) : (
                <div></div>
            )}
        </Modal>
        <Spin style={{ zIndex: 9999 }} spinning={spinning} fullscreen />

        <Modal width={612} closable={true} centered open={previewOpen} footer={null} onCancel={() => setPreviewOpen(false)}
        >
            {previewImages.length === 0 ? <ErrorContainer>
                <ErrorImage src={error_img} />
                <LoadError>{t("加载失败")}</LoadError>
                <ReloadBtn onClick={() => {
                    setReloadImg(!reloadImg)
                }}>
                    {t("重新加载")}
                </ReloadBtn>
            </ErrorContainer> : <ImageLoader src={previewImages[0]} alt="" flag={true} />}
        </Modal>
    </>
}

export default RecordDetail


const Container = styled.div`
    margin: 30px 0 60px 0;
    width: 100%;
    background-color: #fff;

`

const Area = styled.div`
    display: flex;
    width: 100%;
`

const RightAreaContainer = styled.div`
    flex: 1;
    width: 1540px;
    height: 694px;
    background-color: #F5F6F7;
    margin: 0 40px 0 0;
    text-align: left;
    border-radius: 16px;
`

const MainTitle = styled.p`
    font-weight: 600;
    font-size: 32px;
    color: #222222;
    margin: 24px 0 0 60px;
`

const RightArea = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    background-color: #F5F6F7;
    text-align: left;
    border-radius: 16px;
`

const PreviewImg = styled.div`
    width: 288px;
    height: 430px;
    background-color: #fff;
    margin: 60px 60px 88px 60px;
    justify-content: center;
    border-radius: 8px;
    position: relative;

    p {
        display: inline-block;
        padding: 9px 20px;
        color: #FF6926;
        border-radius: 4px;
        border: 1px solid #FDC448;
        font-size: 16px;
        background-color: #FEF3DA;
        position: absolute;
        bottom: 0px;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .place-holder-icon {
        margin-left: 50%;
        margin-top: 50%;
        transform: translateX(-50%);
    }
`

const ErrorContainer = styled.div`
    height: 700px;
`

const ErrorImage = styled.img`
    height: 54px;
    width: 54px;
    margin: 0 auto;
    display: block;
    margin-top: 380px;
`

const LoadError = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: #222222;
    text-align: center;
    font-style: normal;
    width: 100px;
    margin: 0 auto;
    margin-top: 20px;
`

const ReloadBtn = styled.div`
    width: 240px;
    height: 60px;
    line-height: 60px;
    background-color: #FDC448;
    font-weight: 400;
    font-size: 20px;
    color: #222222;
    text-align: center;
    font-style: normal;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 40px;
    cursor: pointer;
`

const PdfInfo = styled.div`
    // height: 100px;
    // background-color: red;
`

const PdfTitle = styled.p`
    font-weight: 600;
    font-size: 24px;
    color: #222222;
    margin-top: 46px;
`

const PdfCount = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: #666666;
`

const PdfKey = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: #222;
    margin-top: 30px;
`

const CascaderContainer = styled.div`
    // height: 60px;
    // background-color: red;
`

const Fotmat = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
`


const PdfFotmat = styled.div`
    font-weight: 400;
    font-size: 18px;
    color: #222;
    display: flex;
    margin: 0;
    padding: 0;
    cursor: pointer;

    p{
        margin: 0;
        padding: 0;
        margin-left: 8px;
    }
`

const MarkdownFotmat = styled.div`
    font-weight: 400;
    font-size: 18px;
    color: #222;
    display: flex;
    margin-left: 40px;
    cursor: pointer;

    p{
        margin: 0;
        padding: 0;
        margin-left: 8px;
    }
`

const DownloadBtn = styled.div<{ status: Number }>`
    width: 300px;
    height: 68px;
    background-color: #222;
    opacity: ${({ status }) => (status === 1 ? 1 : 0.6)};
    margin-top: 30px;
    border-radius: 8px;

    font-weight: 600;
    font-size: 20px;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: ${({ status }) => (status === 1 ? 'pointer' : 'no-drop')};
`

const UploadBtn = styled.div`
    font-weight: 400;
    font-size: 20px;
    color: #666666;
    margin-top: 16px;
    width: 300px;
    text-align: center;
    cursor: pointer;
`


const ModalTopButton = styled.button<{ isPro: boolean }>`
  position: relative;
  width: 255px;
  height: 70px;
  background: #ffffff;
  border-radius: 8px;
  border: 3px solid ${({ isPro }) => (isPro ? "#222222" : "#EBEBEB")};
  margin-top: 12px;
  margin-bottom: 12px;

  color: ${({ isPro }) => (isPro ? "#222222" : "#999999")};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  * {
    color: inherit;
  }

  @media only screen and (max-width: 768px) {
    width: 768px;
    height: 205px;
    border-radius: 20px;
  }
  .sel {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 38px;
    height: 38px;
    @media only screen and (max-width: 768px) {
      width: ${mToPcPx(24)};
      height: ${mToPcPx(24)};
    }
  }
`;

const TBContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const TopButtonLabel = styled.label`
  width: auto;
  font-weight: bold;
  font-size: 22px;
  line-height: 64px;
  text-align: center;
  font-style: normal;
  margin-right: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 62px;
    line-height: 205px;
  }
`;

const TopButtonLabelRight = styled.label`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 62px;
    line-height: 92px;
  }
`;

const TopButtonLabelRB = styled.label`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 51px;
    line-height: 92px;
  }
`;

const TopIcon = styled.label`
  position: absolute;
  left: -3px;
  top: -16px;
  padding: 0px 5px 0px;
  height: 32px;
  background: linear-gradient(180deg, #f49f9f 0%, #f53e3e 100%);
  border-radius: 24px 100px 100px 0px;

  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  font-style: normal;

   @media only screen and (max-width: 768px) {
    height: 82px;
    font-size: 46px;
    line-height: 82px;
    padding: 0px 25px 0px;
     top: -36px;
   }
`;

const LeftArea = styled.div`
    display: inline-block;
    flex: 0 0 360px; /* 设置固定宽度 */
`

const LeftAreItem = styled.div`
    height: 100px;
    margin-left: 20px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin: 0px 0px 8px 20px;
    cursor: pointer;
    opacity: 0.4;

    &.selected {
        background-color: #F5F6F7;
        border-radius: 16px 0 0 16px;
        opacity: 1.0;
    }

    &:hover {
        opacity: 1.0;
    }
`

const LeftAreItemContainer = styled.span`
    text-align: left;
`

const LeftAreItemTitle = styled.span`
    display: inline-block;
    font-size: 20px;
    margin-left: 12px;
    font-weight: 400;
    white-space: nowrap;

    &.selected {
        font-weight: 600;
    }
`

const LeftAreItemDesc = styled.span`
    display: inline-block;
    margin-left: 8px;
    white-space: nowrap;
    font-size: 16px;
    padding: 3px 8px;
    margin-top: 4px;
    background-color: rgba(251, 63, 105, 0.12);
    color: #FB3F69;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;

    &.selected {
        font-weight: 600;
        background-color: #FB3F69;
        color: #fff;
    }
`
