// import { URIPrivacyPolicy, URIUserAgreement } from "constants/uri";
import { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from '../page/Home'
import Historical from '../page/Historical'
import Payment from "../page/Payment";
import Doc from "../page/Doc";
import Article from "../page/Article";
import ArticleList from "../page/ArticleList";
import UseScenes from "../page/UseScenes";
import Price from "../page/Price";
import PdfSmaller from "../page/PdfSmaller";
import Faq from "../page/Faq";
import Api from "../page/Api";
import NotFound from "../page/Error/Error";
import Evaluate from "../page/evaluate/Evaluate";
import ResearchInstitution from "../page/research-institution/ResearchInstitution";
import ApplyInvoice from "../page/apply-invoice/ApplyInvoice";
import SearchPage from "../page/search/SearchPage";
import UserAccount from "../page/user-account/UserAccount";
import RecordDetail from "../page/record-detail/RecordDetail";
import GlobalGuard from "./AuthRouter";

const protectedRoutes = [
  {
    path: "/:index",
    element: <Suspense fallback={<div />} children={<Home />} />,
    children: [],
  },
  {
    path: "/historical",
    element: <Historical />,
  },
  {
    path: "/evaluate",
    element: <Evaluate />,
  },
  {
    path: "/payment",
    element: <Payment />
  },
  {
    path: "/doc",
    element: <Doc />
  },
  {
    path: "/article/:index/:id",
    element: <Article />
  },

  {
    path: "/articles",
    element: <ArticleList />
  },
  {
    path: "/users/:id",
    element: <UseScenes />
  },
  {
    path: "/pdf-to-pdf-conversion",
    element: <PdfSmaller />
  },
  {
    path: "/pricing",
    element: <Price />
  },
  {
    path: "/faq",
    element: <Faq />
  },
  {
    path: "/get-api",
    element: <Api />
  },
  {
    path: "/privacy-policy",
    element: <Doc />
  },
  {
    path: "/research-institution",
    element: <ResearchInstitution />
  },
  {
    path: "/apply-invoice",
    element: <ApplyInvoice />
  },
  {
    path: "/search",
    element: <SearchPage />
  },
  {
    path: "/user-account",
    element: <UserAccount />
  },
  {
    path: "/record-detail/:id/:type",
    element: <RecordDetail />
  },
  {
    path: "*",
    element: <Home />
  }
]

// 动态应用 GlobalGuard
const routes = protectedRoutes.map((route) => ({
  ...route,
  element: <GlobalGuard>{route.element}</GlobalGuard>
}));


const router = createBrowserRouter(routes);


export default router