import React, { useEffect, useRef, useState } from "react";
import HomeHeader from "./Header/HomeHeader";
import {
  Carousel,
  Flex,
} from "antd";
import HomeFooter from "./Footer/HomeFooter";
import home_bg from "../../assets/home/home_bg_c@2x.png";

import home_mobile_bg from "../../assets/home/mobile/home_bg@2x (1).png";
import styled from "styled-components";
import { t } from "../../languages";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Player } from "@lottiefiles/react-lottie-player";
import { useUpload } from "../../components/FileUploadContext";
import { logEvent } from "../../libs/analytics";
import CallBackBox from "./components/CallBackBox";

import withMeta from "../../components/withMeta";
import ProcessSection from "./components/ProcessSection";
import banner_img from "../../assets/home/12.11/68439@2x.png"
import banner_en_img from "../../assets/home/12.11/68439_en@2x.png"

import home_img_trait1 from "../../assets/home/12.11/home_img_trait1@2x.png"
import home_img_trait2 from "../../assets/home/12.11/home_img_trait2@2x.png"
import home_img_trait3 from "../../assets/home/12.11/home_img_trait3@2x.png"
import home_img_trait4 from "../../assets/home/12.11/home_img_trait4@2x.png"

import banner_1_zh from "../../assets/home/12.11/banner_1_zh.png"
import banner_2_zh from "../../assets/home/12.11/banner_2_zh.png"
import banner_3_zh from "../../assets/home/12.11/banner_3_zh.png"

import banner_1_en from "../../assets/home/12.11/banner_1_en.png"
import banner_2_en from "../../assets/home/12.11/banner_2_en.png"
import banner_3_en from "../../assets/home/12.11/banner_3_en.png"

import { px2vw } from "../../libs/utils";
import { useParams } from 'react-router-dom';
import { useVoerkaI18n } from "@voerkai18n/react";

const Home = React.memo(() => {
  const { activeLanguage } = useVoerkaI18n();

  const { addFiles } = useUpload();
  const { index } = useParams<{ index: string }>();
  let numericIndex = Number(index);

  // 如果index大于2 或者不是一个数字，将其设置为 0
  if (isNaN(numericIndex) || numericIndex > 2) {
    numericIndex = 0;
  }

  const player = React.createRef<Player>();
  const targetRef = useRef<HTMLDivElement>(null); // 创建一个 ref 来引用目标元素
  const [isScrolledToElement, setIsScrolledToElement] = useState<boolean>(false);
  const goods_elements = [
    { icon: home_img_trait1, title: t('文字识别精准'), desc: t('高精度OCR文字识别技术，高达99.5%的识别精确度让扫描件提取文字后即刻能用，节省90%无效时间。目前可识别中文简体、英文，持续增加中。') },
    { icon: home_img_trait2, title: t('保留文件原排版'), desc: t('完全还原原文件排版，PDF在线格式转换工具让你从繁琐的逐字输入工作中解放，同时因其高精度的转换结果，让你彻底摆脱人工比对原稿逐字校对、排版的苦恼。') },
    { icon: home_img_trait3, title: t('支持多端'), desc: t('收藏网址pdftopdf.ai，无论是手机还是电脑，都可直接使用，无需下载、不占内存，方便快捷！外出再也不用随身带电脑，掏出手机即可在移动端完成文件处理，随时随地解决PDF文件格式转换、文本提取、编辑、压缩、翻译的问题！') },
    { icon: home_img_trait4, title: t('文件压缩'), desc: t('使用PDF to PDF在线工具，可免费试用OCR文本提取功能，经过提取的PDF扫描件将转变为PDF文本文件格式，且转换格式后的文件将被进行高度压缩。不花一分钱同时获得OCR文字识别和PDF文件压缩的结果，存储更节省空间！') }]
  const [isLight, setIsLight] = useState<boolean>(true);

  const bannerList = activeLanguage === 'en' ? [banner_1_en, banner_2_en, banner_3_en] : [banner_1_zh, banner_2_zh, banner_3_zh]

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current && !isScrolledToElement) {
        const { top } = targetRef.current.getBoundingClientRect();
        const isElementInView = top <= 600;
        setIsScrolledToElement(isElementInView);
        if (isElementInView) {
          player.current?.play();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolledToElement, setIsScrolledToElement, player]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (<>
    {withMeta({
      title: t("免费在线OCR—将PDF扫描件转换为文本"),
      description: t(
        "通过ocr技术，将pdf扫描件转换为pdf文本文件，一键实现文件瘦身的同时保留文件原排版。阅读体验更佳，内容复制、搜索无阻碍!"
      ),
      keywords:
        "pdf to pdf conversion，pdf convert to word converter，ocr text recognition，image pdf to pdf converter，free online OCR，pdf smaller，ocr，ocr scanner",
    })}

    <Flex vertical>
      <HomeHeader />

      <Root>
        <ProcessSection typeIndex={`${numericIndex ?? ''}`} isLight={isLight} />
        <Banner>
          <Carousel autoplay>
            {bannerList.map(item => {
              return <img src={item} alt="" />
            })}

          </Carousel>
        </Banner>

        <GoodsContainer>
          {goods_elements.map((item, index) => {
            return <>
              <Goods language={activeLanguage} key={index} onClick={() => {
                window.scrollTo(0, 0)
                setIsLight(!isLight)
              }
              }>
                <img alt="apple"
                  style={{ width: px2vw(358), height: px2vw(280) }}
                  src={item.icon}
                />
                <div>
                  <p className="title">{item.title}</p>
                  <p className="desc">{item.desc}</p>
                  <p className="upload">{t("上传文件")}</p>
                </div>
              </Goods>
            </>
          })
          }
        </GoodsContainer>
        <CallBackBox />
      </Root>
      <HomeFooter />
    </Flex>

  </>)
})
export default Home;
const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  // background-size: cover;
  // background: url(${home_bg}) top center;

  @media only screen and (max-width: 768px) {
    // background: url(${home_mobile_bg}) top center;
  }
`;


const Banner = styled.div`
    width: 100%;
    height: 560px;
`

const GoodsContainer = styled.div`
    width: 100%
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 90px;
    justify-items: center;
    margin-top: 100px;
    padding-bottom: 100px;
`

const Goods = styled.div<{
  language?: string;
}>`
    width: 800px;
    display: flex;
    div{
        text-align: left;
        margin-left: 40px;
        .title{            
          font-size: ${(props) => (props.language === 'en' ? 32 : 36)}px;
          font-weight: 600;
          color: #222;
          margin: 0; 
          height: ${(props) => (props.language === 'en' ? 70 : 50)}px;
        }

        .desc {
          font-size: ${(props) => (props.language === 'en' ? 16 : 20)}px;
          font-weight: 400;
          color: #222;    
          margin: 12px 0 0 0; 
          height: ${(props) => (props.language === 'en' ? 170 : 165)}px;
        }

        .upload {
          background-color: #FDC448;
          width: 240px;
          border-radius: 8px;
          height: 68px;
          text-align: center;
          line-height: 68px;
          font-size: 20px;
          color: #222222;
          font-weight: 600;
          cursor: pointer;

          &:hover {
            opacity: 0.95;
          }
        }
    }
`