// InviateModel.tsx 邀请弹窗
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import bg from "../assets/inv_model_bg.jpg";
import share_image_zh from "../assets/home/share_img_zh.png";
import share_image_en from "../assets/home/share_img_en.png";
import { t } from "../languages";
import html2canvas from "html2canvas";
import { Flex, Modal, QRCode, Spin, message } from "antd";
import { useRequest, useSafeState } from "ahooks";
import { px2vw } from "../libs/utils";
import { apiInvitionVerify } from "../libs/http/api";
import { logEvent } from "../libs/analytics";

interface ModalProps {
  isOpen: boolean;
  onClose: (success: boolean, code?: string) => void;
}

const InviateCodeModel: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [code, setCode] = useState<string>("");
  const [isVerify, setVerify] = useState(false);
  const verify = useRequest(apiInvitionVerify, { manual: true }); // 获取文章列表

  const submitCode = () => {
    logEvent("Inviate", "Submit", "Code");
    if (code.length === 0) {
      message.error(t("请输入邀请码"));
    } else {
      setVerify(true);
      verify
        .runAsync(code)
        .then((resp) => {
          if (resp.data.code === 200) {
            message.success(resp.data.message);
            onClose(true, code);
          }
          setVerify(false);
        })
        .catch((error) => {
          if (error.response) {
            message.error(error.response.data.message);
          }
          setVerify(false);
        });
    }
  };

  return (
    <>
      <Modal
        width={400}
        open={isOpen}
        onCancel={() => {
          onClose(false);
        }}
        footer={null}
      >
        <Flex
          align="center"
          justify="center"
          vertical
          style={{ padding: px2vw(20) }}
        >
          <Flex vertical style={{ width: "100%" }}>
            <Title>{t("填写邀请码")}</Title>
            <Content>{t("你将获得100页PDF PRO处理次数")}</Content>
          </Flex>
          <InputCode
            onChange={(event) => {
              setCode(event.target.value);
            }}
            placeholder={t("请输入邀请码")}
          ></InputCode>
          <InvButton onClick={submitCode} disabled={isVerify}>
            {isVerify ? <Spin spinning={true} /> : <span>{t("提交")}</span>}
          </InvButton>
        </Flex>
      </Modal>
    </>
  );
};

const Title = styled.label`
  font-size: 22px;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

const Content = styled.label`
  font-size: 16px;
  margin-top: 12px;

  @media only screen and (max-width: 768px) {
    font-size: 51px;
    margin-top: 42px;
  }
`;

const InputCode = styled.input`
  margin-top: 30px;
  margin-bottom: 16px;
  width: 100%;
  background: #f5f6f7;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  outline: none;
  font-size: 20px;
  height: 56px;

  line-height: 36px;
  font-weight: 400;

  @media only screen and (max-width: 768px) {
    border-radius: 20px;
    border: 3px solid #ebebeb;
    height: 246px;

    font-size: 72px;
    line-height: 92px;
    margin-top: 50px;
  }
`;

const InvButton = styled.button`
  cursor: pointer;
  margin-top: 24px;
  background: #fdc448;
  width: 100%;
  height: 60px;
  font-size: 20px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    margin-top: 100px;
    border-radius: 20px;
    width: 768px;
    height: 246px;
    background: #fdc448;
    box-shadow: 15px 15 0px 0px #373737;
    border-radius: 41px;

    font-weight: bold;
    font-size: 22px;
    color: #222222;
    line-height: 97px;
  }
`;

export default InviateCodeModel;
