import { t } from "../../../languages"
import styled from "styled-components"
import { useState } from "react";
import { useRequest } from "ahooks"
import { ApiMyinvitation, deleteAccount } from "../../../libs/http/api"
import { Button, message, Modal } from "antd"
import { useAuth } from "../../../components/AuthContext"
import { useNavigate } from "react-router-dom"
import chinese_alert from "../../../assets/home/12.11/中文图@2x.png"
import english_alert from "../../../assets/home/12.11/英文图@2x.png"
import close_icon from "../../../assets/home/12.11/关闭@2x.png"
import chinese_btn_alert from "../../../assets/home/12.11/中文按钮@2x.png"
import english_btn_alert from "../../../assets/home/12.11/英文按钮@2x.png"
import copy from "copy-to-clipboard"
import { CopyInviteLink } from "../../../libs/http/tracker-api";
import { useVoerkaI18n } from "@voerkai18n/react";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InviteActivityBar: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const { activeLanguage } = useVoerkaI18n();
  const apiInvInfo = useRequest(ApiMyinvitation, { manual: true });

  return <>
    <Overlay isOpen={isOpen}>
      <AlertContainer>
        <AlertImg src={activeLanguage === 'en' ? english_alert : chinese_alert} alt="" />
        <AlertBtn src={activeLanguage === 'en' ? english_btn_alert : chinese_btn_alert} alt="" onClick={() => {
          CopyInviteLink();
          apiInvInfo.runAsync().then((res) => {
            console.log(res.data?.data.invite_link ?? "");
            copy(res.data?.data.invite_link ?? "");
            message.success(t("复制成功！"));
            onClose();
          })
        }} />
        <AlertCloseImg src={close_icon} onClick={onClose} />
      </AlertContainer>


    </Overlay>
  </>
}

export default InviteActivityBar


const Overlay = styled.div<{ isOpen: boolean }>`
  flex-direction: column;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); // 灰色背景蒙层
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const AlertContainer = styled.div`
  width: 680px;
  height: 800px;
  position: relative;
`;

const AlertImg = styled.img`
  width: 100%;
  height: 100%;
`;

const AlertCloseImg = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const AlertBtn = styled.img`
  width: 306px;
  height: 83px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  cursor: pointer;
`;