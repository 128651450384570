export const downloadPDF = (http_url: string, file_name: string) => {
    fetch(http_url)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);

            // 创建隐藏的 <a> 元素
            const a = document.createElement('a');
            a.href = url;
            a.download = file_name; // 设置自定义文件名
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();

            // 清理 URL 对象
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
        .catch((error) => {
            console.error('文件下载失败:', error);
        });
};
