export default {
    "1": "支持格式：PDF",
    "2": "我们想说",
    "3": "更多",
    "4": "上传文件",
    "5": "处理记录",
    "6": "上传失败！",
    "7": "下载文件共需页数",
    "8": "输入邀请码",
    "9": "邀请码抵扣",
    "10": "每个邀请码可以抵扣500页内的文件",
    "11": "请输入邀请码",
    "12": "抵扣",
    "13": "正在上传PDF文件...",
    "14": "请上传正确的PDF文件！",
    "15": "正在上传",
    "16": "已上传",
    "17": "处理中",
    "18": "已完成",
    "19": "处理失败",
    "20": "确认",
    "21": "下载",
    "22": "支付下载",
    "23": "文件上传完成后可以确认生成格式，您可以同时上传更多文件",
    "24": "支付",
    "25": "登录",
    "26": "谷歌一键登录",
    "27": "正在登录",
    "28": "分享链接给新朋友注册，双方将获得100页Pro转换",
    "29": "复制专属链接",
    "30": "图片分享",
    "31": "扫描二维码注册",
    "32": "获得100页PDF Pro处理次数",
    "33": "退出账号",
    "34": "复制成功！",
    "35": "登录已过期，请重新登录！",
    "36": "保存至本地",
    "37": "优先抵扣页数：",
    "38": "推荐",
    "39": "识别正确率",
    "40": "填写邀请码",
    "41": "你将获得100页PDF PRO处理次数",
    "42": "提交",
    "43": "定价",
    "44": "99.5%识别正确率",
    "45": "S$0.07/页",
    "46": "适合专业人士/企业/机构",
    "47": "尽可能高性价比 尽可能高效",
    "48": "让大语言模为您智能纠错",
    "49": "99%识别正确率",
    "50": "S$0.05/页",
    "51": "适合对精确度要求不高的个人",
    "52": "尽可能低廉",
    "53": "满足日常需求",
    "54": "图书转文字",
    "55": "减少95%存储成本",
    "56": "通过图片转文字进行存储",
    "57": "90MB轻松转为6MB",
    "58": "瘦身文件简简单单",
    "59": "你可以存更多",
    "60": "存储更多，看见更多",
    "61": "LLMs企业/学术研究机构",
    "62": "增强你的数据集",
    "63": "比竞争对手更完备",
    "64": "扫描件也可以成为模型可阅读的数据集",
    "65": "古早文档统统收入囊中",
    "66": "电子书企业",
    "67": "降低95%成本",
    "68": "快速促进书籍、档案数字化",
    "69": "节省存储、传输",
    "70": "更好服务用户",
    "71": "给用户最快速提供最高清的图书",
    "72": "节省存储成本",
    "73": "小小的移动设备容纳更多书籍",
    "74": "再也不必担心存储空间",
    "75": "对自己更好",
    "76": "清晰的阅读，美好的体验",
    "77": "学生/老师",
    "78": "课件/文献语言障碍不复存在",
    "79": "扫描件变文本件",
    "80": "直接翻译就好啦",
    "81": "DDL杀手",
    "82": "快速复制引用，论文无忧",
    "83": "残障人士",
    "84": "让所有文件信息都能被读取",
    "85": "扫描件转换文本后即可成为能够被语音阅读的文件",
    "86": "让所有文件变成无障碍",
    "87": "关心在身边的他/她们",
    "88": "如果你希望为无障碍文件作出贡献",
    "89": "非常欢迎联系我们",
    "90": "我们将收录您的书籍，用于公益事业",
    "91": "语言学习者",
    "92": "快速提升语言能力",
    "93": "多种语言自由切换阅读，不必再浪费过多时间查阅",
    "94": "你可以学习更多",
    "95": "看见更多语种，看见更多可能",
    "96": "王先生",
    "97": "从事LLM相关领域研发",
    "98": "非常好，推荐！",
    "99": "很不错，有了这个工具再也不用带着纸质资料外出了。",
    "100": "文本提取准确度很高，大多数类型的扫描件转换完都没什么误差。",
    "101": "转换完体验太棒了！终于可以翻译成多种语言！阅读毫无障碍！",
    "102": "看了别人推荐过来的，很好用。",
    "103": "使用场景",
    "104": "常见问题",
    "105": "Q&A",
    "106": "Blog",
    "107": "阅读爱好者",
    "108": "分享更多Pdf to Pdf相关内容",
    "109": "分享更多Pdf to Pdf相关内容、ocr文本提取技术、文件瘦身",
    "110": "常见问题，图片OCR文本识别FAQ",
    "111": "在这里，我们列出了一些关于pdf到pdf转换的常见问题。如果您没有找到所需的信息，请随时通过pdftopdf@leqi.ai与我们联系。",
    "112": "免费在线OCR—将PDF扫描件转换为文本",
    "113": "通过ocr技术，将pdf扫描件转换为pdf文本文件，一键实现文件瘦身的同时保留文件原排版。阅读体验更佳，内容复制、搜索无阻碍!",
    "114": "上传文件，通过OCR技术快速识别pdf扫描件内容，提取结果精准度高达99%＋。PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件。",
    "115": "Pdf to Pdf| 高效工具全方位定价方案 | 量身定制满足您的需求",
    "116": "探索Pdf to Pdf软件的灵活定价计划，体验无与伦比的功能，享受个性化服务，现在注册更有超值优惠！",
    "117": "验证码错误！",
    "118": "邮箱登录",
    "119": "请输入邮箱",
    "120": "请输入密码",
    "121": "验证码已发送至邮箱",
    "122": "请输入验证码",
    "123": "设置密码",
    "124": "从事商务工作",
    "125": "刘先生",
    "126": "从事教师工作",
    "127": "赵先生",
    "128": "陈先生",
    "129": "个人财务管理者",
    "130": "张女士",
    "131": "可处理页书： ",
    "132": "语言",
    "133": "邮箱：",
    "134": "立即转换 pdf",
    "135": "PDF扫描件文字提取利器，一键提升企业与学术文献处理效率",
    "136": "PDFtoPDF文字提取利器，可用于快速摘录文献、报纸、杂志上的重要信息，进行引用或创作，提高研究和写作效率。支持将手稿或旧文档数字化，方便存储和检索，是LLMs企业和学术研究机构的得力助手！",
    "137": "高效提取PDF扫描件文本——电子书企业必备工具",
    "138": "利用先进的OCR技术，PDFtoPDF网站能精准提取PDF扫描件中的文字内容，助力电子书企业快速将纸质书籍、文档转变为可编辑的文本，实现数字化飞跃。简化编辑流程，提升出版效率，为您的电子书项目注入无限创意与灵活性。立即体验，开启智慧出版新时代。",
    "139": "PDF扫描件图片文本一键提取：从图片到清晰文字，升级阅读爱好者体验",
    "140": "专为阅读爱好者设计，这款OCR工具能巧妙地从PDF扫描件中抓取图片内的文字，将其转化为清晰、易读的文本格式。从此，不再受限于扫描文档的阅读不便，享受如纸质书般的流畅与清晰，让每一次阅读都成为一种享受。提升文本质量，尽享无界阅读新乐趣，现在就加入我们，开启您的舒适阅读之旅。",
    "141": "教育助力：PDF扫描件文本轻松提取，优化学习资料，提升教学体验",
    "142": "专为学生和教师打造的OCR解决方案，高效从PDF扫描件及图片中精准提取文本，转化成清晰可编辑的内容，让繁琐的学习资料瞬间变得井井有条。无论是论文研读、讲义整理还是教材制作，都能显著提升效率，助力学术研究与课堂教学迈上新台阶。立即体验，开启智慧学习新篇章。",
    "143": "OCR文字识别技术：将PDF扫描件转化为语音可读文本，构筑无障碍信息桥梁",
    "144": "利用先进的Text Recognition OCR技术，将PDF扫描文档轻松转换成可编辑、可语音阅读的文本，打破了残障人士阅读障碍。无论是学习资料、工作文件还是日常资讯，都能瞬间转化，让信息获取不再受限，促进数字内容的全面无障碍。",
    "145": "OCR技术赋能语言学习：PDF扫描件秒变文本，多语言翻译随心阅",
    "146": "OCR文本提取 & 翻译神器：一键将PDF扫描文档转换成可编辑文本，无论是外语教材还是原版文献，都能即扫即译。支持多种语言切换，大大节省查阅时间，多语言阅读无障碍，学习加速新体验！",
    "147": "上传PDF扫描件，轻松转换文本——高效PDF转文字工具",
    "148": "端点",
    "149": "认证",
    "150": "需要'web'类型的JWT Token",
    "151": "请求头",
    "152": "响应",
    "153": "成功",
    "154": "失败",
    "155": "Python代码示例",
    "156": "使用示例",
    "157": "参数",
    "158": "需要'test_company'类型的JWT Token",
    "159": "PDF文件，作为multipart/form-data上传",
    "160": "错误信息，例如：No file part in the request",
    "161": "PDF处理任务的ID",
    "162": "API",
    "163": "正式密钥",
    "164": "测试环境",
    "165": "接入文档",
    "166": "获取密钥",
    "167": "密钥:",
    "168": "复制",
    "169": "充值",
    "170": "剩余：",
    "171": "页",
    "172": "登录获取密钥",
    "173": "可免费测试3次PDF文件",
    "174": "1. 获取客户端密钥",
    "175": "复制代码",
    "176": "2. 获取访问令牌",
    "177": "3. 上传PDF文件",
    "178": "4. 获取PDF处理状态",
    "179": "5. 下载PDF文件",
    "180": "企业抬头:",
    "181": "请输入",
    "182": "税号:",
    "183": "开户行:",
    "184": "电话:",
    "185": "地址:",
    "186": "10000页",
    "187": "50000页",
    "188": "复制成功",
    "189": "请确保将 `<YOUR_WEB_JWT_TOKEN>`, `<YOUR_TEST_COMPANY_JWT_TOKEN>`,",
    "190": "`<YOUR_CLIENT_SECRET>`, `<YOUR_TASK_ID>`, 和 `path/to/your/file.pdf` 替换为合适的值，以进行测试。",
    "191": "提交成功",
    "192": "感谢您的提交，我们将在10个工作日内处理您的开票请求，如有疑问或其他要求请联系我们的邮箱：pdftopdf@leqi.ai",
    "193": "免费处理文件数",
    "194": "Apple ID",
    "195": "提示",
    "196": "您已享受一次免费文件处理，您还可以通过分享您的链接或邀请码邀请更多新用户注册，获取免费处理100页",
    "197": "复制邀请链接",
    "198": "下载邀请海报",
    "199": "图片转文字节省空间示例图",
    "200": "图片转文字存储更多示例图",
    "201": "语言切换按钮",
    "202": "扫描后增强数据集示例图",
    "203": "图片提取文字后节省存储示例图",
    "204": "PDF扫描前后对比图",
    "205": "移动端节省存储成本示例图",
    "206": "扫描件提取文本直接翻译",
    "207": "快速复制示例图",
    "208": "扫描件提取文本可被读取示例图",
    "209": "书本ocr收录",
    "210": "扫描件提取文本供翻译",
    "211": "文本提取后可翻译成所需语言",
    "212": "正在制作中...",
    "213": "正在下载中...",
    "214": "请选择输出格式",
    "215": "请选择翻译目标语言",
    "216": "预览",
    "217": "收起处理文件",
    "218": "展开处理文件",
    "219": "OCR",
    "220": "请选择源文件中包含的语言，将提升识别精度，如未选择则智能识别",
    "221": "源文件包含语言：",
    "222": "请选择",
    "223": "中文",
    "224": "英文",
    "225": "输出文件格式：",
    "226": "制作中",
    "227": "开始制作",
    "228": "正在转换文件格式:",
    "229": "翻译",
    "230": "翻译目标语言：",
    "231": "翻译中",
    "232": "开始翻译",
    "233": "正在翻译文件:",
    "234": "预览页面加载中，请稍后…",
    "235": "加载失败",
    "236": "重新制作",
    "237": "邮箱或密码错误",
    "238": "Facebook",
    "239": "请输入验证码！",
    "240": "请输入密码！",
    "241": "下载成功",
    "242": "下载失败",
    "243": "文件高清化与多语言翻译一站解决",
    "244": "扫描件转文本",
    "245": "保留格式",
    "246": "图片转文本",
    "247": "还原排版",
    "248": "文件翻译",
    "249": "高效精准",
    "250": "文字识别精准",
    "251": "高精度OCR文字识别技术，高达99.5%的识别精确度让扫描件提取文字后即刻能用，节省90%无效时间。目前可识别中文简体、英文，持续增加中。",
    "252": "保留文件原排版",
    "253": "完全还原原文件排版，PDF在线格式转换工具让你从繁琐的逐字输入工作中解放，同时因其高精度的转换结果，让你彻底摆脱人工比对原稿逐字校对、排版的苦恼。",
    "254": "支持多端",
    "255": "收藏网址pdftopdf.ai，无论是手机还是电脑，都可直接使用，无需下载、不占内存，方便快捷！外出再也不用随身带电脑，掏出手机即可在移动端完成文件处理，随时随地解决PDF文件格式转换、文本提取、编辑、压缩、翻译的问题！",
    "256": "文件压缩",
    "257": "使用PDF to PDF在线工具，可免费试用OCR文本提取功能，经过提取的PDF扫描件将转变为PDF文本文件格式，且转换格式后的文件将被进行高度压缩。不花一分钱同时获得OCR文字识别和PDF文件压缩的结果，存储更节省空间！",
    "258": "书籍",
    "259": "高效处理书籍扫描件文本 支持提取、复制、搜索",
    "260": "保留原格式",
    "261": "文本更高清",
    "262": "阅读更便捷",
    "263": "合同",
    "264": "OCR扫描合同内容  便于存储、检索、减少人工错误",
    "265": "扫描内容“0”失误",
    "266": "阅读合同更清晰",
    "267": "转换结果高效精准",
    "268": "论文",
    "269": "高效处理论文扫描件 支持提取、复制、搜索 ",
    "270": "简化翻译和多语言支持",
    "271": "提高研究效率",
    "272": "其他",
    "273": "更多其他扫描件内容均支持一键提取   支持翻译、复制、检索",
    "274": "图片转文字 ",
    "275": "图文结合",
    "276": "高效处理图文图片文本    支持提取、复制、搜索",
    "277": "保持原图文排版",
    "278": "快速定位重点信息",
    "279": "高效进行复制引用",
    "280": "纯文本",
    "281": "一键提取纯文本扫描件内容      支持提取、复制、搜索",
    "282": "复制粘贴更高效",
    "283": "快速翻译多页书籍PDF文件内容",
    "284": "专业术语自动适配",
    "285": "一键生成多国语言",
    "286": "打破语言阅读障碍",
    "287": "单页",
    "288": "准确、高效翻译单页书籍内容",
    "289": "支持格式：",
    "290": "上传第一份文件试试吧！",
    "291": "上传中",
    "292": "在上传完成前，请勿离开当前页",
    "293": "上传失败",
    "294": "全部",
    "295": "处理完成",
    "296": "文件列表",
    "297": "查看详情",
    "298": "超爱",
    "299": "请您评价一下我们的网站",
    "300": "不喜欢",
    "301": "无感",
    "302": "还不错",
    "303": "喜欢",
    "304": "请选择一个主题",
    "305": "您的建议",
    "306": "邮件/联系方式",
    "307": "您向您的朋友和同事推荐我们的可能性有多大",
    "308": "开具发票",
    "309": "电子发票发送至邮箱:",
    "310": "请填写签收邮箱",
    "311": "提交信息",
    "312": "学术研究机构",
    "313": "站内搜索",
    "314": "我的账户",
    "315": "搜索",
    "316": "查看账户",
    "317": "退出登录",
    "318": "关于PDFtoPDF",
    "319": "BLOG",
    "320": "隐私政策",
    "321": "联系我们",
    "322": "学术研究机构/研究员",
    "323": "LLMs企业",
    "324": "学生",
    "325": "教师",
    "326": "更多合作",
    "327": "免费使用",
    "328": "欢迎关注我们",
    "329": "加载中:",
    "330": "预览加载中，请稍后...",
    "331": "待处理",
    "332": "是否确认删除该记录?",
    "333": "删除成功",
    "334": "一经删除，无法恢复，请谨慎操作！",
    "335": "结果文件预览",
    "336": "开始处理",
    "337": "上传新文件",
    "338": "建议",
    "339": "表扬",
    "340": "bug",
    "341": "疑问",
    "342": "不太可能",
    "343": "非常可能",
    "344": "修改成功",
    "345": "验证码已发送",
    "346": "邮箱修改成功",
    "347": "请输入密码或验证码",
    "348": "密码修改成功",
    "349": "基本信息",
    "350": "用户名：",
    "351": "修改",
    "352": "支持的登录方式：",
    "353": "验证码：",
    "354": "密码：",
    "355": "注销账户",
    "356": "账户余额",
    "357": "剩余转文本页数：",
    "358": "剩余翻译页数：",
    "359": "邀请活动",
    "360": "取消",
    "361": "确定",
    "362": "确定退出当前账户",
    "363": "注销账户后，",
    "364": "账户内的文件将全部清空无法找回",
    "365": "，剩余页数将作废，请谨慎操作",
    "366": "邀请码",
    "367": "文件",
    "368": "列表",
    "369": "省钱省时示例图",
    "370": "有效=高效",
    "371": "只做有效的事",
    "372": "节省时间、财力",
    "373": "把耗时耗力的重复性工作安心交给我们处理",
    "374": "请填写完整信息",
    "375": "高效处理图文图片文本 支持提取、复制、搜索",
    "376": "上传完成",
    "377": "支付成功",
    "378": "请填写正确的邮箱",
    "379": "发送成功",
    "380": "发送失败",
    "381": "提交失败",
    "382": "正在加急处理中，请稍后哦",
    "383": "删除文件",
    "384": "预览图请求失败",
    "385": "重新加载",
    "386": "您正在操作删除文件，删除后文件无法找回，请确认您的操作",
    "387": "前往处理"
}